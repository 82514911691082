import React, { useState } from 'react';
// import { CiSettings } from 'react-icons/ci';
import { NavLink, NavLinkProps } from 'react-router-dom';
import cn from 'utils/cn';

interface CustomReactRouterLinkProps extends NavLinkProps {
    to: string;
    className?: string;
    text?: string;
    iconComponent?: React.ReactElement;
    iconActiveClassName?: React.HTMLAttributes<HTMLElement>["className"];
    activeClass?: string;
}

const CustomReactRouterLink: React.FC<CustomReactRouterLinkProps> = ({
    className,
    to,
    activeClass = '',
    text = '',
    iconComponent,
    iconActiveClassName = '',
    children,
    ...rest
}) => {
    const [isLinkActive, setIsLinkActive] = useState(false);

    const modifiedIconComponent = iconComponent
        ? React.cloneElement(iconComponent, {
            className: cn(
                iconComponent.props.className,
                isLinkActive && iconActiveClassName
            ),
        })
        : null;

    return (
        <NavLink
            to={to}
            className={({ isActive }) => {
                setIsLinkActive(isActive);
                return cn(
                    "text-black no-underline hover:no-underline hover:text-black",
                    className,
                    isActive && activeClass
                )
            }}
            {...rest}
        >
            <>
                {
                    iconComponent
                        ?
                        <div className="flex items-center gap-2">
                            {modifiedIconComponent}
                            {
                                text &&
                                <p className="text-base tracking-wide mt-0.5">
                                    {text}
                                </p>
                            }
                        </div>
                        :
                        children
                }
            </>
        </NavLink>
    );
};

export default CustomReactRouterLink;