import { useState } from "react";
import cn from "utils/cn";
import IndividualProductsTab from "./individualProductsTab/IndividualProductsTab";
import CategoriesTab from "./categoriesTab/CategoriesTab";

type TabState = "keyword" | "product";

const ProductTargetingInputs = () => {
  const [activeTab, setActiveTab] = useState<TabState>("keyword");

  return (
    <div className="w-full flex flex-col gap-2">
      {/* header */}
      <div className="flex flex-row items-center leading-6 gap-2">
        <span className="text-[#111827] font-bold text-[16px]">
          Product Targeting
        </span>
      </div>

      {/* page switching buttons */}
      <div className="flex flex-row flex-wrap gap-6 border-b">
        <button
          type="button"
          className={cn(
            "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
            activeTab === "keyword" &&
              "border-b-[3px] border-[#237804] text-[#111827] "
          )}
          onClick={() => {
            setActiveTab("keyword");
          }}
        >
          Categories
        </button>

        <button
          type="button"
          className={cn(
            "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
            activeTab === "product" &&
              "border-b-[3px] border-[#237804] text-[#111827] "
          )}
          onClick={() => {
            setActiveTab("product");
          }}
        >
          Individual Product
        </button>
      </div>

      {/* tab button */}
      <div className="w-full">
        {activeTab === "keyword" ? (
          <CategoriesTab />
        ) : (
          <IndividualProductsTab />
        )}
      </div>
    </div>
  );
};

export default ProductTargetingInputs;
