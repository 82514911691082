
export interface ThenFormState {
    logicalAND: NeighborRelationType,
    actions: ActionRowType[]
}

export enum NeighborRelationType {
    AND = "and",
    OR = "or",
    NONE = "none",
}

export enum MoveAddTargetOperatingType {
    ADD = "Add",
    MOVE = "Move"
}

export enum MoveAddTargetOptionType {
    CAMPAIGN = "Campaign",
    AD_GROUP = "Ad Grop"
}

export interface ActionRowType {
    relationToBottomRow: NeighborRelationType,
    actionType?: ActionType | undefined,
    condition?: ConditionType | undefined,
    condition1?: Condition1Type | undefined,
    condition2?: Condition2Type | undefined,
    exchangeOption: boolean,
    status1: Status1Type | undefined,
    status2: Status2Type | undefined,
    targetKey: TargeKeyType | undefined,
    targetKeyMatch: TargeKeyMatchType | undefined,
    targetStatus: undefined,
    value: {
        data: string | number,
        type?: ValueType | undefined,
        errorMessage?: string | undefined
    },
    dynamicCondition: {
        metrics?: string,
        period?: string,
        value?: number
    }
}

export enum BiddingStrategyType {
    DOWN_ONLY = "Down Only",
    UP_AND_DOWN = "Up and Down",
    FIXED = "Fixed"
}

export enum ValueType {
    DOLLER = "$",
    PERCENT = "%"
}

export enum TargetStatus {
    CAMPAIGN = "Campaign",
    AD_GROUP = "Ad Group"
}

export enum TargeKeyMatchType {
    NEGATIVE_EXACT = "Negative Exact",
    NEGATIVE_PHRASE = "Negative Phrase",
    INDIVIDUAL_PRODUCT = "Individual Product (ASIN)"
}

export enum TargeKeyType {
    KEYWORD = "Keyword",
    ASIN = "ASIN"
}

export enum Status1Type {
    ENABLED = "Enabled",
    PAUSED = "Paused",
    ARCHIVE = "Archive"
}

export enum Status2Type {
    TARGET = "Target",
    CAMPAIGN = "Campaign",
    AD_GROUP = "Ad Group"
}

export enum ActionType {
    CHANGE_BID = "Change Bid",
    CHANGE_DEFAULT_BID = "Change Default Bid",
    CHANGE_PLACEMENTS = "Change Placements",
    CHANGE_STATUS = "Change Status",
    NEGATIVE_TARGET = "Negative Target",
    CHANGE_BIDDING_STRATEGY = "Change Bidding Strategy",
    ADD_MOVE_TARGET = "Add/Move Target"
}

export enum ConditionType {
    INCREASE_BY = "Increase by",
    DECREASE_BY = "Decrese by",
    SET_BID = "Set Bid"
}

export enum Condition1Type {
    INCREASE_BY = "Increase",
    DECREASE_BY = "Decrese",
    SET_BID = "Set"
}

export enum Condition2Type {
    TOP_OF_SEARCH = "Top of search (first page)",
    PRODUCT_PAGES = "Product pages",
    REST_OF_SEARCH = "Rest of search"
}


export enum MetricsType {
    ACOS = "ACOS",
    BID = "Bid",
    CLICKS = "Clicks",
    CONVERSION_RATE = "Conversion Rate",
    CPC = "CPC",
    CTR = "CTR",
    DEFAULT_BID = "Default Bid",
    IMPRESSIONS = "Impressions",
    ORDERS = "Orders",
    ROAS = "ROAS",
    SPEND = "Spend",
    PAGE_READ = "Page Read",
    ROYALITIES = "Royalties",
    TOP_OF_SEARCH = "Top of Search",
    PRODUCT_PAGES = "Product Pages"
}


