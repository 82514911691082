import exportIcon from "assets/icons/export.svg";
import TopBarActionButton from "../shared/TopBarActionButton";
import { FC } from "react";

interface Props {
  handleClick?: () => void;
}

const ExportButton: FC<Props> = ({ handleClick }) => {
  return (
    <div className="flex items-center">
      {/* open column button */}
      <TopBarActionButton
        className=""
        clickHandler={() => {
          handleClick && handleClick();
        }}
        icon={exportIcon}
        text="Export"
      />
    </div>
  );
};

export default ExportButton;
