import target from "assets/icons/target.svg";
import { useState } from "react";
import cn from "utils/cn";
import NegativeKeywordsTab from "./negativeKeywordsTab/NegativeKeywordsTab";
import NegativeProductsTab from "./negativeProductsTab/NegativeProductsTab";

type TabState = "keyword" | "product";

const NegativeTargetingInputs = () => {
  const [activeTab, setActiveTab] = useState<TabState>("keyword");

  return (
    <div className="w-full flex flex-col gap-2">
      {/* header */}
      <div className="flex flex-row items-center leading-6 gap-2">
        <button type="button" className="text-[#111827]">
          <img src={target} alt="target" className="h-5 w-5" />
        </button>
        <span className="text-[#111827] font-bold text-xl">
          Negative Targeting (Optional)
        </span>
      </div>

      {/* page switching buttons */}
      <div className="flex flex-row flex-wrap gap-6 border-b">
        <button
          type="button"
          className={cn(
            "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
            activeTab === "keyword" &&
              "border-b-[3px] border-[#237804] text-[#111827] "
          )}
          onClick={() => {
            setActiveTab("keyword");
          }}
        >
          Negative Keywords
        </button>

        <button
          type="button"
          className={cn(
            "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
            activeTab === "product" &&
              "border-b-[3px] border-[#237804] text-[#111827] "
          )}
          onClick={() => {
            setActiveTab("product");
          }}
        >
          Negative Products
        </button>
      </div>

      {/* tab button */}
      <div className="w-full">
        {activeTab === "keyword" ? (
          <NegativeKeywordsTab />
        ) : (
          <NegativeProductsTab />
        )}
      </div>
    </div>
  );
};

export default NegativeTargetingInputs;
