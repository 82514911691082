import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { ThenFormState } from "./AddNewAutomationRuleThen.types";
import { status1Data, status2Data } from "./data";

interface Props {
  currentIndex: number;
  control: Control<ThenFormState, any>;
}

const ChangeStatusRowInputs: FC<Props> = ({ currentIndex, control }) => {
  return (
    <>
      <div className="col-span-3">
        <Controller
          name={`actions.${currentIndex}.status1`}
          control={control}
          render={({ field }) => (
            <CustomSelectOption
              placeholder={""}
              options={status1Data}
              field={field}
              sx={{
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "8px 12px !important",
                },
              }}
            />
          )}
        />
      </div>
      <div className="col-span-4 flex gap-3 items-center">
        <span className="text-[#374151] !font-normal leading-8 text-[16px]">
          Apply to
        </span>
        <div className="grow">
          <Controller
            name={`actions.${currentIndex}.status2`}
            control={control}
            render={({ field }) => (
              <CustomSelectOption
                placeholder={""}
                options={status2Data}
                field={field}
                sx={{
                  "& .MuiSelect-select.MuiSelect-outlined": {
                    padding: "8px 12px !important",
                  },
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ChangeStatusRowInputs;
