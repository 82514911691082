import {
  setAutomateRuleAddActiveStep,
  // setAutomateRuleAddStepComplete,
} from "features/automateRuleAdd/automateRuleAddStateSlice";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  ActionRowType,
  NeighborRelationType,
  ThenFormState,
} from "./AddNewAutomationRuleThen.types";
import { MdAddCircleOutline } from "react-icons/md";
import SubmitButton from "components/submitButton/SubmitButton";
import ActionRow from "./ActionRow";
import { useNavigate } from "react-router-dom";

const AddNewAutomationRuleThen = () => {
  /**-React Router-**/
  const navigate = useNavigate();

  /**-- Redux hook --*/
  const dispatch = useDispatch();

  /**-- Hook Form States */
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { isValid },
  } = useForm<ThenFormState>({
    mode: "onChange",

    defaultValues: {
      actions: [
        {
          actionType: undefined,
          condition: undefined,
          condition1: undefined,
          condition2: undefined,
          exchangeOption: undefined,
          status1: undefined,
          status2: undefined,
          targetKey: undefined,
          targetKeyMatch: undefined,
          relationToBottomRow: NeighborRelationType.OR,
          targetStatus: undefined,
          value: {
            data: undefined,
            errorMessage: undefined,
            type: undefined,
          },
        },
      ],
    },
  });

  /**-- useEffect --*/
  // set step complete state status
  useEffect(() => {
    dispatch(setAutomateRuleAddActiveStep(2));
    // dispatch(setAutomateRuleAddStepComplete(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**-- Handlers --*/
  // handle stepform submit
  const handleStepFormSubmit: SubmitHandler<ThenFormState> = async (data) => {
    console.log(data);
  };

  // handle add new row to the form state
  const handleAddActionRow = () => {
    const currentArray = getValues("actions");

    const updatedArray = [
      ...currentArray,
      {
        actionType: undefined,
        condition: undefined,
        condition1: undefined,
        condition2: undefined,
        exchangeOption: undefined,
        status1: undefined,
        status2: undefined,
        targetKey: undefined,
        targetKeyMatch: undefined,
        relationToBottomRow: NeighborRelationType.OR,
        targetStatus: undefined,
        value: {
          data: undefined,
          errorMessage: undefined,
          type: undefined,
        },
      },
    ];
    setValue("actions", updatedArray as ActionRowType[]);
  };

  // remove target row
  const handleRemoveActionRow = (index: number) => {
    const currentArray = getValues("actions");
    const updatedArray = currentArray.filter((_, i) => i !== index);
    setValue("actions", updatedArray);
  };

  // copy target row
  const handleCopyRow = (index: number, row: ActionRowType) => {
    const currentArray = getValues("actions");
    const updatedArray = [
      ...currentArray.slice(0, index),
      row,
      ...currentArray.slice(index),
    ];
    setValue("actions", updatedArray);
  };

  console.log("Then blog rows", watch("actions"));
  return (
    <form
      className="w-full flex flex-col gap-6"
      onSubmit={handleSubmit(handleStepFormSubmit)}
    >
      <div className="w-full flex flex-col gap-2">
        <h4 className="capitalize text-[#111827] text-[16px] !font-bold leading-6">
          action
        </h4>
        <div className="flex flex-col">
          {watch("actions").map((row, idx) => (
            <ActionRow
              key={idx}
              control={control}
              currentIndex={idx}
              getValues={getValues}
              handleCopyRow={handleCopyRow}
              handleDeleteRow={handleRemoveActionRow}
              totalLength={watch(`actions`).length}
              relationToBottomNeighbor={row.relationToBottomRow}
              relationToTopNeighbor={
                idx === 0
                  ? NeighborRelationType.NONE
                  : watch("actions")[idx - 1].relationToBottomRow
              }
              setValue={setValue}
              watch={watch}
            />
          ))}
          {/* action rows */}
          <div className="flex flex-col">
            {/* add row action */}
            <div className="flex justify-start items-center mt-3">
              <button
                type="button"
                className="flex items-center justify-center gap-1 text-[#9CA3AF] hover:text-[#237804] text-sm !font-medium leading-5 transition-all duration-200"
                onClick={handleAddActionRow}
              >
                <span>
                  <MdAddCircleOutline className="h-5 w-5" />
                </span>
                <span>Add Action</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* form action button */}
      <div className="flex items-center justify-end gap-5">
        <button
          type="button"
          className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
          onClick={() => {
            navigate("/automation-rule/add/if");
          }}
        >
          back
        </button>
        <div>
          <SubmitButton
            buttonText="Next"
            buttonType="submit"
            className="px-8"
            loadingClassName="px-8"
            disabledClassName="px-8"
            isDisabled={!isValid}
            isLoading={false}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNewAutomationRuleThen;
