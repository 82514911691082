import filterIcon from "assets/icons/filter.svg";
import TopBarActionButton from "../shared/TopBarActionButton";

const FilterButton = () => {
  return (
    <div className="flex items-center">
      <TopBarActionButton
        className=""
        clickHandler={() => {
          console.log("Handler clicked filter button");
        }}
        icon={filterIcon}
        text="Filter"
        hasArrow={true}
      />
    </div>
  );
};

export default FilterButton;
