interface GoogleUserData {
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    authToken: string;
 }


// fetch google user data
export const getGoogleUserData = async (token: string):  Promise<GoogleUserData | null>  => {
    if (token) {
        try {
           let res = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = await res.json();
     
            return {
                email: data?.email,
                firstName: data?.given_name,
                lastName: data?.family_name,
                id: data?.id,
                authToken: token
            }
        } catch (error) {
            return null
        }
    } else {
        return null
    }
}

