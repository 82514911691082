import logo from "assets/brand/logo.svg";
import resetLogo from "assets/icons/reset-success.svg";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

const ForgotPasswordResetSuccess = () => {
  const [second, setSecond] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const counter = setInterval(() => {
      setSecond((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(counter);
  }, []);

  useEffect(() => {
    if (second === 0) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second]);

  return (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-32" />
        </div>
        <hr className="my-0 !border-[#D4D4D4]" />
        <div className="flex flex-col items-center justify-center gap-2">
          <img src={resetLogo} alt="logo" className="w-[100px]" />
          <h4 className="font-medium text-xl leading-8 text-center text-[#171717]">
            You successfully reset your password
          </h4>
        </div>
        <div className="flex max-w-[340px] flex-col gap-4">
          {/* default success */}
          <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
            Redirecting back to the login page in {second} second
          </p>
          {/* error state  */}
          {/* <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
                Not automatically redirected? Click this
                <a href="/" className=" inline-block font-bold !text-[#237804] ml-1 underline">Login</a>
                 to redirected back.
              </p> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordResetSuccess;
