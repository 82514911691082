import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { CreateFormState, Marketplace, TabType } from "../../CreateAds.types";
import cn from "utils/cn";
import ReactCountryFlag from "react-country-flag";

interface Props {
  marketplace: Marketplace;
  control: Control<CreateFormState, any>;
  tabType: TabType;
}
interface TogggleProps {
  className?: string;
  onClick?: () => void;
  countryCode: string;
  toggleSelect?: any;
}

const SelectMarketPlaceOptions: FC<Props> = ({
  marketplace,
  control,
  tabType,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <h5 className="capitalize leading-6 !font-normal text-[16px] text-[#737373]">
        {marketplace.region}
      </h5>
      <div className="flex flex-row flex-wrap gap-3">
        {marketplace.marketplaces.map((cc, index) => {
          // return <MarketplaceToggleButton countryCode={cc?.countryCode} />;
          return (
            <Controller
              key={index}
              name={`${tabType}.marketPlaces.${cc.n}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <MarketplaceToggleButton
                  toggleSelect={value !== undefined}
                  countryCode={cc?.countryCode}
                  onClick={() => {
                    const newValue =
                      value === undefined ? cc.countryCode : undefined;
                    onChange(newValue);
                  }}
                />
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

const MarketplaceToggleButton: FC<TogggleProps> = ({
  className,
  onClick,
  countryCode,
  toggleSelect,
}) => {
  let cc = countryCode;
  if (cc.toLowerCase() === "uk") {
    cc = "GB";
  }

  return (
    <button
      type="button"
      className={cn(
        "flex gap-2 py-3.5 px-5 border-[1px] rounded-full items-center justify-center",
        toggleSelect
          ? "border-[#237804] bg-green-500/10 !font-bold !text-[#237804]"
          : "",
        className
      )}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <ReactCountryFlag
        svg
        countryCode={cc}
        style={{
          width: "1.5em",
          height: "1.5em",
        }}
      />
      <span className={cn("uppercase")}>{countryCode}</span>
    </button>
  );
};

export default SelectMarketPlaceOptions;
