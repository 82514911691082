import React, { useEffect } from "react";
import { ClickAwayListener, Modal } from "@mui/material";
import ErrorLabel from "components/errorLabel/ErrorLabel";
import SubmitButton from "components/submitButton/SubmitButton";
import { Controller, useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import AutoCompleteSelect, { OptionType } from "./AutoCompleteSelect";
import { getCountryName, getGTMTimezone } from "utils/getTimezoneList";
import {
  useGetCurrencyTypeSelectionQuery,
  useGetTimezoneSelectionQuery,
} from "features/masterData/masterDataSlice";
import {
  useLoggedInUserQuery,
  useUpdateUserGeneralInformationMutation,
} from "features/user/userSlice";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";

type FormInputs = {
  name: string;
  currencyType: OptionType;
  timezone: OptionType;
};

const EditGeneralInfo = () => {
  /**-React Hooks-**/
  //state
  const [showModal, setShowModal] = React.useState(false);

  /**-RTK-**/
  //queries
  // RTK: query user data fetching
  const { data } = useLoggedInUserQuery("");
  const { data: currencyData } = useGetCurrencyTypeSelectionQuery({});
  const { data: timezoneData } = useGetTimezoneSelectionQuery({});
  //mutations
  const [updateUserProfile, { isLoading }] =
    useUpdateUserGeneralInformationMutation();

  let defaultCurrencyTypeSelection: string = "";
  /**-Data Transformation */
  // decorate currency selction data
  const currencyDataSelection: OptionType[] =
    currencyData?.result?.data?.columns?.map((d) => {
      if (d?.currencyCodes === data?.result?.currencyTypeSelection) {
        defaultCurrencyTypeSelection = `${d?.countryCodes} - ${getCountryName(
          d?.countryCodes
        )}`;
      }
      return {
        name: `${d?.countryCodes} - ${getCountryName(d?.countryCodes) || ""}`,
        value: d?.currencyCodes,
      };
    });

  // decorate timezone selection data
  const timezoneSelectionData: OptionType[] =
    timezoneData?.result?.data?.columns?.map((d) => {
      return {
        name: getGTMTimezone(d?.countryCodes),
        value: d?.countryCodes,
      };
    });

  /**-Hook Form-**/
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      currencyType: {
        name: data?.result?.currencyTypeSelection
          ? data?.result?.currencyTypeSelection
          : "",
        value: data?.result?.currencyTypeSelection || "",
      },
      name: data?.result?.name,
      timezone: {
        name: data?.result?.timeZoneSelection
          ? getGTMTimezone(data?.result?.timeZoneSelection)
          : "",
        value: data?.result?.timeZoneSelection || "",
      },
    },
  });

  /**-- useEffectfor default currecytype selection --**/
  // it's solve default value of undefine of default currecy
  useEffect(() => {
    setValue("currencyType.name", defaultCurrencyTypeSelection);
  }, [defaultCurrencyTypeSelection, setValue]);

  /**-Variables-**/
  const isFormValid = !isValid || !isDirty;

  /**-Event Handlers-**/
  const handleClose = () => {
    setShowModal(false);
    reset();
  };
  // handler: profile info update
  const handleProfileInfoSubmit = async (data: FormInputs) => {
    // Handle form submission
    const body = {
      name: data.name,
      currencyTypeSelection: data.currencyType?.value,
      timeZoneSelection: data.timezone?.value,
    };
    try {
      const res = await updateUserProfile(body);
      if (res?.data?.success) {
        NotifyMessage({
          type: "success",
          message: "User information updated successfully",
        });
        setShowModal(false);
      } else {
        NotifyMessage({
          type: "error",
          message: "Faild to update user information, please try again",
        });
      }
    } catch (error) {
      console.log(error);
      NotifyMessage({
        type: "error",
        message: "Faild to update user information, please try again",
      });
    }
  };

  return (
    <>
      <div className="inline-block">
        <div className="w-full relative" onClick={() => setShowModal(true)}>
          {/* edit general info button */}
          <button
            type="button"
            className="py-2 px-6 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
          >
            Edit
          </button>
        </div>
        <Modal
          open={showModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex h-screen w-screen items-center justify-center bg-transparent">
            <ClickAwayListener onClickAway={handleClose}>
              <div className="bg-white p-8 rounded-lg w-full md:w-[400px] relative">
                <div className="flex justify-between">
                  <h4 className="m-0 text-xl text-[#111827] !font-bold leading-8">
                    Edit General Information
                  </h4>
                  <button
                    onClick={handleClose}
                    className="absolute top-0 right-2 py-4 px-3"
                  >
                    <IoClose className="h-5 w-5" />
                  </button>
                </div>
                <hr className=" !bg-[#D4D4D4] w-full my-6" />
                <div className="flex flex-col gap-6">
                  <form
                    className="flex flex-col gap-6"
                    onSubmit={handleSubmit(handleProfileInfoSubmit)}
                  >
                    {/* name input */}
                    <div className="flex flex-col gap-2">
                      <label
                        htmlFor="name"
                        className="!font-bold leading-6 text-[16px] text-[#111827]"
                      >
                        Name
                      </label>
                      <div className="w-full">
                        <input
                          id="name"
                          type="text"
                          placeholder={"Enter your name"}
                          className="w-full border-[1px] border-[#D1D5DB] py-2 px-3 rounded-md"
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </div>
                      {errors?.name && (
                        <ErrorLabel message={errors?.name?.message} />
                      )}
                    </div>
                    {/* Currency type input */}
                    <div className="flex flex-col gap-2">
                      <label
                        htmlFor="name"
                        className="!font-bold leading-6 text-[16px] text-[#111827]"
                      >
                        Currency Type
                      </label>
                      <div className="w-full">
                        <Controller
                          name="currencyType"
                          control={control}
                          rules={{
                            required: "Currency selection is required",
                          }}
                          render={({ field: { onChange } }) => (
                            <AutoCompleteSelect
                              handleSelect={(option: OptionType) => {
                                onChange(option);
                              }}
                              options={currencyDataSelection}
                              selectedValue={watch("currencyType")}
                              fallBackText="Select currency"
                              type="currency"
                            />
                          )}
                        />
                      </div>
                      {errors?.currencyType && (
                        <ErrorLabel message={errors?.currencyType?.message} />
                      )}
                    </div>
                    {/* Time zone input */}
                    <div className="flex flex-col gap-2">
                      <label
                        htmlFor="name"
                        className="!font-bold leading-6 text-[16px] text-[#111827]"
                      >
                        Time Zone Selection
                      </label>
                      <div className="w-full">
                        <Controller
                          name="timezone"
                          control={control}
                          rules={{
                            required: "Timezone selection is required",
                          }}
                          render={({ field: { onChange } }) => (
                            <AutoCompleteSelect
                              handleSelect={(option: OptionType) => {
                                onChange(option);
                              }}
                              options={timezoneSelectionData}
                              selectedValue={watch("timezone")}
                              fallBackText="Select timezone"
                              type="timezone"
                            />
                          )}
                        />
                      </div>
                      {errors?.timezone && (
                        <ErrorLabel message={errors?.timezone?.message} />
                      )}
                    </div>

                    {/* action button */}
                    <div className="grid grid-cols-2 gap-6">
                      <button
                        onClick={handleClose}
                        className="py-2 px-6 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
                      >
                        Cancel
                      </button>
                      <div>
                        <SubmitButton
                          buttonText="save"
                          buttonType="submit"
                          isDisabled={isFormValid}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EditGeneralInfo;
