import React from "react";
import { GoCheckCircleFill } from "react-icons/go";
import { IoIosAddCircleOutline } from "react-icons/io";

const KeywordRow = () => {
  return (
    <div className="grid grid-cols-3 border-b">
      <div className=" text-[#111827] font-normal leading-6 text-[16px] flex gap-2">
        Keyword 1
      </div>
      <div className=" col-span-2 flex flex-col gap-2 ">
        {/* keyword row */}
        <div className="grid grid-cols-2">
          <div className="text-[#111827] font-normal leading-6 text-[16px] flex flex-col">
            Broad
          </div>
          <div className="text-[#237804] font-normal leading-6 text-[16px] text-center">
            <button type="button">
              <IoIosAddCircleOutline className="h-5 w-5" />
            </button>
          </div>
        </div>
        {/* keyword row */}
        <div className=" grid grid-cols-2">
          <div className="text-[#111827] font-normal leading-6 text-[16px] flex flex-col">
            Phrase
          </div>
          <div className="text-[#237804] font-normal leading-6 text-[16px] text-center">
            <button type="button">
              <GoCheckCircleFill className="h-5 w-5" />
            </button>
          </div>
        </div>
        {/* keyword row */}
        <div className="grid grid-cols-2">
          <div className="text-[#111827] font-normal leading-6 text-[16px] flex flex-col">
            Exact
          </div>
          <div className="text-[#237804] font-normal leading-6 text-[16px] text-center">
            <button type="button">
              <IoIosAddCircleOutline className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordRow;
