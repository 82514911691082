export interface Marketplace {
    region: string,
    marketplaces: { countryCode: string, n: number }[]
}


export enum NeighborRelationType {
    AND = "and",
    OR = "or",
    NONE = "none",
}

export interface TargetRow {
    operand: string | undefined,
    operator: string | undefined,
    relationToBottomRow: NeighborRelationType,
    value: any,
}

export interface ApplyToFormState {
    ruleName: string;
    marketPlaces: string[];
    targets: TargetRow[];
    matchType: {
        autoCampaign: { [key: string]: boolean }[],
        totalAutoCampaignSelected: number,
        manualCampaign: {
            keyword: { [key: string]: boolean }[],
            product: { [key: string]: boolean }[]
        }
        totalManualCampaignSelected: number
    };
}