import { Outlet } from 'react-router';

const MainLayout = () => {
    return (
        <main className="min-h-screen flex flex-col !bg-am-base m-0">
            <Outlet />
        </main>
    );
};

export default MainLayout;