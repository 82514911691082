import ErrorLabel from "components/errorLabel/ErrorLabel";
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import logo from "../../assets/brand/logo.svg";
import { ClickAwayListener } from "@mui/material";
import { useForm } from "react-hook-form";
import cn from "utils/cn";
import eye from "../../assets/icons/eye.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
import {
  useChangeUserPasswordMutation,
  useLoggedInUserQuery,
} from "features/user/userSlice";
import SubmitButton from "components/submitButton/SubmitButton";
import resetLogo from "assets/icons/reset-success.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";

type FormInputs = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const PasswordUpdate = () => {
  /* RTK Query */
  const { data } = useLoggedInUserQuery({});

  const [open, setOpen] = React.useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [changePassword, { isLoading }] = useChangeUserPasswordMutation();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {},
  });

  const isFormValid = !isValid || !isDirty;

  const newPassword = watch("newPassword");
  const [reqStatus, setReqStatus] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setReqStatus(false);
    setShowConfirmNewPassword(false);
    setShowNewPassword(false);
    setShowCurrentPassword(false);
    reset();
  };

  const onSubmit = async (data: FormInputs) => {
    // Handle form submission
    const body: any = {
      oldPassword: data?.currentPassword || "",
      password: data?.newPassword,
    };

    try {
      const res = await changePassword(body);

      if (res?.data?.success) {
        setReqStatus(true);
        NotifyMessage({
          type: "success",
          message: "Password reset successfully",
        });
      } else {
        setReqStatus(false);
        const err: any = res?.error;
        if (err?.data?.validationErrors?.OldPassword) {
          setError("currentPassword", {
            message: err?.data?.validationErrors?.OldPassword[0],
          });
        }
        NotifyMessage({
          type: "error",
          message: "Error resetting password, Please try again",
        });
      }
    } catch (error) {
      NotifyMessage({
        type: "error",
        message: "Error resetting password, Please try again",
      });
    }
  };

  return (
    <>
      <label
        htmlFor="password"
        className="!font-bold leading-6 text-[16px] text-[#111827]"
      >
        Password
      </label>
      <div className="w-full">
        <div className="w-full relative" onClick={handleOpen}>
          <input
            id="password"
            type="password"
            defaultValue={"johnsmite@hotmail.com"}
            className="w-full border-[1px] border-[#D1D5DB] py-2 px-3 rounded-md pr-32 cursor-pointer focus:ring-0 focus:outline-none"
            disabled={false}
          />
          {/* update reset button */}
          <button
            type="button"
            className="absolute right-0 bottom-0 h-full px-5 font-medium leading-5 text-sm text-[#9CA3AF]"
          >
            Reset Password
          </button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex h-screen w-screen items-center justify-center bg-transparent">
            <ClickAwayListener onClickAway={handleClose}>
              <div className="bg-white p-8 rounded-lg w-full md:w-[400px]">
                <div className="flex justify-center">
                  <img src={logo} alt="" className="w-32" />
                </div>
                <hr className=" !bg-[#D4D4D4] w-full my-6" />
                {/* reset password form */}
                {!reqStatus ? (
                  <div className="flex flex-col gap-6">
                    <div>
                      <h4 className="m-0 w-full text-center text-[#171717] !font-medium text-xl leading-8">
                        Reset Password
                      </h4>
                      <p className="font-medium leading-5 text-xs text-[#171717]">
                        Please set new password below
                      </p>
                    </div>
                    <form
                      className="flex flex-col gap-6"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {/* current password input */}
                      {data?.result?.hasPassword && (
                        <div className="flex flex-col gap-2">
                          <label className="text-[#111827] !font-bold text-[16px] leading-6">
                            Current Password
                          </label>
                          <div className="relative w-full">
                            <input
                              type={showCurrentPassword ? "text" : "password"}
                              placeholder="Enter password..."
                              className={cn(
                                "py-2.5 pl-3.5 pr-11 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] border-[1px]  w-full",
                                errors?.currentPassword
                                  ? "!border-red-500"
                                  : "!border-[#C6C6C6]",
                              )}
                              autoComplete="off"
                              {...register("currentPassword")}
                            />
                            <button
                              type="button"
                              onClick={() =>
                                setShowCurrentPassword((prev) => !prev)
                              }
                              className="absolute top-[50%] translate-y-[-50%] right-5"
                            >
                              <img
                                src={showCurrentPassword ? eyeClose : eye}
                                alt="eye"
                                className="h-5 w-5 "
                              />
                            </button>
                          </div>
                          {errors?.currentPassword && (
                            <ErrorLabel
                              message={errors?.currentPassword?.message}
                            />
                          )}
                        </div>
                      )}
                      {/* new password input */}
                      <div className="flex flex-col gap-2">
                        <label className="text-[#111827] !font-bold text-[16px] leading-6">
                          New Password
                        </label>
                        <div className="relative w-full">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            placeholder="Enter password..."
                            className={cn(
                              "py-2.5 pl-3.5 pr-11 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] border-[1px]  w-full",
                              errors?.newPassword
                                ? "!border-red-500"
                                : "!border-[#C6C6C6]",
                            )}
                            autoComplete="off"
                            {...register("newPassword", {
                              required: "Password is required",
                              validate: {
                                minLength: (value) =>
                                  value.length >= 7 ||
                                  "Password must be at least 7 characters long",
                                hasNumber: (value) =>
                                  /\d/.test(value) ||
                                  "Password must contain at least 1 number",
                              },
                            })}
                          />
                          <button
                            type="button"
                            onClick={() => setShowNewPassword((prev) => !prev)}
                            className="absolute top-[50%] translate-y-[-50%] right-5"
                          >
                            <img
                              src={showNewPassword ? eyeClose : eye}
                              alt="eye"
                              className="h-5 w-5 "
                            />
                          </button>
                        </div>
                        {errors?.newPassword ? (
                          <ErrorLabel message={errors?.newPassword?.message} />
                        ) : (
                          <p>
                            Password must contain at least 7 letters and 1
                            number
                          </p>
                        )}
                      </div>

                      {/* confirm new password input */}
                      <div className="flex flex-col gap-2">
                        <label className="text-[#111827] !font-bold text-[16px] leading-6">
                          Confirm New Password
                        </label>
                        <div className="relative w-full">
                          <input
                            type={showConfirmNewPassword ? "text" : "password"}
                            placeholder="Enter password..."
                            className={cn(
                              "py-2.5 pl-3.5 pr-11 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] border-[1px]  w-full",
                              errors?.confirmPassword
                                ? "!border-red-500"
                                : "!border-[#C6C6C6]",
                            )}
                            autoComplete="off"
                            {...register("confirmPassword", {
                              required: "Confirm password is required",
                              validate: (value) =>
                                value === newPassword ||
                                "Passwords do not match",
                            })}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              setShowConfirmNewPassword((prev) => !prev)
                            }
                            className="absolute top-[50%] translate-y-[-50%] right-5"
                          >
                            <img
                              src={showConfirmNewPassword ? eyeClose : eye}
                              alt="eye"
                              className="h-5 w-5 "
                            />
                          </button>
                        </div>
                        {errors?.confirmPassword ? (
                          <ErrorLabel
                            message={errors?.confirmPassword?.message}
                          />
                        ) : (
                          <p>
                            Password must contain at least 7 letters and 1
                            number
                          </p>
                        )}
                      </div>

                      {/* action button */}
                      <div className="flex flex-col">
                        <SubmitButton
                          buttonText="Reset Password"
                          buttonType="submit"
                          isDisabled={isFormValid}
                          isLoading={isLoading}
                        />
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    {/* success display screen */}
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-col items-center justify-center gap-2">
                        <img src={resetLogo} alt="logo" className="w-[100px]" />
                        <h4 className="font-medium text-xl leading-8 text-center text-[#171717]">
                          You successfully reset your password
                        </h4>
                      </div>
                      <div className="flex items-center justify-center">
                        <button
                          onClick={handleClose}
                          type="button"
                          className="flex items-center justify-center gap-2 text-[#171717] font-medium text-[16px] leading-6"
                        >
                          {" "}
                          <FaArrowLeftLong /> Back to Settings
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </ClickAwayListener>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PasswordUpdate;
