import * as React from "react";
import { styled } from "@mui/material/styles";

import ClickAwayListener from "@mui/material/ClickAwayListener";

import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from "@mui/material/Autocomplete";

import InputBase from "@mui/material/InputBase";

import cn from "utils/cn";
import { CiSearch } from "react-icons/ci";

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: "#fff",

    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${" #eaecef"}`,

      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
      ...theme.applyStyles("dark", {
        borderBottom: `1px solid  ${"#30363d"}`,
      }),
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#1c2128",
    }),
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "absolute",
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  "& input": {
    borderRadius: 4,

    backgroundColor: "#fff",
    border: `1px solid ${"#9CA3AF"}`,
    padding: 8,
    paddingRight: 35,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${"rgba(3, 102, 214, 0.3)"}`,
      borderColor: "#9CA3AF",
      ...theme.applyStyles("dark", {
        boxShadow: `0px 0px 0px 3px ${"rgb(12, 45, 107)"}`,
        borderColor: "#388bfd",
      }),
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#0d1117",
      border: `1px solid ${"#eaecef"}`,
    }),
  },
  ...theme.applyStyles("dark", {
    borderBottom: `1px solid ${"#eaecef"}`,
  }),
}));

export default function AutoCompleteSelect({
  handleSelect,
  options,
  selectedValue,
  fallBackText,
  type,
}: {
  handleSelect: (option: OptionType) => void;
  options: OptionType[];
  selectedValue?: OptionType;
  fallBackText?: string;
  type: "timezone" | "currency";
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = React.useState<OptionType[]>(options);

  // autocomplete option open and close state
  const [open, setOpen] = React.useState<boolean>(false);

  // current active value
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedOption, setSelectedOption] = React.useState<
    OptionType | undefined
  >(undefined);

  const handleSelectOption = (option: OptionType) => {
    setSelectedOption(option);
    // setPendingValue(option);
    setOpen(false);
    handleSelect(option);
  };

  // autocomplete
  const autocompleteRef = React.useRef<HTMLDivElement | null>(null);

  // Handler: autocomplete select menu open
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setTimeout(() => {
      const inputElement = autocompleteRef.current?.querySelector("input");
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  };

  // handler auto complete menu option close
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="w-full relative">
        <div className="w-full">
          <button
            type="button"
            className={cn(
              "w-full flex justify-between items-center border-[1px] border-[#D1D5DB] py-2 px-3 rounded-md font-normal text-[16px] leading-6",
              "text-[#9CA3AF]"
            )}
            onClick={handleClick}
          >
            {selectedValue && selectedValue?.name && selectedValue?.value
              ? selectedValue.name
              : fallBackText}
          </button>
        </div>
        <div
          className={cn(
            open ? "block absolute left-0 top-[100%] w-full z-50" : "hidden"
          )}
        >
          <div className="bg-white shadow-md rounded-[4px]">
            <Autocomplete
              ref={autocompleteRef}
              open={true}
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  ((event as React.KeyboardEvent).key === "Backspace" ||
                    (event as React.KeyboardEvent).key === "Delete") &&
                  reason === "removeOption"
                ) {
                  return;
                }
              }}
              disableCloseOnSelect
              renderTags={() => null}
              noOptionsText="No currency info"
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li
                    key={key}
                    {...optionProps}
                    onClick={() => handleSelectOption(option)}
                    className="text-sm font-medium leading-6 text-[#4B5563] mx-3.5 py-2 border-b border-[#F3F4F6] cursor-pointer"
                  >
                    {option.name}
                  </li>
                );
              }}
              options={[...options].sort((a, b) => {
                // Display the selected labels first.
                let ai = value.indexOf(a);
                ai = ai === -1 ? value.length + options.indexOf(a) : ai;
                let bi = value.indexOf(b);
                bi = bi === -1 ? value.length + options.indexOf(b) : bi;
                return ai - bi;
              })}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <div className="w-full relative">
                  <StyledInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    placeholder="Search currency..."
                  />
                  <span className="flex items-center justify-center absolute right-5 top-0 h-full ">
                    <CiSearch className="w-6 h-6" />
                  </span>
                </div>
              )}
              slots={{
                popper: PopperComponent,
              }}
            />
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export interface OptionType {
  name: string;
  value: string;
}
