// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Component } from "react";
// import { Routes, Route, useParams } from "react-router-dom";
// import DashboardLayout from "../../../../layouts/DashboardLayout";
// import { Row, Col, Container, Form } from "react-bootstrap";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import "../index.css";
// import LinkIcon from "../../../../assets/images/icons/link-icon.svg";
// import BackArrow from "../../../../assets/images/icons/back-arrow-icon.svg";
// import ThreeDots from "../../../../assets/images/icons/more-action-icon.svg";
// import DeleteIcon from "../../../../assets/images/icons/trash-icon.svg";
// import Moment from "moment";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// // import CreateManualCampaign from "./createManualCampaign";
// import InfoIcon from "../../../../assets/images/icons/info-circle-icon.svg";
// import same from "../../../../assets/images/icons/same.png";
// import individual from "../../../../assets/images/icons/Individual.png";
// import individualcampaign from "../../../../assets/images/icons/individualcampaign.png";
// import { getWidth } from "rsuite/esm/DOMHelper";
// import { wrap } from "module";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// class CreateAutoCampaign extends Component {
//   state = {
//     isError: {
//       campaignBiddingStrategy: true,
//       portfolio: true,
//       dailyBudget: true,
//       targetingBid: true,
//       defaultBid: true,
//       marketPlaces: true,
//     },
//     isTitle: null,
//     imgstate: 1,
//     matchTypesList: [
//       { id: 1, nameid: "close", title: "Close", isChecked: true, name: "" },
//       { id: 2, nameid: "loose", title: "Loose", isChecked: true, name: "" },
//       {
//         id: 3,
//         nameid: "substitutes",
//         title: "Substitutes",
//         isChecked: true,
//         name: "",
//       },
//       {
//         id: 4,
//         nameid: "complements",
//         title: "Complements",
//         isChecked: true,
//         name: "",
//       },
//     ],
//     list: "List",
//     startDate: new Date(),
//     EndDate: new Date(),
//     isSelectedMarketPlace: [{ close: "" }],
//     isSelectedKeyword: [],
//     isSelected: null,
//     isSelectedCampaign: 1,
//     marketPlacesListSelected: [],
//     isopen: false,
//     portfolioData: [],
//     marketPlacesList: [{ countryCode: "" }],
//     CreateFormData: {
//       campaignType: "AutoCampaign",
//       campaignName: "",
//       asins: [],
//       matchType: "SingleCampaignWithSingleAdgroup",
//       placement: {
//         topOfSearch: undefined,
//         productDetailPages: undefined,
//         restOfSearch: undefined,
//       },
//       settings: {
//         portfolio: "",
//         dailyBudget: "",
//         startDate: "",
//         endDate: "",
//         campaignBiddingStrategy: "",
//       },
//       marketPlaces: [],
//       negativeKeywordTargeting: {},
//       dynamicBidding: {
//         defaultBid: "",
//         targetingBid: "",
//       },
//       targets: null,
//       //  [
//       // {
//       //   targetType: "string",
//       //   matchtype: ["string"],
//       //   targetText: "string",
//       // },
//       // ],
//       postToAmazon: true,
//     },

//     createCampaignFormData: {
//       asin: [],
//       campaignName: "",
//       matchTypeOption: "individualCampaign",
//       adGroups: [
//         {
//           name: "",
//         },
//       ],
//       targetingStrategy: "Auto Campaign",
//       dynamicBidding: {
//         defaultBid: 0,
//         targetingBid: 0,
//         close: 0,
//         loose: 0,
//         substitutes: 0,
//         complements: 0,
//         broad: 0,
//         phrase: 0,
//         exact: 0,
//         product: 0,
//       },
//       // negativeKeywordTargeting: {
//       //   targetingType: "",
//       //   keywords: [
//       //     {
//       //       type: "",
//       //       keyword: "",
//       //     },
//       //   ],
//       // },
//       campaignBiddingStrategy: "",
//       settings: {
//         portfolio: "",
//         dailyBudget: "",
//       },
//       targets: null,
//       postToAmazon: true,
//       marketPlaces: ["US"],
//     },
//     adGroupsTemp: "",
//     adGroups: [],
//     adKeywordType: "",
//     adKeywordsTemp: "",
//     adKeywords: [],
//     negativeKeywordTargeting: {},
//     matchTypeSelect: false,
//     matchTypeCheckedlist: [""],
//     editTab: false,
//     lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
//     selectedTab: "auto",
//   };
//   componentDidMount() {
//     const queryParams = window.location.pathname;
//     let queryParamsArr = queryParams.split("/");
//     const asin = queryParamsArr[4];
//     const comapaignRuleId = queryParamsArr[5];
//     if (comapaignRuleId) {
//       let newFormEditObj: any = this.state.CreateFormData;
//       newFormEditObj["id"] = queryParamsArr[5];
//       console.log("Edit rule obj : ", newFormEditObj);
//       this.setState({ CreateFormData: newFormEditObj });
//       this.setState({ editTab: true });
//       this.getCampainById(comapaignRuleId);
//       this.getPortfolioData();
//     } else {
//       this.setState({ editTab: false });
//       console.log("createCamapaing Asin ID: ", asin);
//       let newCreateCampaignFormData: any = this.state.CreateFormData;
//       if (newCreateCampaignFormData.asins.length < 1) {
//         newCreateCampaignFormData.asins.push(asin);
//         this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//       }
//       this.getPortfolioData();
//       this.getMarketplaceData();
//     }
//   }

//   handleChangeDate = (type, date) => {
//     if (type === "start") {
//       this.setState({ startDate: date });
//       this.setState((prevState: any) => {
//         const currentDate = date;
//         const year = currentDate.getFullYear();
//         const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//         const day = currentDate.getDate().toString().padStart(2, "0");
//         return {
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             settings: {
//               ...prevState.CreateFormData.settings,
//               startDate: `${year}-${month}-${day}`,
//             },
//           },
//         };
//       });
//     } else {
//       this.setState({ EndDate: date });
//       this.setState((prevState: any) => {
//         const currentDate = date;
//         const year = currentDate.getFullYear();
//         const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//         const day = currentDate.getDate().toString().padStart(2, "0");
//         return {
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             settings: {
//               ...prevState.CreateFormData.settings,
//               endDate: `${year}-${month}-${day}`,
//             },
//           },
//         };
//       });
//     }
//   };

//   getCampainById = async (id: any) => {
//     console.log(id);

//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/AdsManager/" + id;
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     });

//     try {
//       const responceData = await response.json();
//       this.setState({
//         adKeywords: responceData?.result?.negativeKeywordTargeting?.keywords,
//       });
//       // console.log("data"+responceData.result.adGroups[0].name);
//       let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//       newCreateCampaignFormData.id = responceData.result.id;
//       newCreateCampaignFormData.asin = responceData.result.asins;
//       newCreateCampaignFormData.campaignName =
//         responceData.result.campaignName.name;
//       newCreateCampaignFormData.adGroups = responceData.result.adGroups;
//       newCreateCampaignFormData.marketPlaces = responceData.result.marketPlaces;
//       newCreateCampaignFormData.targetingStrategy =
//         responceData.result.targetingStrategy;
//       newCreateCampaignFormData.campaignBiddingStrategy =
//         responceData.result.campaignBiddingStrategy;
//       newCreateCampaignFormData.dynamicBidding.defaultBid =
//         responceData.result.dynamicBidding.defaultBid;
//       newCreateCampaignFormData.dynamicBidding.targetingBid =
//         responceData.result.dynamicBidding.targetingBid;
//       newCreateCampaignFormData.dynamicBidding.close =
//         responceData.result.dynamicBidding.close;
//       newCreateCampaignFormData.dynamicBidding.loose =
//         responceData.result.dynamicBidding.loose;
//       newCreateCampaignFormData.dynamicBidding.substitutes =
//         responceData.result.dynamicBidding.substitutes;
//       newCreateCampaignFormData.dynamicBidding.complements =
//         responceData.result.dynamicBidding.complements;
//       newCreateCampaignFormData.dynamicBidding.broad =
//         responceData.result.dynamicBidding.broad;
//       newCreateCampaignFormData.dynamicBidding.phrase =
//         responceData.result.dynamicBidding.phrase;
//       newCreateCampaignFormData.dynamicBidding.exact =
//         responceData.result.dynamicBidding.exact;
//       newCreateCampaignFormData.dynamicBidding.product =
//         responceData.result.dynamicBidding.product;
//       newCreateCampaignFormData.settings.dailyBudget =
//         responceData.result.settings.dailyBudget;
//       newCreateCampaignFormData.settings.portfolio =
//         responceData.result.settings.portfolio;
//       newCreateCampaignFormData.negativeKeywordTargeting.keywords =
//         responceData?.result?.negativeKeywordTargeting?.keywords;
//       // newCreateCampaignFormData.negativeKeywordTargeting.keywords.keyword=responceData.result.negativeKeywordTargeting.keywords[0].keyword;
//       newCreateCampaignFormData.negativeKeywordTargeting.targetingType =
//         responceData.result.negativeKeywordTargeting.targetingType;

//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//       // this.setState({ portfolioData: responceData.result });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   handleChange = (name, e) => {
//     let newFormValues = this.state.CreateFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = e.target.value;
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = e.target.value;
//     }
//     this.setState({ CreateFormData: newFormValues });
//   };
//   handleChangeDecimal = (name, e) => {
//     let newFormValues = this.state.createCampaignFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = parseFloat(e.target.value);
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = parseFloat(e.target.value);
//     }
//     console.log("New Form Data: ", newFormValues);
//     this.setState({ createCampaignFormData: newFormValues });
//   };
//   addTempAdgroup = (e) => {
//     this.setState({ adGroupsTemp: e.target.value });
//   };
//   adGroupsData = () => {
//     this.setState({ isopen: !this.state.isopen });
//     let newAdGroups: any = this.state.adGroups;
//     let val = { name: this.state.adGroupsTemp };
//     if (this.state.adGroupsTemp) {
//       newAdGroups.push(val);
//     }
//     this.setState({ adGroups: newAdGroups });
//     let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//     newCreateCampaignFormData.adGroups = newAdGroups;
//     this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     this.setState({ adGroupsTemp: "" });
//   };
//   addKeyWordType = (e) => {
//     this.setState({ adKeywordType: e.target.value });
//   };
//   addTempAdKeywords = (e) => {
//     this.setState({ adKeywordsTemp: e.target.value });
//   };

//   removeFormFields = (i) => {
//     if (this.state.editTab == false) {
//       let newFormValues = this.state.adKeywords;
//       newFormValues.splice(i, 1);
//       this.setState({ adKeywords: newFormValues });
//       let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//       newCreateCampaignFormData.negativeKeywordTargeting.keywords =
//         newFormValues;
//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     } else {
//       let newFormValues = this.state.adKeywords;
//       newFormValues.splice(i, 1);
//       this.setState({ adKeywords: newFormValues });
//       let newCreateCampaignFormData: any = this.state.createCampaignFormData;
//       newCreateCampaignFormData.negativeKeywordTargeting.keywords =
//         newFormValues;
//       this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//     }
//   };
//   adkeyWordSubmit = () => {
//     if (this.state.adKeywordsTemp) {
//       let adKeywordsListStr = this.state.adKeywordsTemp;
//       let newAdKeywords: any = this.state.adKeywords;
//       let adKeywordsListArr = adKeywordsListStr.split(",");
//       for (let i = 0; i < adKeywordsListArr.length; i++) {
//         let val: any = {
//           type:
//             this.state.isTitle === "NegativeProducts"
//               ? "NegativeProducts"
//               : this.state.adKeywordType,
//           keyword: adKeywordsListArr[i],
//         };
//         if (this.state.adKeywordsTemp) {
//           newAdKeywords.push(val);
//         }
//         this.setState({ adGroups: newAdKeywords });
//       }
//       let newCreateCampaignFormData: any = this.state.CreateFormData;
//       newCreateCampaignFormData.negativeKeywordTargeting.keywords =
//         newAdKeywords;
//       console.log(newCreateCampaignFormData);
//       this.setState((prevState: any) => {
//         const updatedNegativeKeywordTargeting = {
//           targetingLevel: "Campaign level",
//           targetingType: this.state.isTitle || "Negative Keywords",
//           keywords: newAdKeywords,
//         };
//         const conditionMet = updatedNegativeKeywordTargeting.keywords.every(
//           (item: any) => item.type === "" && item.keyword === ""
//         );
//         console.log(conditionMet);

//         return {
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             negativeKeywordTargeting: conditionMet
//               ? null
//               : updatedNegativeKeywordTargeting,
//           },
//         };
//       });
//       // this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//       this.setState({ adKeywordsTemp: "" });
//     }
//   };
//   removeAllAdkeyWords = () => {
//     let newFormValues = this.setState({ adKeywords: [] });
//     let newCreateCampaignFormData: any = this.state.CreateFormData;
//     newCreateCampaignFormData.negativeKeywordTargeting.keywords = newFormValues;
//     this.setState({ newCreateCampaignFormData: newCreateCampaignFormData });
//   };
//   getPortfolioData = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/AdsManager/Portfolios";
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     });

//     try {
//       const responceData = await response.json();
//       this.setState({ portfolioData: responceData?.result.data });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   getMarketplaceData = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/AdsManager/Marketplaces";
//     const requestOptions = {
//       method: "get",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//       // body: JSON.stringify(this.state.createCampaignFormData),
//     };

//     try {
//       const response = await fetch(url, requestOptions);
//       const responceData = await response.json();
//       if (responceData.success) {
//         let result = responceData.result;
//         this.setState({ marketPlacesList: result });
//         console.error(result);
//       } else {
//         // toast("Unable to create");
//       }
//       // setRedirect("/onboarding");
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       // toast("Error On Creating");
//     }
//   };
//   submitCreateCampaing = async () => {
//     if (this.state.editTab == false) {
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       let url = "https://api.aimosa.io/Campaign/Auto";
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: AuthToken,
//         },
//         body: JSON.stringify(this.state.createCampaignFormData),
//       };

//       try {
//         const response = await fetch(url, requestOptions);
//         const responceData = await response.json();
//         if (responceData.success) {
//           let result = responceData.result;
//           console.error(result);
//           toast("Successfully created");
//         } else {
//           toast("Unable to create");
//         }
//         // setRedirect("/onboarding");
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         toast("Error On Creating");
//       }
//     } else {
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       let url = "https://api.aimosa.io/Campaign/Auto";
//       const requestOptions = {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: AuthToken,
//         },
//         body: JSON.stringify(this.state.createCampaignFormData),
//       };

//       try {
//         const response = await fetch(url, requestOptions);
//         const responceData = await response.json();
//         console.log(responceData);

//         if (responceData.success) {
//           let result = responceData.result;
//           console.error(result);
//           toast("Successfully Updated");
//         } else {
//           toast("Unable to update");
//         }
//         // setRedirect("/onboarding");
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         toast("Error On Creating");
//       }
//     }
//   };
//   matchTypeSelectAllClick = (check) => {
//     this.setState({ matchTypeSelect: check });
//     let matchTypeChecklistArr = [
//       "Close",
//       "Loose",
//       "Substitutes",
//       "Complements",
//     ];
//     if (check) {
//       this.setState({ matchTypeCheckedlist: matchTypeChecklistArr });
//     } else {
//       this.setState({ matchTypeCheckedlist: [] });
//     }
//   };
//   matchTypeCheckBoxClick = (e) => {
//     const ischecked = e.target.checked;
//     const newSelectedList = this.state.matchTypeCheckedlist;
//     if (ischecked === false) {
//       const index = newSelectedList.indexOf(e.target.value);
//       if (index > -1) {
//         newSelectedList.splice(index, 1);
//       }
//       this.setState({ matchTypeCheckedlist: newSelectedList });
//     } else {
//       const newArray = this.state.matchTypeCheckedlist;
//       newArray.push(e.target.value);
//       this.setState({ matchTypeCheckedlist: newArray });
//     }
//   };
//   matchTypeDelete = (name, e) => {
//     e.target.parentElement.firstChild.value = "";
//     let newFormValues = this.state.createCampaignFormData;
//     let genKey = name.split(".");
//     if (genKey.length === 1) {
//       newFormValues[genKey[0]] = "";
//     }
//     if (genKey.length === 2) {
//       newFormValues[genKey[0]][genKey[1]] = "";
//     }
//     console.log("Remove type Form Data: ", newFormValues);
//     this.setState({ createCampaignFormData: newFormValues });
//   };
//   handleTabClick = (tab: string) => {
//     this.setState({ selectedTab: tab });
//   };
//   handleTabHover = (tab) => {
//     this.setState({ selectedTab: tab });
//   };
//   handleChanges = (type, condition) => {
//     if (type === 1) {
//       this.setState({
//         isSelectedCampaign: type,
//         // imgstate: 1,
//       });
//     } else {
//       this.setState({
//         isSelectedCampaign: type,
//         // imgstate: null,
//       });
//       this.setState((prev: any) => ({
//         CreateFormData: {
//           ...prev.CreateFormData,
//           matchType: "MultipleCampaigns",
//         },
//       }));
//     }
//   };
//   placementChange = (type, e) => {
//     const value = e.target.value;
//     const parsedNumber = parseFloat(value.replace(/"/g, "").trim());
//     this.setState((prevState: any) => ({
//       CreateFormData: {
//         ...prevState.CreateFormData,
//         placement: {
//           ...prevState.CreateFormData.placement,
//           [e.target.name]: parsedNumber,
//         },
//       },
//     }));
//   };
//   settingChange = (e) => {
//     this.setState((prevState: any) => {
//       let updatedSettings = { ...prevState.CreateFormData.settings };
//       if (e.target.name === "portfolio") {
//         updatedSettings = {
//           ...updatedSettings,
//           portfolio: e.target.value,
//         };
//       } else {
//         const stringWithQuotes = e.target.value;
//         const parsedNumber = parseFloat(
//           stringWithQuotes.replace(/"/g, "").trim()
//         );
//         updatedSettings = {
//           ...updatedSettings,
//           dailyBudget: parsedNumber,
//         };
//       }
//       return {
//         CreateFormData: {
//           ...prevState.CreateFormData,
//           settings: updatedSettings,
//         },
//       };
//     });
//   };

//   postDataCampaign = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     let url = "https://api.aimosa.io/AdsManager/Ads";
//     const campaigns = [this.state.CreateFormData];
//     const requestOptions = {
//       method: "POST",
//       body: JSON.stringify({ campaigns }),
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     };

//     try {
//       const response = await fetch(url, requestOptions);
//       const responceData = await response.json();
//       if (response.ok) {
//         toast("Created Sucessfully");
//       }
//       console.log(responceData);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       toast("Error On Creating");
//     }
//   };

//   render() {
//     const { marketPlaces } = this.state.CreateFormData;
//     const marketplacesList = (item: any) => {
//       let list: any[] = [...marketPlaces];
//       if (list.includes(item)) {
//         const updatedList = list.filter((itemList) => itemList !== item);
//         this.setState((prevState: any) => ({
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             marketPlaces: updatedList,
//           },
//         }));
//         // this.setState({ isSelectedMarketPlace: updatedList });
//       } else {
//         list.push(item);
//         this.setState((prevState: any) => ({
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             marketPlaces: list,
//           },
//         }));
//         // this.setState({ isSelectedMarketPlace: list });
//       }
//       // const marketplacesList = (item: any) => {
//       //   let listing = marketPlaces.push(item);
//       //   this.setState((prevState: any) => ({
//       //     CreateFormData: {
//       //       ...prevState.CreateFormData,
//       //       marketPlaces: [listing],
//       //     },
//       //   }));
//       // this.setState({ isSelectedMarketPlace: item });
//       // console.log(this.state.CreateFormData.marketPlaces.includes(item));

//       // if(this.state.CreateFormData.marketPlaces.includes(item)){

//       // }
//       // if (itemList.includes(id:any)) {
//       // }
//       // if()
//       // this.setState({ isSelectedMarketPlace: item });
//       // this.setState((prevState: any) => ({
//       //   CreateFormData: {
//       //     ...prevState.CreateFormData,
//       //     marketPlaces: [item],
//       //   },
//       // }));
//       // console.log(item);
//     };

//     const marketplaces = this.state.marketPlacesList.map((item: any, index) => (
//       <div
//         key={index}
//         style={{ cursor: "pointer" }}
//         className={`marketplace-item ${
//           this.state.CreateFormData.marketPlaces.some(
//             (marketItem: any) => marketItem === item.countryCode
//           )
//             ? "marketplace-selected"
//             : null
//         }`}
//         onClick={() => marketplacesList(item.countryCode)}
//       >
//         {item.countryCode === "US" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "CA" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "ES" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "FR" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "DE" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/197/197571.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "IT" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/323/323325.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : item.countryCode === "AU" ? (
//           <>
//             <div className="d-flex align-items-center">
//               <img
//                 src="https://cdn-icons-png.flaticon.com/512/323/323367.png"
//                 alt="US Flag"
//                 className="flag-icons"
//               />
//               &nbsp;
//               {item.countryCode}
//             </div>
//           </>
//         ) : (
//           <> {item.countryCode}</>
//         )}
//       </div>
//     ));
//     const handleOptionClick = (type, condition) => {
//       this.setState((prevState: any) => ({
//         CreateFormData: {
//           ...prevState.CreateFormData,
//           settings: {
//             ...prevState.CreateFormData.settings,
//             campaignBiddingStrategy: type,
//           },
//         },
//         isSelected: type,
//       }));
//     };
//     console.log(this.state.CreateFormData);
//     const handleTabSelect = (title) => {
//       console.log(title);
//       this.setState({ isTitle: title });
//       this.setState((prevState: any) => ({
//         CreateFormData: {
//           ...prevState.CreateFormData,
//           negativeKeywordTargeting: {
//             ...prevState.CreateFormData.negativeKeywordTargeting,
//             targetingType: title,
//           },
//         },
//       }));
//     };
//     const keywordChangedynamic = (e: any) => {
//       const { name, value } = e.target;
//       const updatedMatchTypesList = this.state.matchTypesList.map((item) => {
//         if (item.nameid === name) {
//           return { ...item, name: value };
//         }
//         return item;
//       });
//       this.setState({ matchTypesList: updatedMatchTypesList });
//     };
//     const keywordChange = (e: any) => {
//       const value = e.target.value;
//       const parsedNumber = parseFloat(value.replace(/"/g, "").trim());
//       this.setState((prevState: any) => ({
//         CreateFormData: {
//           ...prevState.CreateFormData,
//           dynamicBidding: {
//             ...prevState.CreateFormData.dynamicBidding,
//             [e.target.name]: parsedNumber,
//           },
//         },
//       }));
//     };
//     const keywordChangeHandler = (item, e) => {
//       this.setState((prevState: any) => {
//         const updatedList = prevState.matchTypesList.map((listItem) => {
//           if (listItem.id === item.id && listItem.name === "") {
//             return { ...listItem, isChecked: !listItem.isChecked };
//           }
//           return listItem;
//         });

//         return {
//           matchTypesList: updatedList,
//         };
//       });
//     };
//     const selectedAllAdkeyWords = (e) => {
//       this.setState((prevState: any) => {
//         const allChecked = prevState.matchTypesList.every(
//           (item) => item.isChecked
//         );
//         const updatedList = prevState.matchTypesList.map((listItem) => ({
//           ...listItem,
//           isChecked: !allChecked,
//         }));
//         return {
//           matchTypesList: updatedList,
//         };
//       });
//     };
//     const removematchtypeHandler = (item: any, e) => {
//       this.setState((prevState: any) => {
//         const updatedList = prevState.matchTypesList.map((listItem) => {
//           if (listItem.id === item.id) {
//             return { ...listItem, name: "" };
//           }
//           return listItem;
//         });
//         console.log(updatedList);
//         this.setState({ matchTypesList: updatedList });
//       });
//     };
//     // const checkHandler = () => {

//     // const matchTypesListGroup = this.state.matchTypesList.filter(
//     //   (item) => item.isChecked === true
//     // );
//     // this.setState((prevState: any) => {
//     //   const updatedDynamicBidding = {
//     //     ...prevState.CreateFormData.dynamicBidding,
//     //   };
//     //   matchTypesListGroup.map((item) => {
//     //     updatedDynamicBidding[item.nameid] = parseInt(item.name);
//     //   });
//     //   this.setState(
//     //     (prevState: any) => ({
//     //       CreateFormData: {
//     //         ...prevState.CreateFormData,
//     //         dynamicBidding: updatedDynamicBidding,
//     //       },
//     //     }),
//     //     async () => {
//     //       await this.postDataCampaign();
//     //     }
//     //   );
//     // });
//     // };
//     const checkHandler = async () => {
//       const { CreateFormData } = this.state;
//       console.log(CreateFormData);
//       const validationCheck = {
//         portfolio: !!CreateFormData?.settings?.portfolio,
//         dailyBudget: !!CreateFormData?.settings?.dailyBudget,
//         targetingBid: !!CreateFormData?.dynamicBidding?.targetingBid,
//         defaultBid: !!CreateFormData?.dynamicBidding?.defaultBid,
//         campaignBiddingStrategy:
//           !!CreateFormData?.settings?.campaignBiddingStrategy,
//         marketPlaces: CreateFormData?.marketPlaces?.length > 0,
//       };
//       this.setState({ isError: validationCheck });
//     };
//     // const checkHandler = async () => {

//     // };
//     const imgChange = (item) => {
//       this.setState({ imgstate: item });
//       if (item === 2) {
//         this.setState((prev: any) => ({
//           CreateFormData: {
//             ...prev.CreateFormData,
//             matchType: "SingleCampaignWithMultipleAdgroup",
//           },
//         }));
//       } else {
//         this.setState((prev: any) => ({
//           CreateFormData: {
//             ...prev.CreateFormData,
//             matchType: "SingleCampaignWithSingleAdgroup",
//           },
//         }));
//       }
//     };
//     const resetObject = async () => {
//       const defaultValues = {
//         campaignType: "AutoCampaign",
//         campaignName: "",
//         asins: this.state.CreateFormData.asins,
//         matchType: "SingleCampaignWithSingleAdgroup",
//         placement: {
//           topOfSearch: undefined,
//           productDetailPages: undefined,
//           restOfSearch: undefined,
//         },
//         settings: {
//           portfolio: "",
//           dailyBudget: "",
//           startDate: "",
//           endDate: "",
//           campaignBiddingStrategy: "",
//         },
//         marketPlaces: [],
//         negativeKeywordTargeting: {},
//         dynamicBidding: {
//           defaultBid: "",
//           targetingBid: "",
//         },
//         targets: null,
//       };
//       const matchTypesListReset = [
//         { id: 1, nameid: "close", title: "Close", isChecked: true, name: "" },
//         { id: 2, nameid: "loose", title: "Loose", isChecked: true, name: "" },
//         {
//           id: 3,
//           nameid: "substitutes",
//           title: "Substitutes",
//           isChecked: true,
//           name: "",
//         },
//         {
//           id: 4,
//           nameid: "complements",
//           title: "Complements",
//           isChecked: true,
//           name: "",
//         },
//       ];
//       this.setState({ matchTypesList: matchTypesListReset });
//       this.setState({ isTitle: null });
//       this.setState({ isSelected: null });
//       this.setState({ adKeywords: [], adKeywordType: "" });
//       this.setState({ CreateFormData: defaultValues });
//     };

//     const submitHandler = async (e) => {
//       e.preventDefault();
//       const allTrue = Object.values(this.state.isError).every(
//         (value) => value === true
//       );
//       if (allTrue) {
//         const matchTypesListGroup = this.state.matchTypesList.filter(
//           (item) => item.isChecked === true
//         );
//         const updatedDynamicBidding = {
//           ...this.state.CreateFormData.dynamicBidding,
//         };
//         matchTypesListGroup.forEach((item) => {
//           updatedDynamicBidding[item.nameid] = parseFloat(
//             item.name.replace(/"/g, "").trim()
//           );
//         });
//         await this.setState((prevState: any) => ({
//           CreateFormData: {
//             ...prevState.CreateFormData,
//             dynamicBidding: updatedDynamicBidding,
//           },
//         }));
//         await this.setState((prevState: any) => {
//           const conditionMet = [
//             this.state.CreateFormData.negativeKeywordTargeting,
//           ];
//           const checknull = conditionMet.filter((item: any) => item.keywords);
//           // console.log(checknull.length);
//           if (checknull.length === 0) {
//             return {
//               CreateFormData: {
//                 ...prevState.CreateFormData,
//                 negativeKeywordTargeting: null,
//               },
//             };
//           }
//         });
//         await this.postDataCampaign();
//         await resetObject();
//       }
//     };
//     return (
//       <DashboardLayout>
//         <div className="main-content-container">
//           <hr />
//           <Tabs defaultActiveKey="AutoCampaign" className="ms-1">
//             <Tab eventKey="AutoCampaign" title="Auto Campaign">
//               <Form onSubmit={submitHandler}>
//                 <Container fluid>
//                   <Row>
//                     <Col md={8}>
//                       <p className="set-dynamic">
//                         Set dynamic campaign names. You can check the
//                         alternative placeholders{" "}
//                         <Tooltip
//                           title={
//                             <div className="popup-custom-box">
//                               <p>Alternative Placeholders:</p>
//                               <ul>
//                                 <li>&#10100;ASIN&#10101;</li>
//                                 <li>&#10100;Layer&#10101;</li>
//                                 <li>&#10100;Match Type&#10101;</li>
//                                 <li>&#10100;Tag&#10101;</li>
//                                 <li>&#10100;Targeting Type&#10101;</li>
//                               </ul>
//                             </div>
//                           }
//                           placement="bottom"
//                         >
//                           <span className="here-tooltip"> here</span>
//                         </Tooltip>
//                       </p>
//                       <div className="form-group">
//                         <h6 className="campaign-name-text">Campaign Name</h6>
//                         <div className="placeholder-input-wrapper">
//                           <input
//                             className="custom-input-main"
//                             name="createCampaignFormData.campaignName"
//                             onChange={(e) =>
//                               this.handleChange("campaignName", e)
//                             }
//                             value={this.state.CreateFormData.campaignName}
//                           />
//                         </div>
//                       </div>
//                       <h6 className="my-3 campaign-name-text">
//                         MatchType Options <img src={InfoIcon} alt="" />
//                       </h6>
//                       <div className="d-flex flex-wrap">
//                         <div
//                           style={{ marginRight: "20px" }}
//                           className="d-flex align-items-baseline"
//                         >
//                           <label>
//                             <input
//                               type="radio"
//                               className="form-check-input mx-1"
//                               // name="matchTypeOption"
//                               value="1"
//                               checked={this.state.isSelectedCampaign === 1}
//                               onChange={(e) => this.handleChanges(1, "auto")}
//                             />
//                           </label>
//                           <p className="set-dynamic">
//                             Create each matchtype in the same campaign
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-baseline">
//                           <label>
//                             <input
//                               type="radio"
//                               className="form-check-input mx-1"
//                               // name="matchTypeOption"
//                               value="0"
//                               checked={this.state.isSelectedCampaign === 0}
//                               onChange={(e) => this.handleChanges(0, "auto")}
//                             />
//                           </label>
//                           <p className="set-dynamic">
//                             {" "}
//                             Create each matchtype in individual campaigns
//                           </p>
//                         </div>
//                       </div>
//                       <h6 className="campaign-name-text">
//                         {" "}
//                         {this.state.isSelectedCampaign === 1
//                           ? "Select 1 of these options below :"
//                           : null}
//                       </h6>
//                       {this.state.isSelectedCampaign === 1 ? (
//                         <>
//                           {this.state.imgstate === 1 ? (
//                             <div className="d-flex" style={{ gap: "20px" }}>
//                               <div>
//                                 <img
//                                   src={require("../../../../assets/images/auto1-1.jpg")}
//                                   alt="alt-same"
//                                   style={{
//                                     maxHeight: "100%",
//                                     maxWidth: "100%",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => imgChange(1)}
//                                 />
//                               </div>
//                               <div>
//                                 <img
//                                   src={require("../../../../assets/images/auto1.jpg")}
//                                   alt="alt-individual"
//                                   style={{
//                                     maxHeight: "100%",
//                                     maxWidth: "100%",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => imgChange(2)}
//                                 />
//                               </div>
//                             </div>
//                           ) : (
//                             <div className="d-flex" style={{ gap: "20px" }}>
//                               <div>
//                                 <img
//                                   src={require("../../../../assets/images/auto1-2.png")}
//                                   alt="alt-same"
//                                   style={{
//                                     maxHeight: "100%",
//                                     maxWidth: "100%",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => imgChange(1)}
//                                 />
//                               </div>
//                               <div>
//                                 <img
//                                   src={require("../../../../assets/images/auto2.jpg")}
//                                   alt="alt-same"
//                                   style={{
//                                     maxHeight: "100%",
//                                     maxWidth: "100%",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => imgChange(1)}
//                                 />
//                               </div>
//                             </div>
//                           )}
//                         </>
//                       ) : (
//                         <div>
//                           <img
//                             src={individualcampaign}
//                             alt="alt-individualcampaign"
//                             style={{ maxWidth: "100%", maxHeight: "100%" }}
//                           />
//                         </div>
//                       )}
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={4}>
//                       {/* <div className="img-layout">
//                       <div>
//                         <div className="auto-img-border">
//                           <div className="auto-border">Auto Campaign</div>
//                           <div className="auto-border-line"></div>
//                           <div className="auto-border">Ad Group</div>
//                         </div>
//                         <div style={{ position: "relative" }}>
//                           <div className="horizontal-line"></div>
//                           <div className="d-flex justify-content-between">
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">Close</div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">Loose</div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">Substitute</div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                             <div style={{ position: "relative" }}>
//                               <div className="auto-border-item">
//                                 Complements
//                               </div>
//                               <div className="auto-border-doubleline"></div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div> */}
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={8}>
//                       <hr
//                         style={{
//                           border: "1px solid #E8EAED",
//                           borderWidth: "3px 0",
//                         }}
//                       />
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={8}>
//                       <div>
//                         <p className="placement-icon">Placement</p>
//                       </div>
//                       <div
//                         className="d-flex justify-content-between flex-wrap"
//                         style={{ gap: "20px" }}
//                       >
//                         <div style={{ flexGrow: "1" }} className="mb-3">
//                           <label htmlFor="basic-url" className="form-label">
//                             <span className="placement-text">
//                               Top of search(firstPage)
//                             </span>
//                           </label>
//                           <div className="d-flex mt-1">
//                             <input
//                               type="number"
//                               className="custom-input"
//                               // aria-label="Username"
//                               placeholder="0"
//                               name="topOfSearch"
//                               aria-describedby="basic-addon1"
//                               value={
//                                 this.state.CreateFormData.placement.topOfSearch
//                               }
//                               onChange={(e) =>
//                                 this.placementChange("top.placement", e)
//                               }
//                             />
//                             <span className="input-span" id="basic-addon1">
//                               %
//                             </span>
//                           </div>
//                           {/* {this.state.isError.topOfSearch === null ||
//                           this.state.isError.topOfSearch ? null : (
//                             <span className="text-danger">*requried</span>
//                           )} */}
//                         </div>
//                         <div style={{ flexGrow: "1" }}>
//                           <label htmlFor="basic-url" className="form-label">
//                             <span className="placement-text">
//                               Product detail Pages
//                             </span>
//                           </label>
//                           <div className="mt-1 d-flex">
//                             <input
//                               type="number"
//                               className="custom-input"
//                               // aria-label="Username"
//                               name="productDetailPages"
//                               placeholder="0"
//                               aria-describedby="basic-addon1"
//                               value={
//                                 this.state.CreateFormData.placement
//                                   .productDetailPages
//                               }
//                               onChange={(e) =>
//                                 this.placementChange("product.placement", e)
//                               }
//                             />
//                             <span className="input-span" id="basic-addon1">
//                               %
//                             </span>
//                           </div>
//                         </div>
//                         <div style={{ flexGrow: "1" }}>
//                           <label htmlFor="basic-url" className="form-label">
//                             <span className="placement-text">
//                               Rest of Search
//                             </span>
//                           </label>
//                           <div className="mt-1 d-flex">
//                             <input
//                               type="number"
//                               className="custom-input"
//                               // aria-label="Username"
//                               placeholder="0"
//                               aria-describedby="basic-addon1"
//                               name="restOfSearch"
//                               value={
//                                 this.state.CreateFormData.placement.restOfSearch
//                               }
//                               onChange={(e) =>
//                                 this.placementChange("rest.placement", e)
//                               }
//                             />
//                             <span className="input-span" id="basic-addon1">
//                               %
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={8}>
//                       <hr
//                         style={{
//                           border: "1px solid #E8EAED",
//                           borderWidth: "3px 0",
//                         }}
//                       />
//                     </Col>
//                   </Row>
//                   <Row>
//                     <div>
//                       <p className="Setting-icon">Settings</p>
//                     </div>
//                     <Col md={8} lg={4}>
//                       <span className="placement-text">
//                         Campaign Bidding Strategy
//                       </span>
//                       <div className="setting-button flex-wrap flex-sm-nowrap">
//                         <div
//                           className={`${
//                             this.state.isSelected === "Down only"
//                               ? "selected"
//                               : ""
//                           }`}
//                           onClick={() => handleOptionClick("Down only", "auto")}
//                         >
//                           Down only
//                         </div>
//                         <div
//                           className={`${
//                             this.state.isSelected === "Up & Down"
//                               ? "selected"
//                               : ""
//                           }`}
//                           onClick={() => handleOptionClick("Up & Down", "auto")}
//                         >
//                           Up & Down
//                         </div>

//                         <div
//                           className={`${
//                             this.state.isSelected === "Fixed" ? "selected" : ""
//                           }`}
//                           onClick={() => handleOptionClick("Fixed", "auto")}
//                         >
//                           Fixed
//                         </div>
//                       </div>
//                       {!this.state.isError.campaignBiddingStrategy && (
//                         <span className="text-danger">*requried</span>
//                       )}
//                       <div className="mt-3">
//                         <label htmlFor="basic-url" className="form-label">
//                           <span className="placement-text">Portfolio</span>
//                         </label>
//                         <select
//                           className="form-select"
//                           name="portfolio"
//                           // aria-label="Default select example"
//                           // style={{ maxWidth: "330px" }}
//                           onChange={this.settingChange}
//                           value={this.state.CreateFormData.settings.portfolio}
//                         >
//                           <option value="">Choose Portfolio</option>
//                           {this.state.portfolioData?.map((list: any, i) => (
//                             <option value={list.portfolioId} key={i}>
//                               {list.portfolioName}
//                             </option>
//                           ))}
//                         </select>
//                       </div>
//                       {!this.state.isError.portfolio && (
//                         <span className="text-danger">*requried</span>
//                       )}
//                     </Col>
//                     <Col md={8} lg={4}>
//                       <label htmlFor="basic-url" className="form-label">
//                         <span className="placement-text">Daily Budget</span>
//                       </label>
//                       <div className="daily-budget">
//                         <span className="input-span-daily" id="basic-addon1">
//                           $
//                         </span>
//                         <input
//                           type="number"
//                           required
//                           name="dailyBudget"
//                           className="custom-input-daily"
//                           placeholder="0"
//                           aria-describedby="basic-addon1"
//                           value={this.state.CreateFormData.settings.dailyBudget}
//                           onChange={this.settingChange}
//                         />
//                       </div>

//                       {!this.state.isError.dailyBudget && (
//                         <span className="text-danger">*requried</span>
//                       )}
//                       <div className="Daily-Budget-dates mt-3">
//                         <div>
//                           <label htmlFor="basic-url" className="form-label">
//                             <span className="placement-text"> Start</span>
//                           </label>
//                           <br />
//                           <DatePicker
//                             className="date-picker-autocampign"
//                             selected={this.state.startDate}
//                             onChange={(e) => {
//                               this.handleChangeDate("start", e);
//                             }}
//                           />
//                         </div>
//                         <div>
//                           <label htmlFor="basic-url" className="form-label">
//                             <span className="placement-text">End</span>
//                           </label>
//                           <br />
//                           <DatePicker
//                             className="date-picker-autocampign"
//                             selected={this.state.EndDate}
//                             onChange={(e) => {
//                               this.handleChangeDate("end", e);
//                             }}
//                           />
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={8}>
//                       <hr
//                         style={{
//                           border: "1px solid #E8EAED",
//                           borderWidth: "3px 0",
//                         }}
//                       />
//                     </Col>
//                   </Row>
//                   <Row>
//                     <div>
//                       <p className="marketplace-icon">Select Marketplaces</p>
//                     </div>
//                     {!this.state.isError.marketPlaces && (
//                       <span className="text-danger">*requried</span>
//                     )}
//                     <Col md={8}>
//                       <div className="marketplaces-id">{marketplaces}</div>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={8}>
//                       <hr
//                         style={{
//                           border: "1px solid #E8EAED",
//                           borderWidth: "3px 0",
//                         }}
//                       />
//                     </Col>
//                   </Row>
//                   <Row>
//                     <div>
//                       <p className="Targeting-icon">Negative Targeting</p>
//                     </div>
//                     <Col md={8}>
//                       <Tabs
//                         defaultActiveKey="NegativeKeywords"
//                         onSelect={handleTabSelect}
//                       >
//                         <Tab
//                           eventKey="NegativeKeywords"
//                           title="Negative Keywords"
//                         >
//                           <Container>
//                             <Row>
//                               <Col md={12}>
//                                 <div className="my-3">
//                                   <div className="form-check form-check-inline">
//                                     <input
//                                       className="form-check-input"
//                                       type="radio"
//                                       placeholder="Add Keyword"
//                                       name="negativeKeyword"
//                                       id="inlineRadio3"
//                                       checked={
//                                         this.state.adKeywordType ===
//                                         "Negative exact"
//                                       }
//                                       value="Negative exact"
//                                       onChange={(e) => this.addKeyWordType(e)}
//                                     />
//                                     <label
//                                       className="form-check-label"
//                                       htmlFor="inlineRadio3"
//                                     >
//                                       Negative exact
//                                     </label>
//                                   </div>
//                                   <div className="form-check form-check-inline">
//                                     <input
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="negativeKeyword"
//                                       id="inlineRadio4"
//                                       value="Negative phrase"
//                                       checked={
//                                         this.state.adKeywordType ===
//                                         "Negative phrase"
//                                       }
//                                       onChange={(e) => this.addKeyWordType(e)}
//                                     />
//                                     <label
//                                       className="form-check-label"
//                                       htmlFor="inlineRadio4"
//                                     >
//                                       Negative phrase
//                                     </label>
//                                   </div>
//                                   {/* <div className="form-check form-check-inline">
//                                   <input
//                                     className="form-check-input"
//                                     type="checkbox"
//                                     name="negativeKeyword"
//                                     id="inlineRadio4"
//                                     value="Negative phrase"
//                                     onChange={(e) => this.addKeyWordType(e)}
//                                   />
//                                   <label
//                                     className="form-check-label"
//                                     htmlFor="inlineRadio4"
//                                   >
//                                     Exact
//                                   </label>
//                                 </div> */}
//                                 </div>
//                                 <div className="add-keyword-container">
//                                   <textarea
//                                     name=""
//                                     placeholder="Add Keywords"
//                                     id="addKeywordsText"
//                                     className="form-control mb-3 input-box-control-auto"
//                                     onChange={(e) => this.addTempAdKeywords(e)}
//                                     style={{ width: "100%" }}
//                                     value={this.state.adKeywordsTemp}
//                                   ></textarea>
//                                   <button
//                                     type="button"
//                                     className="btn btn-outline mb-2 add-keyword-btn"
//                                     onClick={(e) => this.adkeyWordSubmit()}
//                                     disabled={!this.state.adKeywordType}
//                                     style={{
//                                       background: "#00FF00",
//                                     }}
//                                   >
//                                     Add Keyword
//                                   </button>
//                                 </div>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col md={12}>
//                                 <div
//                                   className="create-campaing-form table-responsive border"
//                                   style={{ width: "100%" }}
//                                 >
//                                   <table className="table">
//                                     <thead>
//                                       <tr>
//                                         <th>Keyword</th>
//                                         <th>Match Type</th>
//                                         <th></th>
//                                       </tr>
//                                     </thead>
//                                     <tbody>
//                                       {this.state.adKeywords?.map(
//                                         (adKeyword: any, i) => (
//                                           <tr>
//                                             <td>{adKeyword.keyword}</td>
//                                             <td>{adKeyword.type}</td>
//                                             <td className="text-center more-action">
//                                               <i
//                                                 onClick={() =>
//                                                   this.removeFormFields(i)
//                                                 }
//                                               >
//                                                 <img src={DeleteIcon} alt="" />
//                                               </i>
//                                             </td>
//                                           </tr>
//                                         )
//                                       )}
//                                     </tbody>
//                                   </table>
//                                 </div>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col
//                                 md={12}
//                                 className="d-flex justify-content-between my-4 0 keyword-added"
//                               >
//                                 <p>
//                                   {this.state.adKeywords?.length} keyword added
//                                 </p>
//                                 <span
//                                   className="text-end remove-all-link"
//                                   onClick={this.removeAllAdkeyWords}
//                                 >
//                                   Remove All
//                                 </span>
//                               </Col>
//                             </Row>
//                           </Container>
//                         </Tab>
//                         <Tab
//                           eventKey="NegativeProducts"
//                           title="Negative Products"
//                         >
//                           <Container>
//                             <Row>
//                               <Col md={12}>
//                                 {/* <div className="mt-2">
//                                 <h6 className="target-types">
//                                   Targeting Types
//                                 </h6>
//                               </div> */}
//                                 <div className="my-3">
//                                   {/* <div className="form-check form-check-inline">
//                                   <input
//                                     className="form-check-input"
//                                     type="radio"
//                                     name="negativeKeyword"
//                                     id="inlineRadio4"
//                                     value="Negative phrase"
//                                     onChange={(e) => this.addKeyWordType(e)}
//                                   />
//                                   <label
//                                     className="form-check-label"
//                                     htmlFor="inlineRadio4"
//                                   >
//                                     Product
//                                   </label>
//                                 </div> */}
//                                   {/* <div className="form-check form-check-inline">
//                                   <input
//                                     className="form-check-input"
//                                     type="radio"
//                                     name="negativeKeyword"
//                                     id="inlineRadio4"
//                                     value="Negative phrase"
//                                     onChange={(e) => this.addKeyWordType(e)}
//                                   />
//                                   <label
//                                     className="form-check-label"
//                                     htmlFor="inlineRadio4"
//                                   >
//                                     Category
//                                   </label>
//                                 </div> */}
//                                 </div>
//                                 <div className="add-keyword-container">
//                                   <textarea
//                                     name=""
//                                     id="addKeywordsText"
//                                     placeholder="Add Keywords"
//                                     className="form-control mb-3 input-box-control-auto"
//                                     onChange={(e) => this.addTempAdKeywords(e)}
//                                     style={{ width: "100%" }}
//                                     value={this.state.adKeywordsTemp}
//                                   ></textarea>
//                                   <button
//                                     type="button"
//                                     className="btn btn-outline mb-2 add-keyword-btn"
//                                     onClick={(e) => this.adkeyWordSubmit()}
//                                     // disabled={!this.state.adKeywordType}
//                                     style={{
//                                       background: "#00FF00",
//                                     }}
//                                   >
//                                     Add Keyword
//                                   </button>
//                                 </div>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col md={12}>
//                                 <div
//                                   className="create-campaing-form table-responsive border"
//                                   style={{ width: "100%" }}
//                                 >
//                                   <table className="table">
//                                     <thead>
//                                       <tr>
//                                         <th>Keyword</th>
//                                         <th>Match Type</th>
//                                         <th></th>
//                                       </tr>
//                                     </thead>
//                                     <tbody>
//                                       {this.state.adKeywords?.map(
//                                         (adKeyword: any, i) => (
//                                           <tr>
//                                             <td>{adKeyword.keyword}</td>
//                                             <td>{adKeyword.type}</td>
//                                             <td className="text-center more-action">
//                                               <i
//                                                 onClick={() =>
//                                                   this.removeFormFields(i)
//                                                 }
//                                               >
//                                                 <img src={DeleteIcon} alt="" />
//                                               </i>
//                                             </td>
//                                           </tr>
//                                         )
//                                       )}
//                                     </tbody>
//                                   </table>
//                                 </div>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col
//                                 md={12}
//                                 className="d-flex justify-content-between my-4 0 keyword-added"
//                               >
//                                 <p>
//                                   {this.state.adKeywords?.length} keyword added
//                                 </p>
//                                 <span
//                                   className="text-end remove-all-link"
//                                   onClick={this.removeAllAdkeyWords}
//                                 >
//                                   Remove All
//                                 </span>
//                               </Col>
//                             </Row>
//                           </Container>
//                         </Tab>
//                       </Tabs>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={8}>
//                       <hr
//                         style={{
//                           border: "1px solid #E8EAED",
//                           borderWidth: "3px 0",
//                         }}
//                       />
//                     </Col>
//                   </Row>
//                   {/* <Row>
//                   <div>
//                     <h6>Add Group(s)</h6>
//                   </div>
//                   <Col md={8}>
//                     <form>
//                       <input
//                         type="text"
//                         className="form-control"
//                         defaultValue={""}
//                         aria-label="Username"
//                         placeholder="enter ad group name"
//                         aria-describedby="basic-addon1"
//                       />
//                     </form>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col className="my-3">
//                     <div className="add-gropu-btn">Ad Group</div>
//                   </Col>
//                 </Row> */}
//                   <Row>
//                     <Col md={8}>
//                       <form>
//                         <div
//                           className="d-flex justify-content-between flex-wrap"
//                           style={{ columnGap: "10px" }}
//                         >
//                           <div style={{ flexGrow: "1" }}>
//                             <div>
//                               <h6 className="custom-input-text">
//                                 Targeting Bid (X)
//                               </h6>
//                             </div>
//                             <div>
//                               <input
//                                 required
//                                 type="number"
//                                 name="targetingBid"
//                                 className="form-control"
//                                 placeholder="0"
//                                 aria-describedby="basic-addon1"
//                                 onChange={keywordChange}
//                                 value={
//                                   this.state.CreateFormData.dynamicBidding
//                                     .targetingBid
//                                 }
//                               />
//                             </div>
//                             {/* {this.state.isError.targetingBid === null ||
//                             this.state.isError.targetingBid ? null : (
//                               <span className="text-danger">*requried</span>
//                             )} */}
//                             {!this.state.isError.targetingBid && (
//                               <span className="text-danger">*requried</span>
//                             )}
//                           </div>

//                           <div style={{ flexGrow: "1" }}>
//                             <div>
//                               <h6 className="custom-input-text">Default Bid</h6>
//                             </div>
//                             <div>
//                               <input
//                                 required
//                                 name="defaultBid"
//                                 type="number"
//                                 value={
//                                   this.state.CreateFormData.dynamicBidding
//                                     .defaultBid
//                                 }
//                                 className="form-control"
//                                 placeholder="0"
//                                 aria-describedby="basic-addon1"
//                                 onChange={keywordChange}
//                               />
//                             </div>
//                             {/* {this.state.isError.defaultBid === null ||
//                             this.state.isError.defaultBid ? null : (
//                               <span className="text-danger">*requried</span>
//                             )} */}
//                             {!this.state.isError.defaultBid && (
//                               <span className="text-danger">*requried</span>
//                             )}
//                           </div>
//                         </div>
//                       </form>
//                     </Col>
//                   </Row>
//                   <Row className="my-3">
//                     <Col md={8}>
//                       <div className="d-flex justify-content-between align-items-center">
//                         <h6 className="custom-input-text">Match Types</h6>
//                         <span
//                           className="text-end remove-all-link"
//                           id="selectAll"
//                           // checked={this.state.matchTypesList.every(
//                           //   (item: any) => item.isChecked
//                           // )}
//                           onClick={(e) => {
//                             selectedAllAdkeyWords(e);
//                           }}
//                         >
//                           {this.state.matchTypesList.some(
//                             (item: any) => !item.isChecked
//                           )
//                             ? "Select All"
//                             : "Deselect All"}
//                         </span>
//                       </div>
//                     </Col>
//                     <Col md={8} className="my-3">
//                       <form>
//                         <div
//                           className="justify-content-between d-flex flex-wrap flex-sm-nowrap"
//                           style={{ gap: "10px" }}
//                         >
//                           {this.state.matchTypesList.map((item, index) => {
//                             return (
//                               <div style={{ flexGrow: "1" }}>
//                                 <div className="form-check form-check-inline">
//                                   <input
//                                     className="form-check-input"
//                                     type="checkbox"
//                                     id={`checkbox-${item.id}`}
//                                     value="Negative exact"
//                                     checked={item.isChecked}
//                                     onChange={(e) => {
//                                       keywordChangeHandler(item, e);
//                                     }}
//                                   />
//                                   <label
//                                     className="form-check-label custom-input-text"
//                                     htmlFor="inlineRadio3"
//                                   >
//                                     {item.title}
//                                   </label>
//                                 </div>
//                                 <div className="d-flex">
//                                   <input
//                                     className="custom-input"
//                                     value={item.name}
//                                     // defaultValue={item.name ? item.name : 0}
//                                     name={item.nameid}
//                                     placeholder="0"
//                                     aria-describedby="basic-addon1"
//                                     onChange={keywordChangedynamic}
//                                   />
//                                   <span
//                                     className="input-span"
//                                     id="basic-addon1"
//                                     onClick={(e) =>
//                                       removematchtypeHandler(item, e)
//                                     }
//                                   >
//                                     X
//                                   </span>
//                                 </div>
//                               </div>
//                             );
//                           })}
//                         </div>
//                       </form>
//                     </Col>
//                   </Row>
//                 </Container>
//                 <div className="form-button-group">
//                   <Link
//                     to={"/ads/ads-creation"}
//                     className="btn btn-outline-primary"
//                   >
//                     Back
//                   </Link>
//                   <button
//                     className="btn btn-primary"
//                     // type="submit"
//                     onClick={checkHandler}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </Form>
//             </Tab>
//             {/* <button disabled>hi</button> */}
//             <Tab
//               eventKey="ManualCampaign"
//               title="Manual Campaign"
//               className="title-card-tab"
//               disabled={true}
//             >
//               {/* <CreateManualCampaign /> */}
//             </Tab>
//           </Tabs>
//         </div>
//         {/* <div className="img-layout">
//           <div>
//             <div className="auto-img-border">
//               <div className="auto-border">Auto Campaign</div>
//               <div className="auto-border-line"></div>
//               <div className="auto-border">Ad Group</div>
//             </div>
//             <div style={{ position: "relative" }}>
//               <div className="horizontal-line"></div>
//               <div className="d-flex justify-content-between">
//                 <div style={{ position: "relative" }}>
//                   <div className="auto-border-item">Close</div>
//                   <div className="auto-border-doubleline"></div>
//                 </div>
//                 <div style={{ position: "relative" }}>
//                   <div className="auto-border-item">Loose</div>
//                   <div className="auto-border-doubleline"></div>
//                 </div>
//                 <div style={{ position: "relative" }}>
//                   <div className="auto-border-item">Substitute</div>
//                   <div className="auto-border-doubleline"></div>
//                 </div>
//                 <div style={{ position: "relative" }}>
//                   <div className="auto-border-item">Complements</div>
//                   <div className="auto-border-doubleline"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div> */}
//         {/* <div>
//           <div className="option-1">
//             <div className="auto-campaign-wrapper">
//               <p> Auto Campaign</p>
//             </div>
//             <div className="close-wrapper">
//               <p> Auto Campaign</p>
//             </div>
//             <div className="loose-wrapper">
//               <p> Auto Campaign</p>
//             </div>
//             <div className="substitute-wrapper">
//               <p> Auto Campaign</p>
//             </div>
//             <div className="complements-wrapper">
//               <p> Auto Campaign</p>
//             </div>
//             <div className="ad-group-wrapper">
//               <p> Auto Campaign</p>
//             </div> */}
//         {/* <LineIcon />
//             <LineIcon />
//             <LineIcon />
//             <LineIcon />
//             <LineIcon />
//             <Check /> */}
//         {/* </div> */}
//         {/* // </div> */}
//       </DashboardLayout>
//     );
//   }
// }

// export default CreateAutoCampaign;
// // import React from "react";
// // import DashboardLayout from "../../../../layouts/DashboardLayout";

// // const CreateAutoCampaign: React.FunctionComponent = () => {
// //   return <DashboardLayout>createAutoCampaign</DashboardLayout>;
// // };

// // export default CreateAutoCampaign;
import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DashboardLayout from "../../../../layouts/XDashboardLayout";
import { Col, Row, Container } from "react-bootstrap";

import LastAppSync from "../lastAppSync";
import AutoCampaign from "./campaigns/autoCampaign";
class createAutoCampaign extends Component {
  render() {
    return (
      <DashboardLayout>
        <LastAppSync />
        <div className="main-content-container">
          <hr />
          <Container fluid>
            <Row>
              <Col>
                <Tabs defaultActiveKey="AutoCampaign" className="ms-1">
                  <Tab eventKey="AutoCampaign" title="Auto Campaign">
                    <AutoCampaign />
                  </Tab>
                  <Tab
                    eventKey="ManualCampaign"
                    title="Manual Campaign"
                    disabled
                  >
                    ManualCampaign
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </DashboardLayout>
    );
  }
}

export default createAutoCampaign;
