import { apiSlice } from "../api/apiSlice";

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loggedInUser: builder.query({
      query: () => ({
        url: `/Users/Me`,
        method: "GET",
      }),
      providesTags: ["loggedInUser"],
    }),
    userProfileImageChange: builder.mutation({
      query: (data) => ({
        url: "/Users/ProfileImage",
        method: "PUT",
        body: data,
        headers: {
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["loggedInUser"],
    }),
    userProfileImageDelete: builder.mutation({
      query: () => ({
        url: "/Users/DeleteProfileImage",
        method: "DELETE",
      }),
      invalidatesTags: ["loggedInUser"],
    }),
    changeUserPassword: builder.mutation({
      query: (data) => ({
        url: `/Users/Changepassword`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["loggedInUser"],
    }),
    getUserProfile: builder.mutation({
      query: () => ({
        url: `/Users/Profile`,
        method: "GET",
      }),
    }),
    syncUserBackgroundService: builder.mutation({
      query: (data) => ({
        url: `/BackgroundServices/Ads/SyncUserQueue`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["loggedInUser"],
    }),
    updateUserGeneralInformation: builder.mutation({
      query: (data) => ({
        url: `/Users/Profile`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["loggedInUser"],
    }),
    requestUpdateUserEmail: builder.mutation({
      query: (data) => ({
        url: `/Users/ProfileEmail`,
        method: "PUT",
        body: data,
      }),
    }),
    verifyToUpdateEmail: builder.mutation({
      query: (data) => ({
        url: `/Users/VerifyEmail`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["loggedInUser"],
    }),
  }),
});

export const {
  useLoggedInUserQuery,
  useUserProfileImageChangeMutation,
  useChangeUserPasswordMutation,
  useUserProfileImageDeleteMutation,
  useGetUserProfileMutation,
  useSyncUserBackgroundServiceMutation,
  useUpdateUserGeneralInformationMutation,
  useRequestUpdateUserEmailMutation,
  useVerifyToUpdateEmailMutation,
} = authSlice;
