import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "features/api/apiSlice";
import stepCounterReducer from "features/onboardingStep/onboardingStep";
import automateRuleAddStepCounterReducer from "features/automateRuleAdd/automateRuleAddStateSlice";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        stepCounter: stepCounterReducer,
        automateRuleAddStepCounter: automateRuleAddStepCounterReducer
    },

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export default store