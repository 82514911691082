import cn from "utils/cn";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Step, StepIconProps, StepLabel, Stepper } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface TabButtonProps {
  children?: React.ReactNode;
  className?: string;
  path: string;
  to?: string;
}

export function PageButton(props: TabButtonProps) {
  const { children, className, path } = props;
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        cn(
          "w-full flex items-center py-3 px-3 gap-3 font-medium text-sm leading-5 !text-am-aiGray rounded-lg !no-underline hover:!no-underline hover:text-black",
          className,
          isActive
            ? "bg-am-primary-light/5 border-r-2 border-am-primary"
            : "bg-transparent"
        )
      }
    >
      {children}
    </NavLink>
  );
}

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props;
  return (
    <div
      className={cn(
        "h-[26px] w-[26px] rounded-full flex items-center justify-center border-[1px] mr-1",
        active || completed
          ? "border-[#237804] bg-[#237804] text-white"
          : "border-[#737373] text-[#737373]"
      )}
    >
      {icon}
    </div>
  );
};

const AddNewAutomationRuleLayout = () => {
  /**-- React dom hook --*/
  const location = useLocation();
  // extract page step name
  const stepPageName = location.pathname.split("/");

  const activeStep = useSelector(
    (state: RootState) => state.automateRuleAddStepCounter.activeStep
  );
  const completed = useSelector(
    (state: RootState) => state.automateRuleAddStepCounter.completeStep
  );

  /**-- Variable --*/
  // step list
  const steps = [
    {
      title: "apply to",
    },
    {
      title: "if",
    },
    {
      title: "then",
    },
    {
      title: "timeline",
    },
  ];

  return (
    <section className="grow flex flex-col">
      {/* back button */}
      <Link
        to={"/"}
        className="bg-white py-2 px-3 w-full text-left flex flex-row items-center !font-semibold leading-5 text-sm gap-2 !text-[#737373] hover:!no-underline"
      >
        <IoIosArrowBack className="w-5 h-5" />
        Back
      </Link>
      <div className="flex-grow grid grid-cols-1 md:grid-cols-4 lg:grid-cols-8 xl:grid-cols-11 2xl:grid-cols-6 mt-6 gap-10">
        {/* page stepper ui */}
        <div className="col-span-1 md:col-span-1 lg:col-span-3 xl:col-span-3 2xl:col-span-1 flex flex-col ">
          <div className="w-full bg-white p-6 rounded-xl gap-3 flex flex-col">
            <h4 className="!font-medium leading-8 text-[#1C1C1C] text-xl !m-0">
              Automation Process
            </h4>
            <Stepper orientation="vertical" activeStep={activeStep}>
              {steps.map((step, idx) => (
                <Step key={idx} completed={completed[idx]}>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    StepIconProps={{ icon: idx + 1 }}
                    className="capitalize"
                  >
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        {/* tab content */}
        <div className="col-span-1 md:col-span-3 lg:col-span-5 xl:col-span-8 2xl:col-span-5 flex-grow h-full px-8 py-6 bg-white rounded-xl">
          <div className="grow flex flex-col gap-6">
            {/* header */}
            <h2 className="border-b border-[#E5E7EB] !leading-8 !font-bold !text-xl !text-[#171717] m-0 pb-3 capitalize">
              {stepPageName[stepPageName.length - 1].split("-").join(" ")}
            </h2>
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddNewAutomationRuleLayout;
