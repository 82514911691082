import logo from "../../assets/brand/logo.svg";
import emailLogo from "../../assets/icons/email_custom.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import InfoBox from "components/infoBox/InfoBox";
import { useEffect, useState } from "react";
import cn from "utils/cn";
import { useResendActiveEmailMutation } from "features/auth/authSlice";

const EmailVerification = () => {
  // navigation utils
  const navigate = useNavigate();

  // navigate bage to previous page
  const handleBack = () => {
    navigate(-1);
  };

  // STATE: - Time counter state
  const [countDown, setCountDown] = useState<number>(60);

  // ERROR STATE: - Resend email error
  const [errorState, setErrorState] = useState<string | null>(null);

  // Countdown effect
  useEffect(() => {
    // If the timer reaches zero, do nothing
    if (countDown === 0) return;

    // Update the countdown every second
    const timer = setInterval(() => {
      setCountDown((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        }
        return prevTime;
      });
    }, 1000);

    // Clear the timer when component unmounts or when timeLeft changes
    return () => clearInterval(timer);
  }, [countDown]);

  // prev page state
  // location: - react-router-dom utils
  const location = useLocation();

  // extract email from prev state
  const state = location?.state || null;
  const userEmail = state?.email;

  // RTK: mutation
  const [resendEmail] = useResendActiveEmailMutation();

  const handleResendEmail = async () => {
    try {
      const res = await resendEmail({ email: userEmail });
      if (res?.data?.success) {
        setCountDown(60);
      } else {
        const err: any = res?.error;
        if (err?.data?.message === "User already active") {
          setErrorState("Account already activated");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return state ? (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-32" />
        </div>
        <hr className="my-0 !border-[#D4D4D4]" />
        <div className="flex flex-col items-center justify-center gap-2">
          <img src={emailLogo} alt="" className="w-[100px]" />
          <h4 className="font-medium text-xl leading-8 text-center text-[#171717]">
            Check your email to verify
          </h4>
        </div>
        <div className="flex max-w-[340px] flex-col gap-4">
          <p className="m-0  text-[16px] font-medium leading-6 text-[#171717]">
            We already sent and activation link to {userEmail}. Please open the
            email and click the link to continue.
          </p>
          <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
            Didn't receive the email? Please check spam folder or
            <button
              type="button"
              className={cn(
                `font-bold text-[#237804] ml-1 underline`,
                countDown === 0 ? "!text-[#237804]" : "!text-[#A3A3A3]"
              )}
              disabled={countDown !== 0}
              onClick={handleResendEmail}
            >
              Resend.
            </button>
          </p>
          {/* message */}
          <div className="flex items-center">
            <InfoBox
              type={errorState ? "danger" : "warning"}
              className="w-full"
              message={
                errorState
                  ? errorState
                  : `You need to wait 00:${countDown} seconds before resend email`
              }
            />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <button
            onClick={handleBack}
            type="button"
            className="flex items-center justify-center gap-2 text-[#171717] font-medium text-[16px] leading-6"
          >
            {" "}
            <FaArrowLeftLong /> Change email
          </button>
        </div>
      </div>
    </div>
  ) : (
    <>{navigate(-1)}</>
  );
};

export default EmailVerification;
