import { useLoggedInUserQuery } from "features/user/userSlice";
import { Outlet } from "react-router";

const OnboardingLayout = () => {
    /**-RTK-**/
    //queries
    const { data: loggedInUserData } = useLoggedInUserQuery({})

    console.log("loggedInUserData", loggedInUserData)

    return (
        <section className="grow grid">
            <Outlet />
        </section>
    );
};

export default OnboardingLayout;