import React, { FC } from "react";
import { CiSearch } from "react-icons/ci";

interface Props {
  changeHandler?: (s: string) => void;
}

const SearchInput: FC<Props> = ({ changeHandler }) => {
  return (
    <div className="flex items-center ">
      {/* input */}
      <div className="w-full relative">
        {/* icon */}
        <span className="absolute flex items-center left-0 top-[50%] translate-y-[-50%] px-2.5">
          <CiSearch className="h-5 w-5" />
        </span>
        <input
          className="w-full outline-none border-[1px] border-[#D1D5DB] py-[7px] px-4 rounded-[4px] pl-9 leading-5"
          placeholder="Search by name or tags..."
          onChange={(e) => {
            changeHandler && changeHandler(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default SearchInput;
