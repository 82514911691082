import React, { FC, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import Modal from "@mui/material/Modal";
import { MdCloudUpload } from "react-icons/md";
import { getCroppedImg } from "utils/cropImage";
import cameraIcon from "assets/icons/camera.svg";
import cn from "utils/cn";
import { useUserProfileImageChangeMutation } from "features/user/userSlice";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";
import { CircularProgress } from "@mui/material";

// Define types for crop and zoom settings
interface Crop {
  x: number;
  y: number;
}

interface CropSize {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Props {
  className?: string;
  onClick?: () => void;
}

const ImageUpload: FC<Props> = ({ className }) => {
  // RTK hook
  const [changeProfileImage, { isLoading }] =
    useUserProfileImageChangeMutation();

  // File input ref
  const imageInputRef = useRef<HTMLInputElement>(null);

  // Image state
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    undefined
  );

  // Image cropping state
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropSize | null>(
    null
  );

  const onCropComplete = (croppedArea: any, croppedAreaPixels: CropSize) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // Modal state
  const handleOpen = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  // handle close the modal
  const handleClose = () => {
    setImagePreview(undefined);
  };

  // Handle file input change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      setImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImagePreview(undefined);
    }
  };

  // Handle profile image upload
  const handleProfileImageUpload = async () => {
    if (!croppedAreaPixels) {
      return;
    }

    try {
      if (image) {
        const imageUrl = URL.createObjectURL(image);

        const croppedImg = await getCroppedImg(imageUrl, croppedAreaPixels);

        if (croppedImg) {
          const formData = new FormData();
          formData.append("File", croppedImg);

          const res = await changeProfileImage(formData);

          if (res?.data?.success) {
            handleClose();
            NotifyMessage({
              type: "success",
              message: "Profile image updated successfully",
            });
          } else {
            NotifyMessage({
              type: "error",
              message: "Failed to upload image",
            });
          }
        }
      }
    } catch (e) {
      handleClose();
      NotifyMessage({
        type: "error",
        message: "Error cropping the image, try again",
      });
    }
  };

  return (
    <div>
      <button type="button" className={cn(className)} onClick={handleOpen}>
        <input
          hidden
          ref={imageInputRef}
          type="file"
          onChange={handleFileChange}
          accept="image/*" // Optional: restrict to image files
        />
        <img src={cameraIcon} alt="camera" className="w-[30px]" />
      </button>

      {/* Snackbar for request status */}
      <Modal
        open={Boolean(imagePreview)}
        onClose={handleClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <div className="flex flex-col bg-transparent items-center justify-center h-screen gap-2 relative">
          <div className="relative flex h-[300px] w-[300px]">
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              style={{
                containerStyle: {
                  width: "100%",
                  height: "100%",
                },
                mediaStyle: {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              }}
            />
          </div>
          <button
            type="button"
            onClick={handleProfileImageUpload}
            className="text-white bg-[#237804] py-2 px-3 rounded-[4px] font-bold flex items-center gap-2"
            disabled={isLoading} // Optionally disable while loading
          >
            {isLoading && (
              <CircularProgress className="!text-white" size={20} />
            )}
            <MdCloudUpload /> Upload
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ImageUpload;
