import smsIcon from "assets/icons/sms.svg";
import doneIcon from "assets/icons/done.svg";
import ImageUpload from "./ImageUpload";

import EmailUpdate from "./EmailUpdate";
import PasswordUpdate from "./PasswordUpdate";
import { Avatar, CircularProgress } from "@mui/material";
import {
  useLoggedInUserQuery,
  useUserProfileImageDeleteMutation,
} from "features/user/userSlice";

import cn from "utils/cn";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";
import EditGeneralInfo from "./EditGeneralInfo";
import {
  useGetCurrencyTypeSelectionQuery,
  useGetTimezoneSelectionQuery,
} from "features/masterData/masterDataSlice";
import { getCountryName, getGTMTimezone } from "utils/getTimezoneList";

const Profile = () => {
  // RTK: query user data fetching
  const { data } = useLoggedInUserQuery({});
  const { data: currencyData } = useGetCurrencyTypeSelectionQuery({});
  const { data: timezoneData } = useGetTimezoneSelectionQuery({});
  // RTK: mutation user profile delete
  const [deleteProfileImage, { isLoading: isRemoveProfileLoading }] =
    useUserProfileImageDeleteMutation();

  // handle profile delete
  const handleProfileDelete = async () => {
    try {
      const res = await deleteProfileImage({});
      if (res?.data?.success) {
        NotifyMessage({
          type: "success",
          message: "Profile image removed successfully",
        });
      } else {
        NotifyMessage({
          type: "error",
          message: "Error removing profile image",
        });
      }
    } catch (error) {
      NotifyMessage({
        type: "error",
        message: "Error removing profile image",
      });
    }
  };

  /**-Data Transformation */
  // decorate currency selction data
  let currencyDataSelection: string = "";

  currencyData?.result?.data?.columns?.forEach((d) => {
    if (d?.currencyCodes === data?.result?.currencyTypeSelection) {
      currencyDataSelection = `${d?.countryCodes} - ${
        getCountryName(d?.countryCodes) || ""
      }`;
    }
  });

  // decorate timezone selection data
  let timezoneSelectionData: string = "";

  timezoneData?.result?.data?.columns?.forEach((d) => {
    if (data?.result?.timeZoneSelection === d?.countryCodes) {
      timezoneSelectionData = getGTMTimezone(d?.countryCodes);
    }
  });

  return (
    <div className="grow flex flex-col gap-6">
      {/* header */}
      <h2 className="border-b border-[#E5E7EB] !leading-8 !font-bold !text-xl !text-[#171717] m-0 pb-3">
        My Profile
      </h2>
      {/* profile image */}
      <div className="flex gap-3 px-6 py-6 border-[1px] rounded-lg items-center ">
        {/* image */}
        <div className="flex items-center justify-center relative">
          <Avatar
            src={data?.result?.image}
            alt="profile"
            sx={{
              width: 100,
              height: 100,
            }}
          />
          {/* change profile picture icon */}
          <ImageUpload
            className="absolute bottom-1 right-1"
            onClick={() => {}}
          />
        </div>
        <div className="flex flex-col grow gap-1">
          <h4 className="text-[#111827] !font-bold text-xl !leading-8">
            {data?.result?.name}
          </h4>
          <p className="flex gap-2">
            <img src={smsIcon} alt="icon" className="w-[18px]" />
            <span className="text-[#6B7280] text-sm leading-5 font-medium">
              {data?.result?.email}
            </span>
            <img src={doneIcon} alt="icon" className="w-[18px]" />
          </p>
          <div>
            <button
              onClick={handleProfileDelete}
              className={cn(
                " text-sm leading-5 font-semibold underline flex items-center gap-2",
                data?.result?.image.includes("DefaultProfileImage.png")
                  ? "text-[#9ba0aa] no-underline"
                  : "text-[#DC2626]"
              )}
              disabled={
                data?.result?.image.includes("DefaultProfileImage.png") ||
                isRemoveProfileLoading
                  ? true
                  : false
              }
            >
              {isRemoveProfileLoading && (
                <CircularProgress className="!text-red-500" size={15} />
              )}
              Remove Profile Picture
            </button>
          </div>
        </div>
      </div>
      {/* details form */}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 xl:gap-8">
        {/* details input */}
        <div className="flex flex-col gap-6 p-6 border-[1px] border-[#E5E7EB] rounded-lg">
          {/* use details title with edit*/}
          <div className="flex flex-row justify-between items-center">
            <h4 className="m-0 text-xl text-[#111827] !font-bold leading-8">
              General Information
            </h4>
            {/* <button className="py-2 px-6 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]">
              Edit
            </button> */}
            <EditGeneralInfo />
          </div>
          {/* user info display */}
          <div className="flex flex-col gap-5">
            {/* name input */}
            <div className="flex flex-col gap-2">
              <label
                htmlFor="name"
                className="!font-bold leading-6 text-[16px] text-[#111827]"
              >
                Name
              </label>
              <div className="w-full">
                <button
                  type="button"
                  className={cn(
                    "w-full flex justify-between items-center border-[1px] border-[#D1D5DB] py-2 px-3 rounded-md font-normal text-[16px] leading-6",
                    "text-[#9CA3AF]"
                  )}
                >
                  {data?.result?.name}
                </button>
              </div>
            </div>
            {/* Currency type input */}
            <div className="flex flex-col gap-2">
              <label
                htmlFor="name"
                className="!font-bold leading-6 text-[16px] text-[#111827]"
              >
                Currency Type
              </label>
              <div className="w-full">
                <button
                  type="button"
                  className={cn(
                    "w-full flex justify-between items-center border-[1px] border-[#D1D5DB] py-2 px-3 rounded-md font-normal text-[16px] leading-6",
                    "text-[#9CA3AF]"
                  )}
                >
                  {data?.result?.currencyTypeSelection
                    ? currencyDataSelection
                    : "Select currency"}
                </button>
              </div>
            </div>
            {/* Time zone input */}
            <div className="flex flex-col gap-2">
              <label
                htmlFor="name"
                className="!font-bold leading-6 text-[16px] text-[#111827]"
              >
                Time Zone Selection
              </label>
              <div className="w-full">
                <button
                  type="button"
                  className={cn(
                    "w-full flex justify-between items-center border-[1px] border-[#D1D5DB] py-2 px-3 rounded-md font-normal text-[16px] leading-6",
                    "text-[#9CA3AF]"
                  )}
                >
                  {data?.result?.timeZoneSelection
                    ? timezoneSelectionData
                    : "Select time zone"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* currency and time zone */}
        <div className="flex flex-col gap-6 ">
          {/* email input */}
          <div className="flex flex-col gap-6 p-6 border-[1px] border-[#E5E7EB] rounded-lg">
            {/*email title*/}
            <div className="flex flex-row justify-between items-center">
              <h4 className="m-0 text-xl text-[#111827] !font-bold leading-8">
                Email Information
              </h4>
            </div>
            <EmailUpdate />
          </div>
          {/* password update */}
          <div className="flex flex-col gap-6 p-6 border-[1px] border-[#E5E7EB] rounded-lg">
            {/*email title*/}
            <div className="flex flex-row justify-between items-center">
              <h4 className="m-0 text-xl text-[#111827] !font-bold leading-8">
                Password Change
              </h4>
            </div>
            <PasswordUpdate />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
