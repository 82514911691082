import logo from "assets/brand/logo.svg";
import successLogo from "assets/icons/payment_success.svg";
import { Link } from "react-router-dom";

function SubscriptionSuccess() {
    return (
        <div className="grow grid place-items-center">
            <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6">
                <div className="flex justify-center">
                    <img src={logo} alt="" className="w-32" />
                </div>
                <hr className="my-0 !border-[#D4D4D4]" />
                <div className="flex flex-col items-center justify-center gap-2">
                    <img src={successLogo} alt="logo" className="w-[100px]" />
                    <h4 className="font-medium text-xl leading-8 text-center text-[#171717]">
                        Your payment was successful!
                    </h4>
                </div>
                <div className="flex justify-center">
                    <Link to="/" className="no-underline hover:no-underline hover:text-white text-base text-white bg-am-primary font-medium tracking-wide px-4 py-2 rounded">Back to Dashboard</Link>
                    {/* <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
                        Not automatically redirected? Click this
                        <a
                            href="/"
                            className=" inline-block font-bold !text-[#237804] ml-1 underline"
                        >
                            Login
                        </a>
                        to redirected back.
                    </p> */}
                </div>
            </div>
        </div>
    );
}

export default SubscriptionSuccess;