import { Marketplace, PlaceholderType } from "./CreateAds.types";

export const Placeholders = [
    {
        name: PlaceholderType.CAMPAIGN_STRUCTURE,
        value: PlaceholderType.CAMPAIGN_STRUCTURE,
    },
    {
        name: PlaceholderType.TARGETING_TYPE,
        value: PlaceholderType.TARGETING_TYPE,
    },
    {
        name: PlaceholderType.MATCH_TYPE,
        value: PlaceholderType.MATCH_TYPE,
    },
    {
        name: PlaceholderType.ASIN,
        value: PlaceholderType.ASIN,
    },
    {
        name: PlaceholderType.CUSTOM_TEXT,
        value: PlaceholderType.CUSTOM_TEXT,
    },
];


export const marketplaces: Marketplace[] = [
    {
        region: "europe",
        marketplaces: [
            {
                countryCode: "DE",
                n: 1
            },
            {
                countryCode: "UK",
                n: 2
            },
            {
                countryCode: "FR",
                n: 3
            },
            {
                countryCode: "IT",
                n: 4
            },
            {
                countryCode: "ES",
                n: 5
            },
            {
                countryCode: "PL",
                n: 6
            },
            {
                countryCode: "NL",
                n: 7
            },
            {
                countryCode: "Se",
                n: 8
            }
        ]
    },
    {
        region: "north america",
        marketplaces: [
            {
                countryCode: "US",
                n: 9
            },
            {
                countryCode: "CA",
                n: 10
            },
            {
                countryCode: "MX",
                n: 11
            }
        ]
    },
    {
        region: "south america",
        marketplaces: [
            {
                countryCode: "BR",
                n: 12
            }
        ]
    },
    {
        region: "middle east",
        marketplaces: [
            {
                countryCode: "AE",
                n: 13
            },
            {
                countryCode: "SA",
                n: 14
            }
        ]
    },
    {
        region: "asia pacific",
        marketplaces: [
            {
                countryCode: "AU",
                n: 15
            },
            {
                countryCode: "JP",
                n: 16
            },
            {
                countryCode: "SG",
                n: 17
            }
        ]
    }
]