import { FC } from "react";
import { GoCheckCircleFill } from "react-icons/go";
import { IoIosAddCircleOutline } from "react-icons/io";
import cn from "utils/cn";

interface Props {
  selected: boolean;
}

const CategoryRow: FC<Props> = ({ selected }) => {
  return (
    <div className="grid grid-cols-4">
      <div className=" col-span-2 text-[#111827] font-normal leading-6 text-[16px]">
        Coloring Books for Grown-Ups
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">US</div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-center">
        <button type="button" className={cn(selected && "text-[#237804]")}>
          {selected ? (
            <GoCheckCircleFill className="h-5 w-5" />
          ) : (
            <IoIosAddCircleOutline className="h-5 w-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default CategoryRow;
