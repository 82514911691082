import {
  Box,
  ClickAwayListener,
  Fade,
  InputBase,
  Modal,
  Popper,
  Radio,
} from "@mui/material";
import React from "react";
import cn from "utils/cn";
import { FaPlus } from "react-icons/fa6";
import styled from "@emotion/styled";
import { CiSearch } from "react-icons/ci";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import { status2Data } from "./data";
import SubmitButton from "components/submitButton/SubmitButton";

const StyledPopperContent = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 250,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
  padding: 0,
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& input": {
    borderRadius: 4,
    backgroundColor: "#fff",
    border: `1px solid #D1D5DB`,
    padding: 8,
    paddingRight: 35,
    fontSize: 14,
    "&:focus": {
      borderColor: "#9CA3AF",
    },
  },
}));

const AddAndModeTargetRowInputsManualOptions = () => {
  const [optionOpen, setOptionOpen] = React.useState(false);
  const [createCampaignOpen, setCreateCampaignOpen] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const parentRef = React.useRef<null | HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOptionOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = optionOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOptionOpen(false);
      }}
    >
      <div className="w-full " ref={parentRef}>
        <button
          className={cn(
            "w-full flex items-center justify-between rounded-[4px] border-[1px] py-2 px-3 border-[#D1D5DB] text-sm capitalize leading-5",
            optionOpen &&
              "border-[#237804] bg-[#EAFFEC] text-[#237804] !font-bold",
          )}
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          <span></span>
          <span
            className={cn(optionOpen ? "text-[#237804]" : "text-[#6B7280]")}
          >
            {optionOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </span>
        </button>
        <Popper
          id={id}
          open={optionOpen}
          anchorEl={anchorEl}
          transition
          sx={{
            width: parentRef?.current?.offsetWidth,
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  boxShadow:
                    "1px 6px 15px -3px rgba(0, 0, 0, 0.10), 0px -4px 6px -2px rgba(0, 0, 0, 0.05)",
                  paddingX: "8px",
                  bgcolor: "background.paper",
                  overflow: "hidden",
                }}
              >
                {/* search option */}
                <div className="w-full relative py-3">
                  <StyledInput placeholder="Search campaign..." />
                  <span className="flex items-center justify-center absolute right-5 top-0 h-full ">
                    <CiSearch className="w-6 h-6" />
                  </span>
                </div>
                {/* option list */}
                <StyledPopperContent>
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                  <Option />
                </StyledPopperContent>
                {/* create new campaign button */}
                <button
                  className="flex gap-8 py-4 w-full items-center justify-center text-[#237804] !font-bold border-t border-[#F3F4F6]"
                  onClick={() => {
                    setCreateCampaignOpen(true);
                  }}
                >
                  <FaPlus /> Create New Campaign
                </button>
              </Box>
            </Fade>
          )}
        </Popper>
        {/* create new Campaign modal */}
        <CreateEditModal
          open={createCampaignOpen}
          setOpen={setCreateCampaignOpen}
        />
      </div>
    </ClickAwayListener>
  );
};

const Option = () => {
  return (
    <div className="text-[#4B5563] leading-5 !font-medium text-sm flex justify-between items-center gap-3 py-3 border-t">
      <p className="text-ellipsis line-clamp-1 grow">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates
        temporibus iste possimus fugiat quis eius voluptate molestiae non
        exercitationem neque porro corporis magnam, eligendi sit similique
        corrupti adipisci officiis necessitatibus?
      </p>
      <button className="inline-flex">
        <MdOutlineModeEditOutline className="w-5" />
      </button>
    </div>
  );
};

const CreateEditModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="w-full md:w-[650px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg">
        {/* header */}
        <div className="w-full flex items-center justify-between px-5 py-3 border-b">
          <h3 className="text-[#111827] !font-bold text-xl leading-8">
            Create New Campaign
          </h3>
          <button
            type="button"
            onClick={() => {
              setOpen(false);
            }}
          >
            <IoMdClose className="h-5 w-5" />
          </button>
        </div>
        {/* body */}
        <div className="flex flex-col p-3 gap-4">
          {/* name input */}
          <div className="flex flex-col gap-2">
            <label
              htmlFor="campaig_name"
              className="leading-6 text-[#111827] !font-bold text-[16px]"
            >
              Campaign Name
            </label>
            <input
              id="campaig_name"
              className="w-full outline-none border-[1px] rounded-[4px] py-2 px-2.5 focus:border-[#237804] hover:border-[#237804] transition-all duration-300"
              placeholder="Enter a new campaign name.."
            />
          </div>
          {/* campaign settings */}
          <div className="flex flex-col gap-2">
            <label className="leading-6 text-[#111827] !font-bold text-[16px]">
              Campaign Setting
            </label>
            <div className="flex flex-row flex-wrap items-center gap-5">
              {/* setting radio option */}
              <div className="grow flex items-center justify-between">
                <div className="flex items-center justify-start">
                  <Radio
                    id="and"
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#00976D",
                      },
                    }}
                  />
                  <label htmlFor="and">Default</label>
                </div>
                <div className="flex items-center justify-start">
                  <Radio
                    id="and"
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#00976D",
                      },
                    }}
                  />
                  <label htmlFor="and">use current campaign setting</label>
                </div>
              </div>
              {/* setting dropdown option */}
              <div className="inline-flex">
                <CustomSelectOption
                  placeholder="Select Campaign"
                  options={status2Data}
                  field={{}}
                  className="col-span-2"
                  sx={{
                    "& .MuiSelect-select.MuiSelect-outlined": {
                      padding: "8px 12px !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          {/* message */}
          <p className="!m-0 !font-normal leading-5 text-xs text-[#374151]">
            You able to edit the campaign setting later
          </p>
        </div>
        {/* action button */}
        <div className="w-full flex items-center justify-end px-5 py-3 border-b">
          <div className="flex items-center justify-end gap-5">
            <button
              type="button"
              className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
            >
              back
            </button>
            <div>
              <SubmitButton
                buttonText="Next"
                buttonType="submit"
                className="px-8"
                loadingClassName="px-8"
                disabledClassName="px-8"
                isDisabled={false}
                isLoading={false}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAndModeTargetRowInputsManualOptions;
