import {
  setAutomateRuleAddActiveStep,
  // setAutomateRuleAddStepComplete,
} from "features/automateRuleAdd/automateRuleAddStateSlice";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  NeighborRelationType,
  RunningType,
  TimelineFormState,
  UntilRowType,
} from "./AddNewAutomationRuleTimeline.types";
import SubmitButton from "components/submitButton/SubmitButton";
import { MdAddCircleOutline } from "react-icons/md";
import { Radio } from "@mui/material";
import Datepicker from "./Datepicker";
import UnitRow from "./UntilRow";

const AddNewAutomationRuleTimeline = () => {
  /**-React Router-**/
  const navigate = useNavigate();

  /**-- Redux hook --*/
  const dispatch = useDispatch();

  /**-- Hook Form States */
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { isValid },
  } = useForm<TimelineFormState>({
    mode: "onChange",
    defaultValues: {
      untils: [
        {
          metrics: undefined,
          operator: undefined,
          sumValue: undefined,
          value: {},
          value1: {},
          value2: {},
          exchangeOption: false,
          dynamicCondition: {},
          relationToBottomRow: NeighborRelationType.OR,
        },
      ],
    },
  });

  /**-- useEffect --*/
  // set step complete state status
  useEffect(() => {
    dispatch(setAutomateRuleAddActiveStep(3));
    // dispatch(setAutomateRuleAddStepComplete(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**-- Handlers --*/
  // handle stepform submit
  const handleStepFormSubmit: SubmitHandler<TimelineFormState> = async (
    data
  ) => {
    console.log(data);
  };

  // handleAddConditionRow
  const handleAddUntilRow = () => {
    const currentArray = getValues("untils");

    const updatedArray = [
      ...currentArray,
      {
        metrics: undefined,
        operator: undefined,
        sumValue: undefined,
        value: {},
        value1: {},
        value2: {},
        exchangeOption: false,
        dynamicCondition: {},
        relationToBottomRow: NeighborRelationType.OR,
      },
    ];
    setValue("untils", updatedArray as UntilRowType[]);
  };

  // remove target row
  const handleRemoveUntilRow = (index: number) => {
    const currentArray = getValues("untils");
    const updatedArray = currentArray.filter((_, i) => i !== index);
    setValue("untils", updatedArray);
  };

  // copy target row
  const handleCopyRow = (index: number, row: UntilRowType) => {
    const currentArray = getValues("untils");
    const updatedArray = [
      ...currentArray.slice(0, index),
      row,
      ...currentArray.slice(index),
    ];
    setValue("untils", updatedArray);
  };

  console.log("data ", watch("runningDates"));
  return (
    <form
      className="w-full flex flex-col gap-6"
      onSubmit={handleSubmit(handleStepFormSubmit)}
    >
      {/* until rows */}
      <div className="w-full flex flex-col gap-2">
        <h4 className="capitalize text-[#111827] text-[16px] !font-bold leading-6">
          Until
        </h4>

        {/* action rows */}
        <div className="flex flex-col">
          {/* <div className="flex flex-col w-full"></div> */}
          {watch(`untils`).map((row, idx) => (
            <UnitRow
              key={idx}
              control={control}
              currentIndex={idx}
              getValues={getValues}
              handleCopyRow={handleCopyRow}
              handleDeleteRow={handleRemoveUntilRow}
              totalLength={watch("untils").length}
              relationToBottomNeighbor={row?.relationToBottomRow}
              relationToTopNeighbor={
                idx === 0
                  ? NeighborRelationType.NONE
                  : watch("untils")[idx - 1].relationToBottomRow
              }
              setValue={setValue}
            />
          ))}
          {/* add row action */}
          <div className="flex justify-start items-center mt-3">
            <button
              type="button"
              className="flex items-center justify-center gap-1 text-[#9CA3AF] hover:text-[#237804] text-sm !font-medium leading-5 transition-all duration-200"
              onClick={handleAddUntilRow}
            >
              <span>
                <MdAddCircleOutline className="h-5 w-5" />
              </span>
              <span>Add Condition</span>
            </button>
          </div>
        </div>
      </div>

      {/* rule running date */}
      <div className="w-full flex flex-col gap-2">
        <h4 className="capitalize text-[#111827] text-[16px] !font-bold leading-6">
          rule running date
        </h4>
        <div className="flex flex-col xl:flex-row w-full gap-4">
          {/* running type */}
          <div className="grow flex justify-between items-center">
            <Controller
              name="runningDates.runningType"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex items-center justify-start">
                    <Radio
                      {...field}
                      id="continuosly"
                      value={RunningType.RUN_CONTINUOUSLY}
                      checked={field.value === RunningType.RUN_CONTINUOUSLY}
                      sx={{
                        color: "#6B7280",
                        padding: "0px",
                        margin: "0px",
                        marginRight: "5px",
                        "&.Mui-checked": {
                          color: "#00976D",
                        },
                      }}
                    />
                    <label htmlFor="continuosly">Run continuously</label>
                  </div>
                  <div className="flex items-center justify-start">
                    <Radio
                      {...field}
                      id="dateRange"
                      value={RunningType.DATE_RANGE}
                      checked={field.value === RunningType.DATE_RANGE}
                      sx={{
                        color: "#6B7280",
                        padding: "0px",
                        margin: "0px",
                        marginRight: "5px",
                        "&.Mui-checked": {
                          color: "#00976D",
                        },
                      }}
                    />
                    <label htmlFor="dateRange">Date range</label>
                  </div>
                </>
              )}
            />
          </div>
          {/* select options */}
          <div className="flex flex-wrap gap-4">
            <div className="inline-flex w-full lg:w-[250px] xl:w-[250px] 2xl:w-[400px]">
              <Controller
                name="runningDates.dateRange.from"
                control={control}
                render={({ field }) => (
                  <Datepicker field={field} watch={watch} />
                )}
              />
            </div>
            <div className="inline-flex w-full lg:w-[250px] xl:w-[250px] 2xl:w-[400px]">
              <Controller
                name="runningDates.dateRange.to"
                control={control}
                render={({ field }) => (
                  <Datepicker field={field} watch={watch} />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {/* form action button */}
      <div className="flex items-center justify-end gap-5">
        <button
          type="button"
          className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
          onClick={() => {
            navigate("/automation-rule/add/then");
          }}
        >
          back
        </button>
        <div>
          <SubmitButton
            buttonText="Next"
            buttonType="submit"
            className="px-8"
            loadingClassName="px-8"
            disabledClassName="px-8"
            isDisabled={!isValid}
            isLoading={false}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNewAutomationRuleTimeline;
