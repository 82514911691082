import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function MarketSelectData(props) {
  const { option, handleSelect, selectAllHandler } = props;
  const [isopen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const dropdownHandler = (event) => {
    event.stopPropagation();
    setIsOpen(!isopen);
  };

  const marketPlaceData = option?.map((item, index) => {
    return (
      <div>
        <ul className="p-0 m-1" key={index}>
          <div className="d-flex align-items-self-start p-0">
            <input
              type="checkbox"
              id={item.value}
              checked={item?.isChecked}
              className="mx-2 form-check-input"
              onChange={() => handleSelect(item.value)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <li className="titleList-li">{item.value}</li>
          </div>
        </ul>
        <div className="border-bottom"></div>
      </div>
    );
  });
  const selectedTags = option
    .filter((item) => item.isChecked)
    .map((item, index) => (
      <span key={index} className="marketTag">
        <div>
          {item.label}
          <span className="removeIcon" onClick={() => handleSelect(item.value)}>
            x
          </span>
        </div>
      </span>
    ));

  console.log(option);
  return (
    <div>
      <div className={`${isopen ? "marketplace-hover" : "marketplace-select"}`}>
        <div className="my-2 mx-2 marketplaceTags" onClick={dropdownHandler}>
          {selectedTags?.length > 0 ? (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div>{selectedTags}</div>
                <div className="align-self-end">
                  <ExpandMoreIcon
                    onClick={dropdownHandler}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex justify-content-between"
                onClick={dropdownHandler}
                style={{ cursor: "pointer" }}
              >
                <div className="titleList-li">All MarketPlace</div>
                <div>
                  |
                  <ExpandMoreIcon onClick={dropdownHandler} />
                </div>
              </div>
            </>
          )}
        </div>
        {isopen ? (
          <div className="marketplace-dropdown" ref={dropdownRef}>
            <div className="d-flex align-items-self-start p-0 ms-1 mt-1 selectallborder">
              <input
                type="checkbox"
                className="mx-2 form-check-input"
                checked={option.every((item) => item.isChecked)}
                onChange={(e) => selectAllHandler(e)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <span className="titleList-li">Select All</span>
            </div>
            <div className="border-bottom"></div>
            {marketPlaceData}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MarketSelectData;
