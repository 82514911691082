import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoSyncSharp } from "react-icons/io5";
import cn from "utils/cn";
import DataTable from "./dataTable/DataTable";

const AdsStatus = () => {
  return (
    <div className="w-full flex flex-col gap-2">
      {/* header */}
      <div className="flex w-full justify-between items-center flex-wrap">
        {/* left heading box */}
        <div className="flex flex-col">
          <h4 className="text-[#6B7280] !m-0 text-xl leading-8 !font-bold">
            Bookshelf
          </h4>
          <div className="flex items-center text-[#6B7280] gap-1">
            <span className="text-sm font-medium leading-5">
              Last App Sync Wed, Nov 2, 10:57
            </span>
            <button type="button" className="!font-bold text-[#6B7280]">
              <IoSyncSharp className="h-5 w-5" />
            </button>
          </div>
        </div>
        {/* timeline box */}
        <div className="flex gap-6">
          {/* timeline button */}
          <button className="text-[#6B7280] font-medium leading-5 text-sm border-b py-2">
            Yesterday
          </button>
          <button className="text-[#6B7280] font-medium leading-5 text-sm border-b py-2">
            Today
          </button>
          <button
            className={cn(
              "text-[#6B7280] font-medium leading-5 text-sm border-b py-2",
              "border-b-2 border-[#237804] text-[#237804] font-bold"
            )}
          >
            Last 7 Days
          </button>
          <button className="text-[#6B7280] font-medium leading-5 text-sm border-b py-2">
            Last 30 Days
          </button>
          <button className="text-[#6B7280] font-medium leading-5 text-sm border-b py-2 flex items-center gap-[4px] border-transparent">
            <span>Custom</span>
            <span>
              <IoIosArrowDown />
            </span>
          </button>
        </div>
      </div>
      {/* data table */}
      <DataTable />
    </div>
  );
};

export default AdsStatus;
