import styled from "@emotion/styled";
import KeywordRow from "./KeywordRow";
import SelectedKeywordRow from "./SelectedKeywordRow";

const ContentBoxContainer = styled("div")(() => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
}));

const KeywordTargetingInputs = () => {
  return (
    <div className="w-full flex flex-col gap-2">
      {/* header */}
      <div className="flex flex-row items-center leading-6 gap-2">
        <span className="text-[#111827] font-bold text-[16px]">
          Keyword Targeting
        </span>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
        {/* left box */}
        <div className="flex flex-col gap-2 h-full ">
          {/* box header */}
          <div className="w-full flex flex-col gap-2">
            {/* type input */}
            <div className="flex gap-x-6 items-center flex-wrap">
              {/* type selection */}
              <div className="w-full flex flex-col gap-2">
                <h4 className="text-[#111827] leading-6 font-bold text-[16px] !m-0">
                  Types
                </h4>
                <div className="flex gap-4">
                  <div className="flex items-center justify-start gap-2">
                    <input
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label className="!text-[#111827] text-[16px] !font-normal leading-6">
                      Broad
                    </label>
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <input
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label className="!text-[#111827] text-[16px] !font-normal leading-6">
                      Phrase
                    </label>
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <input
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label className="!text-[#111827] text-[16px] !font-normal leading-6">
                      Exact
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* keywords inputs button */}
          <div className="w-full flex flex-col gap-4">
            <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[500px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
              {/* row header */}
              <div className="grid grid-cols-3">
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Keyword
                </div>

                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Match Type
                </div>
                <div className="font-medium leading-6 text-[16px] text-center text-[#237804]">
                  Add All
                </div>
              </div>
              <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
                <KeywordRow />
                <KeywordRow />
                <KeywordRow />
                <KeywordRow />
                <KeywordRow />
                <KeywordRow />
                <KeywordRow />
              </ContentBoxContainer>
            </div>
          </div>
        </div>
        {/* right blox */}
        <div className="flex flex-col gap-2 h-full items-end justify-end">
          <div className="w-full flex justify-between">
            <p className="text-[#6B7280] text-[16px] !font-medium !m-0 leading-6">
              0/1000 keyword added
            </p>
            <button
              type="button"
              className="text-[#D1D5DB] text-[16px] !font-medium leading-6"
            >
              Remove all
            </button>
          </div>
          {/* keywords inputs button */}
          <div className="w-full flex flex-col">
            <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[500px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
              {/* row header */}
              <div className="grid grid-cols-3">
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Keyword
                </div>
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Match Type
                </div>
              </div>
              <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
                <SelectedKeywordRow />
              </ContentBoxContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordTargetingInputs;
