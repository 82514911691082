import { Marketplace } from "./AddNewAutomationRuleApplyTo.types";

export const marketplaces: Marketplace[] = [
    {
        region: "europe",
        marketplaces: [
            {
                countryCode: "DE",
                n: 1
            },
            {
                countryCode: "UK",
                n: 2
            },
            {
                countryCode: "FR",
                n: 3
            },
            {
                countryCode: "IT",
                n: 4
            },
            {
                countryCode: "ES",
                n: 5
            },
            {
                countryCode: "PL",
                n: 6
            },
            {
                countryCode: "NL",
                n: 7
            },
            {
                countryCode: "Se",
                n: 8
            }
        ]
    },
    {
        region: "north america",
        marketplaces: [
            {
                countryCode: "US",
                n: 9
            },
            {
                countryCode: "CA",
                n: 10
            },
            {
                countryCode: "MX",
                n: 11
            }
        ]
    },
    {
        region: "south america",
        marketplaces: [
            {
                countryCode: "BR",
                n: 12
            }
        ]
    },
    {
        region: "middle east",
        marketplaces: [
            {
                countryCode: "AE",
                n: 13
            },
            {
                countryCode: "SA",
                n: 14
            }
        ]
    },
    {
        region: "asia pacific",
        marketplaces: [
            {
                countryCode: "AU",
                n: 15
            },
            {
                countryCode: "JP",
                n: 16
            },
            {
                countryCode: "SG",
                n: 17
            }
        ]
    }
]

export const levelOptions = [
    {
        name: "target",
        value: "target"
    },
    {
        name: "ad group",
        value: "ad group"
    },
    {
        name: "campaign",
        value: "campaign"
    },
    {
        name: "protfolio",
        value: "protfolio"
    }
];
export const conditionOptions = [
    {
        name: "is Equal To",
        value: "is Equal To"
    },
    {
        name: "is not Equal To",
        value: "is not Equal To"
    },
    {
        name: "Contains",
        value: "Contains"
    },
    {
        name: "Does not Contain",
        value: "Does not Contain"
    }
];

export const autoCampaign = ["close", "loose", "substitutes", "complements"];
export const manualCampaignKeywordTargeting = ["broad", "phrase", "exact"];
export const manualCampaignProductTargeting = ["product", "category"]