import deleteIcon from "assets/icons/delete_icon.svg";

const SelectedKeywordRow = () => {
  return (
    <div className="grid grid-cols-3">
      <div className="text-[#111827] font-normal leading-6 text-[16px]">
        Keyword 1
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">
        Broad
      </div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-end">
        <button type="button">
          <img src={deleteIcon} className="w-[25px] " alt="delete" />
        </button>
      </div>
    </div>
  );
};

export default SelectedKeywordRow;
