import logo from "assets/brand/logo.svg";

import { useForm, SubmitHandler } from "react-hook-form";

import ErrorLabel from "components/errorLabel/ErrorLabel";

import cn from "utils/cn";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useForgotPasswordMutation } from "features/auth/authSlice";
import SubmitButton from "components/submitButton/SubmitButton";
import { useState } from "react";
import InfoBox from "components/infoBox/InfoBox";
import { useNavigate } from "react-router-dom";

interface FormInputs {
  email: string;
}

const ForgotPassword = () => {
  // NAVIGATe: - react-router-dom utils
  const navigate = useNavigate();

  // RTK: - mutation
  const [forgotPassword, { isError, isLoading }] = useForgotPasswordMutation();

  // STATE: - Hook-Form state
  const {
    register,
    handleSubmit,

    formState: { errors, isDirty, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  // STATE: - error track state
  const [forgotPasswordRequestStatus, setForgotPasswordRequestStatus] =
    useState(false);

  // Determine: - if the button should be disabled based on form state
  const isFormValid = !isValid || !isDirty;

  // Handle: - send email
  const handleSendEmail: SubmitHandler<FormInputs> = async (data) => {
    // decorate req body
    const body = {
      email: data?.email,
      passwordResetUrl: `${process.env.REACT_APP_URL}/set-new-password/?token=`,
    };
    try {
      // REQUEST: - send request for forgot password to the backend
      const req = await forgotPassword(body);
      if (req?.data?.success) {
        // SUCCESS: - got to the request success route and faild flase
        navigate("/forgot-password-email-sent", {
          state: {
            ...body,
          },
        });

        setForgotPasswordRequestStatus(false);
      } else {
        // FAILD: - request faild from backend
        setForgotPasswordRequestStatus(true);
      }
    } catch (error) {
      // FAILD: - request faild from front-end
      setForgotPasswordRequestStatus(true);
    }
  };

  return (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6 relative">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-32" />
        </div>
        <hr className="my-0 !bg-[#D4D4D4] !border-[#D4D4D4]" />
        <h3 className="text-center text-xl !font-medium !text-black leading-[30px] m-0">
          Forgot Password
        </h3>

        {/* forget password form */}
        <form
          className="flex flex-col w-full gap-3"
          onSubmit={handleSubmit(handleSendEmail)}
        >
          {/* email inputs */}
          <div className="flex flex-col">
            <input
              type="email"
              placeholder="Enter email..."
              className={cn(
                "py-2.5 px-3.5 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] leading-6",
                " border-[1px]",
                errors?.email ? "!border-red-500" : "!border-[#C6C6C6]"
              )}
              autoComplete="off"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email",
                },
              })}
            />
            {errors?.email && <ErrorLabel message={errors?.email?.message} />}
          </div>
          {/* info message */}
          {isError ||
            (forgotPasswordRequestStatus && (
              <InfoBox
                type="warning"
                message="Sorry for the trouble, we unable to prosess your request. Please try again."
              />
            ))}
          {/* login button */}
          <div className="flex flex-col w-[340px]">
            <SubmitButton
              buttonText="Send email"
              buttonType="submit"
              isLoading={isLoading}
              isDisabled={isFormValid}
            />
          </div>
        </form>

        {/* signup link */}
        <p className="flex text-xs items-center justify-center font-medium m-0 leading-5 text-[#171717]">
          You’ll receive a password reset instructions on your email
        </p>

        {/* back to login button  */}
        <div className="flex items-center justify-center">
          <a
            href="/login"
            className="no-underline flex items-center justify-center gap-2 !text-[#171717] font-medium text-[16px] leading-6"
          >
            <FaArrowLeftLong /> Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
