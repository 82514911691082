import { CircleFlag } from 'react-circle-flags';

const CountryFlag2 = ({ country, size = 20 }) => {
    return (
        <div className='flex gap-2 items-center'>
            <CircleFlag countryCode={country?.code?.toLowerCase()} height={size} width={size} />
            <span>{country?.name}</span>
        </div>
    );
};

export default CountryFlag2;