import React, { FC, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import cn from "utils/cn";
import CustomSelectOptionWithFilter from "components/CustomSelectOptionWithFilter/CustomSelectOptionWithFilter";
import { metricsData, timePeriodData } from "./data";
import { Control, Controller, UseFormGetValues } from "react-hook-form";
import { IfFormState } from "./AddNewAutomationRuleIf.types";
import { ClickAwayListener } from "@mui/material";

interface Props {
  currentIndex: number;
  getValues: UseFormGetValues<IfFormState>;
  control: Control<IfFormState, any>;
}

const SelectDynamicCondition: FC<Props> = ({
  control,
  currentIndex,
  getValues,
}) => {
  const [optionOpen, setOptionOpen] = useState<boolean>(false);
  return (
    <ClickAwayListener onClickAway={() => setOptionOpen(false)}>
      <div className="w-full relative flex flex-row gap-3 items-center">
        {/* condition for set dynamic value */}
        <span className="text-[#374151] !font-bold leading-8 text-xl">X</span>
        {/* select dynamic dropdown condition open */}
        <div className="relative grow">
          <button
            type="button"
            className={cn(
              "py-2 px-3 border-[1px] w-full rounded-[4px] text-sm leading-5  !font-normal flex justify-between items-center relative",
              optionOpen
                ? "border-[#237804] bg-[#EAFFEC] !font-bold text-[#237804]"
                : "border-[#D1D5DB] text-[#6F6F6F]"
            )}
            onClick={() => setOptionOpen(!optionOpen)}
          >
            Set Dynamic Condition
            {optionOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </button>
          {/* dropdown block section */}

          <div
            className={cn(
              "absolute top-[100%] right-0 w-[570px] shadow-md py-2 px-3 bg-white mt-2 border-[1px] rounded-[4px] z-50",
              optionOpen ? "flex flex-col gap-3" : "hidden"
            )}
          >
            {/* select options block */}
            <div className="w-full grid grid-cols-8 gap-3">
              {/* select metrics */}
              <div className="col-span-3">
                <Controller
                  name={`conditions.${currentIndex}.dynamicCondition.metrics`}
                  control={control}
                  render={({ field }) => (
                    <CustomSelectOptionWithFilter
                      placeholder={"Select Metrics"}
                      searchPlaceholder="Search metrics..."
                      options={metricsData}
                      field={field}
                    />
                  )}
                />
              </div>
              {/* select period */}
              <div className="col-span-3">
                <Controller
                  name={`conditions.${currentIndex}.dynamicCondition.period`}
                  control={control}
                  rules={{
                    required: "Time period is required",
                  }}
                  render={({ field }) => (
                    <CustomSelectOptionWithFilter
                      placeholder={"Select Metrics"}
                      searchPlaceholder="Search metrics..."
                      options={timePeriodData}
                      field={field}
                      hasCustom={true}
                    />
                  )}
                />
              </div>
              {/* action value */}
              <div className="flex gap-3 items-center">
                {/* options calculated value */}
                <span className="text-[#374151] !font-bold leading-8 text-xl">
                  =
                </span>
                {/* value */}
                <span className="text-[#374151] !font-bold leading-8 text-xl">
                  {getValues(
                    `conditions.${currentIndex}.dynamicCondition.value`
                  )}
                </span>
              </div>
            </div>
            {/* action box */}
            <div className="flex items-center justify-end pt-3 border-t-[1px] gap-4">
              <button
                type="button"
                className="capitalize py-1 px-3 border-[1px] border-[#6B7280] text-[#6B7280] rounded-[4px] !font-bold leading-6 text-sm"
              >
                Clear
              </button>

              <button
                type="button"
                className={cn(
                  "capitalize py-1.5 px-3  border-[1px] border-[#6B7280] text-white rounded-[4px] !font-bold leading-6 text-sm",

                  getValues(
                    `conditions.${currentIndex}.dynamicCondition.metrics`
                  ) &&
                    getValues(
                      `conditions.${currentIndex}.dynamicCondition.period`
                    )
                    ? "bg-[#237804] border-[#237804]"
                    : "bg-[#D1D5DB] border-[#D1D5DB]"
                )}
              >
                save
              </button>
            </div>
          </div>
        </div>
        {/* options calculated value */}
        <span className="text-[#374151] !font-bold leading-8 text-xl">=</span>
        {/* value */}
        <span className="text-[#374151] !font-bold leading-8 text-xl">
          {getValues(`conditions.${currentIndex}.dynamicCondition.value`)}
        </span>
      </div>
    </ClickAwayListener>
  );
};

export default SelectDynamicCondition;
