import profileIcon from "assets/icons/prgile.svg";
import billingPlanIcon from "assets/icons/billing_plan.svg";
import nimbusLinkIcon from "assets/icons/nimbus_link.svg";
import cn from "utils/cn";
import { Link, NavLink, Outlet } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

interface TabButtonProps {
  children?: React.ReactNode;
  className?: string;
  path: string;
  to?: string;
}

export function PageButton(props: TabButtonProps) {
  const { children, className, path } = props;
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        cn(
          "w-full flex items-center py-3 px-3 gap-3 font-medium text-sm leading-5 !text-am-aiGray rounded-lg !no-underline hover:!no-underline hover:text-black",
          className,
          isActive
            ? "bg-am-primary-light/5 border-r-2 border-am-primary"
            : "bg-transparent"
        )
      }
    >
      {children}
    </NavLink>
  );
}

const SettingsLayout = () => {
  // tabs contents
  const tabContents = [
    {
      title: "My Profile",
      icon: profileIcon,
      path: "/account/profile",
    },
    {
      title: "Billing & Plan",
      icon: billingPlanIcon,
      path: "/account/billing-plan",
    },
    {
      title: "Amazon Connection",
      icon: nimbusLinkIcon,
      path: "/account/amazon-connect",
    },
  ];

  return (
    <section className="grow flex flex-col">
      {/* back button */}
      <Link
        to={"/"}
        className="bg-white py-2 px-3 w-full text-left flex flex-row items-center !font-semibold leading-5 text-sm gap-2 !text-[#737373] hover:!no-underline"
      >
        <IoIosArrowBack className="w-5 h-5" />
        Back
      </Link>
      <div className="flex-grow grid grid-cols-1 lg:grid-cols-8 xl:grid-cols-11 2xl:grid-cols-5 mt-6 gap-8">
        {/* tab options */}
        <div className="col-span-1 lg:col-span-3 xl:col-span-3 2xl:col-span-1 flex flex-col ">
          <div className="w-full bg-white px-4 py-4 rounded-xl">
            {tabContents.map((content, idx) => (
              <PageButton
                key={idx}
                className="flex w-full items-center"
                path={content.path}
              >
                <img src={content?.icon} className="w-[25px]" alt="" />
                <span>{content?.title}</span>
              </PageButton>
            ))}
          </div>
        </div>
        {/* tab content */}
        <div className="col-span-1 lg:col-span-5 xl:col-span-8 2xl:col-span-4 flex-grow h-full px-8 py-6 bg-white rounded-xl">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default SettingsLayout;
