import { apiSlice } from "features/api/apiSlice";

export const onboardingSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        requestAmazonAutorizationURL: builder.mutation({
            query: (region) => ({
                url: `/AmazonAds/AuthorizationURL?Region=${region}`,
                method: 'GET'
            })
        }),
        requestAmazonAdsMerchant: builder.mutation({
            query: (data) => ({
                url: `/AmazonAds/Callback`,
                method: "POST",
                body: data
            })
        }),
        updateUserProfilePreferences: builder.mutation({
            query: (data) => ({
                url: `/Users/Onboarding/ProfilePreferences`,
                method: 'PUT',
                body: data
            })
        }),

    })
});


export const {
    useRequestAmazonAutorizationURLMutation,
    useRequestAmazonAdsMerchantMutation,
    useUpdateUserProfilePreferencesMutation,

} = onboardingSlice;