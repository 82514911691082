// faq.tsx
import React, { Component, ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import DashboardLayout from "../../layouts/XDashboardLayout";
import FaqSideTab from "./SideTab/faqSideTab";
import "./faq.css";
import ProductServiceTitleIcon from "../../assets/images/icons/product-service-title-icon.svg";
import GetStartedTitleIcon from "../../assets/images/icons/get-started-title-icon.svg";
import SubscriptionPaymentTitleIcon from "../../assets/images/icons/subscription-payment-title-icon.svg";
import DataSecurityTitleIcon from "../../assets/images/icons/data-security-title-icon.svg";
import TroubleshootingTitleIcon from "../../assets/images/icons/troubleshooting-title-icon.svg";

interface FaqItem {
  id: string;
  question: string;
  message: string;
  category: string;
}

interface FaqProps {
  setFaqData: (data: any, category: string, totalPages: number, currentPage: number) => void;
}

interface FaqState {
  lastUpdatedDate: string | null;
  faqData: FaqItem[];
  selectedCategory: string | null;
  initialFetchComplete: boolean;
  searchFormActive: boolean;
  currentPage: number;
  totalPages: number;
}

class Faq extends Component<FaqProps, FaqState> {
  state: FaqState = {
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
    faqData: [],
    selectedCategory: null,
    initialFetchComplete: false,
    searchFormActive: false,
    currentPage: 1,
    totalPages: 1,
  };

  componentDidMount() {
    this.fetchFaqData();
  }

  fetchFaqData = async (searchText?: string, page: number = 1) => {
    const pageSize = 10;
    let apiUrl = `https://api.aimosa.io/FAQ?pageNumber=${page}&pageSize=${pageSize}`;

    if (searchText) {
      apiUrl += `&Filters.searchText=${searchText}`;
    } else if (this.state.selectedCategory !== null) {
      apiUrl += `&Filters.Category=${encodeURIComponent(this.state.selectedCategory)}`;
    }

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      const faqData = data.result.data || [];
      this.setState({
        faqData: faqData,
        initialFetchComplete: true,
        currentPage: data.result.currPage || 1,
        totalPages: data.result.lastPage || 1,
      });
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  setFaqData = (data: FaqItem[], category: string, totalPages: number, currentPage: number) => {
    this.setState({
      faqData: data,
      selectedCategory: category,
      searchFormActive: false,
      totalPages: totalPages,
      currentPage: currentPage,
    });
  };

  handleSearch = (searchText: string) => {
    this.setState({ searchFormActive: true, currentPage: 1 });
    this.fetchFaqData(searchText);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    const { searchFormActive } = this.state;
    const searchText = searchFormActive ? (document.getElementById('globalSearch') as HTMLInputElement)?.value : undefined;

    this.setState({ currentPage: page }, () => {
      this.fetchFaqData(searchText, page);
    });
  };

  renderHeader = () => (
    <div className="main-cont-header bookself-container">
      <Row className="page-header">
        <Col>
          <div className="main-con-page-title-container">
            <div className="title">
              <h3 className="page-title">FAQ</h3>
            </div>
          </div>
        </Col>
        {/* <Col className="text-end last-sync justify-content-end">
          <span
              className="last-sync"
          >
            <div>Last App Sync</div>
            <div className="lastspan">
                {" "}
                {this.state.lastUpdatedDate || "Not available"}
            </div>
          </span>
        </Col> */}
      </Row>
    </div>
  );

  renderSearchForm = () => (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="search-filter-container">
        <i className="fa fa-search"></i>
        <input
          type="text"
          placeholder="Search a question..."
          id="globalSearch"
          name="globalSearch"
          className="faq-header-input"
          onChange={(e) => this.handleSearch(e.target.value)}
        />
      </div>
    </form>
  );

  renderFaqHeader = () => (
    <div className="faq-header-bg">
      <Row className="justify-content-center">
        <Col md={5}>
          <div className="faq-header-title">Hello, how can we help?</div>
          <div>{this.renderSearchForm()}</div>
          <p>or select a category to quickly find the help you require.</p>
        </Col>
      </Row>
    </div>
  );

  renderFaqQaHeader = () => {
    const { selectedCategory, searchFormActive } = this.state;

    const categoryIcons = {
      "Products & Services": ProductServiceTitleIcon,
      "Get Started": GetStartedTitleIcon,
      "Subscription & Payment": SubscriptionPaymentTitleIcon,
      "Data Security": DataSecurityTitleIcon,
      "Troubleshooting": TroubleshootingTitleIcon,
    };
    const icon = selectedCategory !== null ? categoryIcons[selectedCategory] : undefined;

    return selectedCategory !== null && !searchFormActive ? (
      <div className="faq-qa-header-container">
        <>
          <div className="faq-qa-header">
            <div className="faq-qa-header-image">
              <img src={icon} alt={`Category: ${selectedCategory}`} />
            </div>
          </div>
          <div className="faq-qa-header">
            <h2>{selectedCategory}</h2>
            <p>Get help with {selectedCategory}</p>
          </div>
        </>
      </div>
    ) : null;
  };

  renderFaqQaCard = () => {
    const { faqData, currentPage, totalPages } = this.state;

    return (
      <div className="row faq-qa-card-container">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {faqData.map((item) => (
            <div className="accordion-item" key={item.id}>
              <h2 className="accordion-header" id={`flush-heading-${item.id}`}>
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse-${item.id}`}
                  aria-expanded="true"
                  aria-controls={`flush-collapse-${item.id}`}
                >
                  {item.question}
                </button>
              </h2>
              <div
                id={`flush-collapse-${item.id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`flush-heading-${item.id}`}
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">{item.message}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="custom-table-footer">
          <Row>
            <Col md={5}>{/* Left side of the table footer (empty in your code) */}</Col>
            <Col md={7}>
              {/* Right side of the table footer */}
              <div className="table-footer-right">
                <Stack spacing={2}>
                  {/* Pagination Component */}
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    shape="rounded"
                    onChange={this.handlePageChange}
                  />
                </Stack>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render(): ReactElement {
    const { initialFetchComplete, lastUpdatedDate, selectedCategory, searchFormActive } = this.state;

    return (
      <DashboardLayout>
        {this.renderHeader()}
        <div className="main-content-container faq-container">
          <hr />
          <div className="container-fluid">
            {initialFetchComplete && this.renderFaqHeader()}
          </div>
          <div className="container-fluid mt-5 padding-lr-30">
            <Row>
              <Col md={3}>
                <FaqSideTab setFaqData={this.setFaqData} />
              </Col>
              <Col md={9}>
                <div className="container faq-qa-card">
                  <Col md={12}>
                    {initialFetchComplete && this.renderFaqQaHeader()}
                    {this.renderFaqQaCard()}
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

export default Faq;
