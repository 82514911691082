import logo from "../../assets/brand/logo.svg";
import emailLogo from "../../assets/icons/email_custom.svg";
import { useLocation, useNavigate } from "react-router-dom";

import { FaArrowLeftLong } from "react-icons/fa6";
import cn from "utils/cn";
import InfoBox from "components/infoBox/InfoBox";
import { useForgotPasswordMutation } from "features/auth/authSlice";
import { useEffect, useState } from "react";

const ForgotPasswordEmailSent = () => {
  // navigation: - react-router-dom utils
  const navigate = useNavigate();

  // location: - react-router-dom utils
  const location = useLocation();

  // RTK: - mutation
  const [forgotPassword] = useForgotPasswordMutation();

  // STATE: - error track state
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stateWraning, setStateWarning] = useState(false);

  // STATE: - Time counter state
  const [countDown, setCountDown] = useState<number>(60);

  useEffect(() => {
    // If the timer reaches zero, do nothing
    if (countDown === 0) return;

    // Update the countdown every second
    const timer = setInterval(() => {
      setCountDown((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        }
        return prevTime;
      });
    }, 1000);

    // Clear the timer when component unmounts or when timeLeft changes
    return () => clearInterval(timer);
  }, [countDown]);

  // navigate to previous page
  const handleBack = () => {
    navigate(-1);
  };

  // get state from previous route
  const state = location.state || null;

  // extract email to show the user
  const email = state?.email;

  // Handle: - send email
  const handleReSendEmail = async () => {
    // decorate req body
    const body = state;

    if (body) {
      try {
        // REQUEST: - send request for forgot password to the backend
        const req = await forgotPassword(body);
        if (req?.data?.success) {
          setCountDown(60);
          setStateWarning(false);
        } else {
          // FAILD: - request faild from backend
          setStateWarning(true);
        }
      } catch (error) {
        // FAILD: - request faild from front-end
        setStateWarning(true);
      }
    } else {
      // FAILD: - if somehow state not found
      setStateWarning(true);
    }
  };

  return state ? (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-32" />
        </div>
        <hr className="my-0 !border-[#D4D4D4]" />
        <div className="flex flex-col items-center justify-center gap-2">
          <img src={emailLogo} alt="logo" className="w-[100px]" />
          <h4 className="font-medium text-xl leading-8 text-center !text-[#171717]">
            Check your email to reset password
          </h4>
        </div>
        <div className="flex max-w-[340px] flex-col gap-4">
          <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
            We already sent password reset instruction to{" "}
            <strong>{email}</strong>. Please follow the instruction to reset
            password.
          </p>

          <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
            Didn't receive the email? Please check spam folder or
            <button
              onClick={handleReSendEmail}
              className={cn(
                "inline-block font-bold  ml-1 underline",
                countDown === 0 ? "!text-[#237804]" : "!text-[#A3A3A3]"
              )}
              disabled={countDown !== 0}
            >
              Resend
            </button>
            .
          </p>

          {/* info */}
          {countDown !== 0 && (
            <InfoBox
              type={"warning"}
              message={`You need to wait 00:${countDown} seconds before resend email`}
            />
          )}
        </div>

        {/* open email action button */}
        <div className="flex flex-col w-[340px]">
          <button
            type="submit"
            className={
              "w-full py-2.5  rounded-[4px] text-[16px] leading-6 font-bold flex items-center justify-center bg-[#237804] text-white"
            }
            onClick={() => {
              // open default mail client
              window.location.href = "mailto:";
            }}
          >
            Open email
          </button>
        </div>

        <div className="flex items-center justify-center">
          <button
            onClick={handleBack}
            type="button"
            className="flex items-center justify-center gap-2 text-[#171717] font-medium text-[16px] leading-6"
          >
            {" "}
            <FaArrowLeftLong /> Change email
          </button>
        </div>
      </div>
    </div>
  ) : (
    <>{navigate(-1)}</>
  );
};

export default ForgotPasswordEmailSent;
