import ReactDOM from "react-dom/client";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/styles/fonts.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import CacheService from "./services/CacheService";
import metaData from "./shared/Cache/metaData.json";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "store/store";

const initializeApp = async () => {
  try {
    await CacheService.updateCache("metaData", metaData.result.data);

    const reactRoot = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    reactRoot.render(
      // <React.StrictMode>
      <Provider store={store}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
          onScriptLoadSuccess={() => console.log("googleAuthSuccefull")}
          onScriptLoadError={() => console.log("googleAuthFailed")}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </Provider>
      // </React.StrictMode>
    );
  } catch (error) {
    console.error("Error initializing the app:", error);
  }
};

initializeApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
