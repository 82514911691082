import AmazonConnectionTable from "./AmazonConnectionTable";
import { useGetAmazonConnectionsQuery } from "features/amazon/amazonSlice";
import LoadingScreen from "components/loadingScreen/LoadingScreen";
import { LinearProgress } from "@mui/material";

const AmazonConnection = () => {
  /**-RTK-**/
  //queries
  const { data: amazonConnectionData, isLoading: amazonConnectionDataLoading } = useGetAmazonConnectionsQuery("")

  return (
    <section className="grow flex flex-col gap-6">
      <h2 className="border-b border-gray-200 !leading-8 !font-bold !text-xl text-blacks m-0 pb-3">Amazon Connections</h2>
      <div className="grow">
        {
          amazonConnectionDataLoading &&
          <div className="h-screen grid place-content-center">
            <div className="grid gap-3">
              <LoadingScreen />
              <LinearProgress color="success" />
            </div>
          </div>
        }
        {
          amazonConnectionData?.result &&
          <AmazonConnectionTable
            connectionData={amazonConnectionData?.result}
          />
        }
      </div>
    </section>
  )
};

export default AmazonConnection;
