import { CreateFormState, TabType } from "pages/createAds/CreateAds.types";
import { FC } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import { BsCurrencyDollar } from "react-icons/bs";
import cn from "utils/cn";

interface Props {
  control: Control<CreateFormState, any>;
  tabType: TabType;
  getValues: UseFormGetValues<CreateFormState>;
  watch: UseFormWatch<CreateFormState>;
}

const MatchTypeInputs: FC<Props> = ({ control, getValues, tabType, watch }) => {
  return (
    <div className="w-full flex flex-col gap-2">
      {/* header */}
      <div className="flex flex-col">
        <span className="text-[#111827] leading-6 font-bold text-[16px]">
          Match types
        </span>
        <p className="text-[#111827] !m-0 !font-normal text-[16px] leading-6 ">
          Match type will be multiplied with targeting bid
        </p>
      </div>
      {/* input options */}
      <div
        className={cn(
          "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6",
          tabType === TabType.AUTO_CAMPAIGN
            ? "xl:grid-cols-5"
            : "xl:grid-cols-6"
        )}
      >
        {/* targeting bid inputs */}
        <Controller
          name={`${tabType}.matchType.targetingBit`}
          control={control}
          rules={{
            validate: {
              minValue: (value) => {
                const numValue = parseFloat(String(value));
                return numValue >= 0.02 || "Minimum targeting bid is 0.02";
              },
            },
          }}
          render={({ field, fieldState }) => (
            <div className=" flex gap-2 flex-col">
              <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
                Targeting Bid (X)
              </h4>
              <div className="w-full relative">
                <span
                  className={cn(
                    "absolute top-[50%] translate-y-[-50%] left-[1px] px-3.5 py-[10px] rounded-l-[4px] bg-[#F3F4F6] text-[#111827] ",
                    fieldState.error && "border-r !border-red-500"
                  )}
                >
                  <BsCurrencyDollar className="h-4 w-4" />
                </span>
                <input
                  onChange={(e) => {
                    field?.onChange(e.target.value);
                  }}
                  value={field?.value}
                  placeholder="Enter daily budget"
                  className={cn(
                    "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pl-12 hover:border-[#237804] focus:border-[#237804]",
                    fieldState.error && "!border-red-500"
                  )}
                />
              </div>
              {fieldState.error && (
                <span className="text-[#111827] text-sm">
                  {fieldState.error.message}
                </span>
              )}
            </div>
          )}
        />
        {/* 
        #######################################################################
        #---------------------------------------------------------
        # auto campaign specific inputs 
        #---------------------------------------------------------
        #######################################################################
        */}
        {/* close inputs */}
        {tabType === TabType.AUTO_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.close.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.close.selected`}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      checked={field?.value}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.close.selected`}
                    >
                      Close
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.close.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* loose inputs */}
        {tabType === TabType.AUTO_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.loose.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.loose.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.loose.selected`}
                    >
                      Loose
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.loose.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* substitutes inputs */}
        {tabType === TabType.AUTO_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.subsitutes.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.subsitutes.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.subsitutes.selected`}
                    >
                      Substitutes
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.subsitutes.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* complements inputs */}
        {tabType === TabType.AUTO_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.complements.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.complements.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.complements.selected`}
                    >
                      Complements
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.complements.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* 
        ####################################################################
        #---------------------------------------------------------
        #    manual campaign specific inputs 
        #---------------------------------------------------------
        #####################################################################
        */}
        {/* broad inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.broad.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.broad.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.broad.selected`}
                    >
                      Broad
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.broad.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* phrase inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.phrase.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.phrase.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.phrase.selected`}
                    >
                      Phrase
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.phrase.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* exact inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.exact.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.exact.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.exact.selected`}
                    >
                      Exact
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.exact.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* product inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.product.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.product.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.product.selected`}
                    >
                      Product
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.product.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {/* category inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          <div className=" flex gap-2 flex-col">
            <Controller
              name={`${tabType}.matchType.category.selected`}
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <input
                      id={`${tabType}.matchType.category.selected`}
                      checked={field?.value}
                      onChange={(e) => {
                        field?.onChange(!field?.value);
                      }}
                      type="checkbox"
                      className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                    />
                    <label
                      className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                      htmlFor={`${tabType}.matchType.category.selected`}
                    >
                      Category
                    </label>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`${tabType}.matchType.category.value`}
              control={control}
              rules={{
                validate: {
                  minValue: (value) => {
                    const numValue = parseFloat(String(value));
                    return numValue >= 0.02 || "Minimum value is 0.02";
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex gap-2 flex-col">
                  <div className="w-full relative">
                    <span
                      className={cn(
                        "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                        fieldState.error && "border-l !border-red-500"
                      )}
                    >
                      X
                    </span>
                    <input
                      onChange={(e) => {
                        field?.onChange(e.target.value);
                      }}
                      value={field?.value}
                      placeholder="Enter multiplier (X)..."
                      className={cn(
                        "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                        fieldState.error && "!border-red-500"
                      )}
                    />
                  </div>
                  {fieldState.error && (
                    <span className="text-[#111827] text-sm">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchTypeInputs;
