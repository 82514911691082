import columnIcon from "assets/icons/column.svg";
import TopBarActionButton from "../shared/TopBarActionButton";

const ColumnButton = () => {
  return (
    <div className="flex items-center">
      {/* open column button */}
      <TopBarActionButton
        className=""
        clickHandler={() => {
          console.log("Handler clicked filter button");
        }}
        icon={columnIcon}
        text="Column"
        hasArrow={true}
      />
    </div>
  );
};

export default ColumnButton;
