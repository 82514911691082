import { Modal } from "@mui/material";
import doneIcon from "assets/icons/done.svg";
import ErrorLabel from "components/errorLabel/ErrorLabel";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";
// import NotifyMessage from "components/NotifyMessage/NotifyMessage";
import SubmitButton from "components/submitButton/SubmitButton";
import {
  useLoggedInUserQuery,
  useRequestUpdateUserEmailMutation,
  useVerifyToUpdateEmailMutation,
} from "features/user/userSlice";
import React from "react";
import { useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import cn from "utils/cn";

type EmailFormInputs = {
  email: string;
};

type OtpFormInputs = {
  otp: string;
};

const EmailUpdate = () => {
  /**-React Hooks-**/
  //state
  const [showModal, setShowModal] = React.useState(false);
  // email update state
  const [emailUpdateState, setEmailUpdateState] = React.useState(false);

  // presist email address
  const [presistEmail, setPresistEmail] = React.useState<string | null>(null);

  /**-RTK-**/
  //queries
  // RTK: query user data fetching
  const { data: userData } = useLoggedInUserQuery({});
  // mutation
  const [requestUpdateEmail, { isLoading: isRequestUpdateEmailLoading }] =
    useRequestUpdateUserEmailMutation();
  const [verifyUpdateEmail, { isLoading: isVerifyUpdateEmailLoading }] =
    useVerifyToUpdateEmailMutation();

  /**-Hook Form-**/
  // email update form state
  const {
    register: updateEmailFormRegister,
    handleSubmit: updateEmailFormHandleSubmit,
    reset: updateEmailFormReset,
    setError,
    formState: {
      errors: updateEmailFormErrors,
      isDirty: isUpdateEmailFormDirty,
      isValid: isUpdateEmailFormValid,
    },
  } = useForm<EmailFormInputs>({
    mode: "onChange",
    defaultValues: {
      email: userData?.result?.email,
    },
  });

  // otp form state
  const {
    register: otpFormRegister,
    handleSubmit: otpFormHandleSubmit,
    reset: otpFormReset,
    watch: otpWatch,
    formState: {
      errors: otpFormErrors,
      isDirty: isOtpFormDirty,
      isValid: isOtpFormValid,
    },
  } = useForm<OtpFormInputs>({
    mode: "onChange",
  });

  /**-Variables-**/
  const updateEmailFormValid =
    !isUpdateEmailFormValid || !isUpdateEmailFormDirty;
  const otpFormValid = !isOtpFormDirty || !isOtpFormValid;

  /**-Event Handlers-**/
  const updateEmailFormHandleClose = () => {
    setShowModal(false);
    setEmailUpdateState(false);
    updateEmailFormReset();
  };

  const otpFormHandleClose = () => {
    setShowModal(false);
    setEmailUpdateState(false);
    otpFormReset();
  };

  const handleClose = () => {
    setShowModal(false);
    setEmailUpdateState(false);
    otpFormReset();
    updateEmailFormReset();
  };

  // handler: email update
  const handleUpdateEmailSubmit = async (data: EmailFormInputs) => {
    // check user password exist or not
    if (!userData?.result?.hasPassword) {
      NotifyMessage({
        type: "error",
        message: "Error!, You cannot change your email",
      });
      setError("email", {
        message: "Please before updating the email, reset the password first",
      });
      return;
    }

    // Handle form submission
    const body = {
      newEmail: data.email,
    };
    try {
      const res = await requestUpdateEmail(body);
      if (res?.data?.success) {
        NotifyMessage({
          type: "success",
          message: res?.data?.message,
        });
        setEmailUpdateState(true);
        setPresistEmail(data?.email);
        updateEmailFormReset();
      } else {
        const r: any = res?.error;
        setEmailUpdateState(false);
        setPresistEmail(null);
        NotifyMessage({
          type: "error",
          message: r?.data?.message || "Failed to update email address",
        });
      }
    } catch (error) {
      NotifyMessage({
        type: "error",
        message: "Faild to update user information, please try again",
      });
    }
  };

  // handler: send otp
  const handleSendOtpSubmit = async (data: OtpFormInputs) => {
    const body = {
      email: presistEmail,
      code: Number(data?.otp),
    };
    try {
      const res = await verifyUpdateEmail(body);
      if (res?.data?.success) {
        NotifyMessage({
          type: "success",
          message: res?.data?.message,
        });

        setEmailUpdateState(false);
        setShowModal(false);
        otpFormReset();
      } else {
        const r: any = res?.error;
        setEmailUpdateState(true);
        setPresistEmail(null);
        NotifyMessage({
          type: "error",
          message: r?.data?.message || "Faild to verify OTP",
        });
      }
    } catch {
      NotifyMessage({
        type: "error",
        message: "Faild to verify otp, please try again",
      });
    }
  };

  return (
    userData && (
      <>
        <div className="inline-block">
          <div className="w-full relative" onClick={() => setShowModal(true)}>
            {/* update email button */}
            <input
              id="password"
              type="text"
              value={userData?.result?.email || "Enter a new email"}
              className="w-full border-[1px] border-[#D1D5DB] py-2 px-3 rounded-md pr-32 cursor-pointer focus:ring-0 focus:outline-none"
              disabled={false}
            />
            {/* update email button */}
            <button className="absolute right-0 bottom-0 h-full px-5 flex items-center gap-3 font-medium leading-5 text-sm text-[#9CA3AF] ">
              Update Email{" "}
              <img src={doneIcon} alt="icon" className="w-[18px]" />
            </button>
          </div>
          <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="flex h-screen w-screen items-center justify-center bg-transparent">
              {/* <ClickAwayListener onClickAway={handleClose}> */}
              <div className="bg-white p-8 rounded-lg w-full md:w-[400px] relative">
                <div className="flex justify-between">
                  <h4 className="m-0 text-xl text-[#111827] !font-bold leading-8">
                    Update Email Information
                  </h4>
                  <button
                    onClick={handleClose}
                    className="absolute top-0 right-2 py-4 px-3"
                  >
                    <IoClose className="h-5 w-5" />
                  </button>
                </div>
                <hr className=" !bg-[#D4D4D4] w-full my-6" />
                <div className="flex flex-col gap-6">
                  {!emailUpdateState ? (
                    <>
                      {/* 
                  ----------------------------------
                  email update form 
                  ----------------------------------
                  */}
                      <form
                        className="flex flex-col gap-6"
                        onSubmit={updateEmailFormHandleSubmit(
                          handleUpdateEmailSubmit,
                        )}
                      >
                        {/* name input */}
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor="email"
                            className="!font-bold leading-6 text-[16px] text-[#111827]"
                          >
                            Email
                          </label>
                          <div className="w-full">
                            <input
                              id="email"
                              type="email"
                              placeholder={"Enter a new email"}
                              className={cn(
                                "w-full border-[1px] py-2 px-3 rounded-md ",
                                updateEmailFormErrors?.email
                                  ? "border-red-500 focus:outline-none focus:ring-0"
                                  : "border-[#D1D5DB]",
                              )}
                              {...updateEmailFormRegister("email", {
                                required: "Email is required for update",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Invalid email address",
                                },
                              })}
                            />
                          </div>
                          {updateEmailFormErrors?.email && (
                            <ErrorLabel
                              message={updateEmailFormErrors?.email?.message}
                            />
                          )}
                        </div>
                        {/* action button */}
                        <div className="grid grid-cols-2 gap-6">
                          <button
                            onClick={updateEmailFormHandleClose}
                            className="py-2 px-6 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
                          >
                            Cancel
                          </button>
                          <div>
                            <SubmitButton
                              buttonText="save"
                              buttonType="submit"
                              isDisabled={updateEmailFormValid}
                              isLoading={isRequestUpdateEmailLoading}
                            />
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      {/* 
                  -----------------------------------
                  send otp form
                  -----------------------------------
                  */}
                      <form
                        onSubmit={otpFormHandleSubmit(handleSendOtpSubmit)}
                        className="flex flex-col gap-6"
                      >
                        {/* email verification code */}
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor="otp"
                            className="!font-bold leading-6 text-[16px] text-[#111827]"
                          >
                            Email Verification Code
                          </label>
                          <div className="w-full">
                            <input
                              id="otp"
                              type="text"
                              placeholder={"X X X X"}
                              value={otpWatch("otp")}
                              className={cn(
                                "w-full border-[1px]  py-2 px-3 rounded-md",
                                otpFormErrors?.otp
                                  ? "border-red-500 focus:outline-none focus:ring-0"
                                  : "border-[#D1D5DB]",
                              )}
                              {...otpFormRegister("otp", {
                                required: "Otp is required",
                              })}
                            />
                          </div>
                          {otpFormErrors?.otp && (
                            <ErrorLabel message={otpFormErrors?.otp?.message} />
                          )}
                        </div>
                        {/* action button */}
                        <div className="grid grid-cols-2 gap-6">
                          <button
                            onClick={otpFormHandleClose}
                            className="py-2 px-6 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
                          >
                            Cancel
                          </button>
                          <div>
                            <SubmitButton
                              buttonText="save"
                              buttonType="submit"
                              isDisabled={otpFormValid}
                              isLoading={isVerifyUpdateEmailLoading}
                            />
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
              {/* </ClickAwayListener> */}
            </div>
          </Modal>
        </div>
      </>
    )
  );
};

export default EmailUpdate;
