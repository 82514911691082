import { useMemo } from 'react';
import {
    MaterialReactTable as MrtTable,
    type MRT_ColumnDef,
    useMaterialReactTable,
} from 'material-react-table';
import { type BillingHistoryType } from './exampleDummyTableData';

export const BillingHistoryTable: React.FC<{ billingHistoryData: BillingHistoryType[] }> = ({ billingHistoryData }) => {
    const columns = useMemo<MRT_ColumnDef<BillingHistoryType>[]>(
        //column definitions...
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableSorting: false,
            },
            {
                accessorKey: 'paymentMethod',
                header: 'Payment Method',
                enableSorting: true,
            },
            {
                accessorKey: 'description',
                header: 'Plan',
                enableSorting: true,
            },
            {
                accessorKey: 'issuedDate',
                header: 'Issued Date',
                enableSorting: true,
                Cell: ({ cell }) => {
                    return new Date(cell.getValue<string>()).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                }
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                enableSorting: true,
            },
        ],
        [],
    );

    const table = useMaterialReactTable<BillingHistoryType>({
        columns,
        data: billingHistoryData,
        enableGlobalFilterModes: true,
        initialState: {
            showGlobalFilter: true,
        },
        positionGlobalFilter: 'left',
        enableColumnFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableSorting: false,
        muiPaginationProps: {
            // color: 'primary',
            shape: 'rounded',
            variant: 'outlined',
            sx: {
                '& .Mui-selected': {
                    color: "white !important",
                    backgroundColor: "#237804"
                }
            },
        },
        paginationDisplayMode: 'pages',
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default, //change default background color
        }),
        muiTableBodyRowProps: { hover: false },
        muiTablePaperProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableProps: {
            sx: {
                border: '1px solid #e5e7eb',
                caption: {
                    captionSide: 'top',
                },
            },
        },
        muiTableHeadRowProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
                fontWeight: 'normal',
                backgroundColor: '#F9FAFB',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
            },
        },
    });

    return <MrtTable table={table} />;
};

export default BillingHistoryTable;