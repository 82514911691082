import React, { FC } from "react";
import { GoCheckCircleFill } from "react-icons/go";
import { IoIosAddCircleOutline } from "react-icons/io";
import cn from "utils/cn";

interface Props {
  selected: boolean;
}

const ProductRow: FC<Props> = ({ selected }) => {
  return (
    <div className="grid grid-cols-5">
      <div className=" col-span-2 text-[#111827] font-normal leading-6 text-[16px] flex gap-2">
        {/* product image */}
        <img
          src="/assets/images/book1.svg"
          alt="book"
          className="w-[35px] object-cover"
        />
        {/* product details */}
        <div className="flex flex-col justify-center gap-1  ">
          <h4 className="line-clamp-1 text-[#111827] !font-medium !m-0 leading-5 text-sm">
            Food & Snacks Coloring Book: Bold & Lorem ipsum dolor sit amet.
          </h4>
          <div className="flex items-center gap-2 !m-0 font-normal leading-5 text-xs">
            <span>Megan Miles Art</span>
            <span className="w-[8px] h-[8px] rounded-full bg-[#b1b8c4] block m-0 p-0" />
            <span>B07MJ7N9VW</span>
          </div>
        </div>
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">
        Expanded
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">US</div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-center">
        <button type="button" className={cn(selected && "text-[#237804]")}>
          {selected ? (
            <GoCheckCircleFill className="h-5 w-5" />
          ) : (
            <IoIosAddCircleOutline className="h-5 w-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ProductRow;
