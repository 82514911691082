import SignInWithGoogle from "components/SignInWithGoogle/SignInWithGoogle";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useOAuthLoginMutation } from "../../features/auth/authSlice";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";

const LoginWithGoogle: FC = () => {
  const navigate = useNavigate();

  const [oAuthLogin] = useOAuthLoginMutation();

  /**
   * Handle on google login success events
   * */
  const handleOnGoogleLoginSuccess = async (response: any) => {
    if (response) {
      const { authToken, email, firstName, idToken, lastName } = response;

      const apiData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        provider: "google",
        authToken: authToken,
        idToken: idToken,
      };

      try {
        let req = await oAuthLogin(apiData);
        if (req.data?.success) {
          let result = req.data?.result;
          localStorage.setItem("userName", result?.user?.userName);
          localStorage.setItem("email", result?.user?.email);
          localStorage.setItem("userToken", result?.accessToken);
          localStorage.setItem("planName", result?.user?.planName);
          localStorage.setItem("planStatus", result?.user?.planStatus);
          localStorage.setItem("planExpDate", result?.user?.planEXPDate);
          navigate("/");
        } else {
          NotifyMessage({
            type: "error",
            message: "Google login error! try again",
          });
        }
      } catch (error) {
        NotifyMessage({
          type: "error",
          message: "Google login error! try again",
        });
      }
    } else {
      // Fix me: show the model
      NotifyMessage({
        type: "error",
        message: "Google login error! try again",
      });
    }
  };

  /**
   * Handle on google login error events
   */
  const handleOnGoogleLoginError = (response) => {
    console.log("error", response);
    // if (response?.error === "popup_closed_by_user") {
    //   NotifyMessage({
    //     type: "error",
    //     message: "Sign-in process canceled by the user.",
    //   });
    // } else {
    //   NotifyMessage({
    //     type: "error",
    //     message: "Google sign-in error",
    //   });
    // }
  };

  return (
    <SignInWithGoogle
      onError={handleOnGoogleLoginError}
      onSuccess={handleOnGoogleLoginSuccess}
    />
  );
};

export default LoginWithGoogle;
