import { matchRoutes, Outlet, useLocation } from "react-router";
import Navbar from "./navbar/Navbar";
import aiMosaRocketLogo from "assets/images/logo_rocket.png";
import aiMosaLogoWhite from "assets/brand/logo-white2.png";
import { Link, NavLink } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import { useGetDashboardLastSyncQuery } from "features/dashboard/dashboardSlice";
import { useEffect, useState } from "react";
import logoImg from "assets/brand/logo2.png";
import BasicModal from "components/basicModal/BasicModal";
import InfoBox from "components/infoBox/InfoBox";
import { LinearProgress } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import { GoHome } from "react-icons/go";
import AmAccordion from "components/amAccordion/AmAccordion";
import CustomReactRouterLink from "components/customReactRouterLink/CustomReactRouterLink";
import cn from "utils/cn";

const adsStatusMenuOptions = [
  {
    name: "Automation Rule",
    path: "/automation-rule/manager",
  },
  {
    name: "Ads Creation",
    path: "/campaign/add",
  },
  {
    name: "Campain Manager",
    path: "/campaign/manager",
  },
];

const DashboardLayout = () => {
  /**-React Router Hooks-**/
  const location = useLocation();
  const adsRouteMatchedDetails = matchRoutes(
    adsStatusMenuOptions,
    location.pathname
  );
  const adsRouteMatched = adsRouteMatchedDetails?.[0]?.route.path || "";

  /**-React Hooks-**/
  //states
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [adsStatusActive, setAdsStatusActive] = useState(false);

  /**-RTK-**/
  //mutations
  const { data: dashboardLastSyncData, refetch } =
    useGetDashboardLastSyncQuery("");

  /**-useEffect-**/
  useEffect(() => {
    const syncData = dashboardLastSyncData?.result;
    if (dashboardLastSyncData?.message === "success") {
      if (syncData?.lastAdsSync && syncData?.adsSyncStatus) {
        setShowSyncModal(false);
      } else {
        setShowSyncModal(true);
      }
    }
  }, [dashboardLastSyncData]);

  useEffect(() => {
    if (adsRouteMatched) {
      setAdsStatusActive(true);
    } else {
      setAdsStatusActive(false);
    }
  }, [adsRouteMatched]);

  return (
    <div className="grow flex overflow-hidden">
      {" "}
      {/*grow is required to take the available space vertically*/}
      <aside className="group relative">
        <section className="h-screen overflow-hidden bg-am-secondary">
          <div className="h-full px-3 py-6 overflow-y-auto overflow-x-hidden flex flex-col">
            <section className="pb-8 border-b border-gray-400">
              <Link to="/">
                <img
                  src={aiMosaRocketLogo}
                  alt="Aimosa logo"
                  className="w-8 2xl:w-10"
                />
              </Link>
            </section>
            <section className="grow">
              <div className="grid gap-6 justify-items-center py-8">
                <CustomReactRouterLink
                  to="/"
                  className="px-1 py-2 text-white hover:text-am-primary-light"
                  activeClass="!text-am-primary-light !no-underline"
                  iconComponent={<GoHome className="text-[29px] " />}
                />
                <NavLink
                  to="/campaign/manager"
                  className={
                    cn(
                      "grid place-items-center h-[26px] w-[26px] hover:bg-am hover:no-underline border-2 border-white text-white hover:border-am-primary-light hover:text-am-primary-light text-xs font-semibold tracking-wide rounded-lg select-none",
                      adsStatusActive && "!text-am-primary-light !no-underline border-am-primary-light"
                    )
                  }
                >
                  <span>AD</span>
                </NavLink>
              </div>
            </section>
            <section>
              <div className="grid gap-6">
                <CustomReactRouterLink
                  to="/settings"
                  className="text-white hover:text-am-primary-light"
                  activeClass="!text-am-primary-light !no-underline"
                  iconComponent={<CiSettings className="text-2xl 2xl:text-3xl" />}
                />
                <CustomReactRouterLink
                  to="/"
                  className="text-red-500 hover:text-red-700"
                  activeClass="!text-red-500 !no-underline"
                  iconComponent={<IoLogOutOutline className="text-[26px] rotate-180" />}
                />
              </div>
            </section>
          </div>
        </section>
        {/* expended sidebar */}
        <section className="hidden group-hover:flex absolute top-0 left-0 z-10 h-screen overflow-hidden overflow-x-hidden bg-am-secondary">
          <div className="h-full px-3 py-6 overflow-y-auto overflow-x-hidden flex flex-col">
            <section className="pb-8 border-b border-gray-400">
              <Link to="/">
                <img src={aiMosaLogoWhite} alt="Aimosa logo" />
              </Link>
            </section>
            <section className="grow">
              <div className="grid gap-9 py-8 px-2">
                <CustomReactRouterLink
                  to="/"
                  className="text-white hover:text-am-primary-light"
                  activeClass="!text-am-primary-light !no-underline"
                  iconComponent={<GoHome className="text-[28px] " />}
                  text="Dashhboard"
                />
                <div className="group/adStatus hover:no-underline text-white hover:text-am-primary-light">
                  <AmAccordion
                    key={`${adsStatusActive}`}
                    defaultExpanded={adsStatusActive ? true : false}
                    headerComponent={
                      <div className="flex items-center gap-2">
                        <p
                          className={cn(
                            "border-2 inline-block rounded-lg p-[3px] text-[10px] font-medium tracking-wider select-none text-white group-hover/adStatus:border-am-primary-light group-hover/adStatus:text-am-primary-light",
                            adsStatusActive &&
                            "border-am-primary-light text-am-primary-light"
                          )}
                        >
                          AD
                        </p>
                        <p
                          className={cn(
                            "text-white group-hover/adStatus:text-am-primary-light text-base tracking-wide mt-0",
                            adsStatusActive && "text-am-primary-light"
                          )}
                        >
                          Ads Status
                        </p>
                      </div>
                    }
                    bodyComponent={
                      <ul className="text-white grid gap-2">
                        {adsStatusMenuOptions?.map((option, index) => (
                          <li key={index}>
                            <CustomReactRouterLink
                              to={option?.path}
                              className="text-gray-400 hover:text-white"
                              activeClass="!text-white !no-underline after:content-['•'] after:ml-5 after:text-xl"
                            >
                              <span>{option?.name}</span>
                            </CustomReactRouterLink>
                          </li>
                        ))}
                      </ul>
                    }
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="grid gap-6">
                <CustomReactRouterLink
                  to="/settings"
                  className="text-white hover:text-am-primary-light"
                  activeClass="!text-am-primary-light !no-underline"
                  iconComponent={<CiSettings className="text-2xl 2xl:text-3xl" />}
                  text="Settings"
                />
                <CustomReactRouterLink
                  to="/"
                  className="text-red-500 hover:text-red-700"
                  activeClass="!text-red-500 !no-underline"
                  iconComponent={<IoLogOutOutline className="text-[26px] rotate-180" />}
                  text="Logout"
                />
              </div>
            </section>
          </div>
        </section>
      </aside>
      <section className="grow">
        <div className="overflow-hidden h-screen">
          <div className="h-full overflow-auto grid grid-cols-1 ">
            <div className="flex flex-col">
              <Navbar />
              <section className="grow px-4 pt-4">
                <Outlet />
              </section>
              <section className="py-3.5  px-4 flex flex-row flex-wrap justify-between">
                <p className="text-[#6B7280] text-xs">&copy; 2024, Aimosa</p>
                <div className="flex flex-row gap-6">
                  {/* @TODO: link/show the actual content */}
                  <Link to="/" className="text-[#6B7280] text-xs">
                    Privacy Policy
                  </Link>
                  <Link to="/" className="text-[#6B7280] text-xs">
                    Documentation
                  </Link>
                  <Link to="/" className="text-[#6B7280] text-xs">
                    Support
                  </Link>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <BasicModal
        showModal={showSyncModal}
        setShowModal={setShowSyncModal}
        disableOverlayClick={true}
        crossButton={true}
        headerLogoImg={logoImg}
        modalParentClass="max-w-[540px]"
        headerLogoClass="py-6"
        modalBodyClass="px-6"
      >
        <div className="grid gap-4">
          <div>
            <p className="text-base font-medium text-black">Data syncing</p>
            <p className="text-am-aiGray mt-0">
              Your data is currently syncing. you’ll receive an email when it is
              completed.
            </p>
          </div>
          <div className="grid gap-2">
            <p className="text-base">Fetching data...</p>
            <LinearProgress color="success" />
          </div>
          <InfoBox
            type="warning"
            className="py-3 justify-start"
            message="This process will continue even if you close this window."
          />
          <div className="flex justify-center">
            <button
              className="rounded px-3 py-2 bg-am-primary text-white font-medium tracking-wide flex gap-1 items-center"
              onClick={() => refetch()}
            >
              <IoMdRefresh className="text-base" />
              <span>Refresh</span>
            </button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default DashboardLayout;
