import { CircularProgress } from "@mui/material";
import cn from "utils/cn";

export type ButtonType = "submit" | "button" | "reset" | undefined;

interface SubmitButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  buttonText?: string;
  buttonType?: ButtonType;
  onClick?: () => void
  disabledClassName?: string;
  loadingClassName?: string;
  className?: string;
  loaderSize?: number;
}

const SubmitButton = ({ isDisabled = false, isLoading = false, buttonText = "Submit", buttonType = "submit", onClick, disabledClassName, loadingClassName, className, loaderSize = 20 }: SubmitButtonProps) => {
  return (
    <>
      {
        isDisabled
          ?
          <div className={cn(
            "w-full py-2.5 bg-[#E5E5E5] text-[#A3A3A3] rounded-[4px] text-[16px] leading-6 font-bold text-center cursor-not-allowed select-none",
            disabledClassName
          )}>{buttonText}</div>
          :

          isLoading
            ?
            <div className={cn(
              "w-full py-2.5 bg-am-primary text-white rounded-[4px] text-[16px] leading-6 font-bold text-center cursor-not-allowed select-none flex justify-center items-center gap-2",
              loadingClassName
            )}>
              <CircularProgress
                className="!text-white"
                size={loaderSize}
              />
              Loading...
            </div>
            :
            <button
              type={buttonType}
              onClick={onClick}
              className={cn(
                "w-full py-2.5 bg-am-primary text-white rounded-[4px] text-[16px] leading-6 font-bold",
                className
              )}
            >
              {buttonText}
            </button>

      }
    </>
  );
};

export default SubmitButton;
