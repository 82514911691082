import tagIcon from "assets/icons/tag.svg";
import TopBarActionButton from "../shared/TopBarActionButton";

const ManageTagsButton = () => {
  return (
    <div className="flex items-center">
      {/* open column button */}
      <TopBarActionButton
        className=""
        clickHandler={() => {
          console.log("Handler clicked filter button");
        }}
        icon={tagIcon}
        text="Manage Tags"
      />
    </div>
  );
};

export default ManageTagsButton;
