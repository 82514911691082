import styled from "@emotion/styled";
import { Radio } from "@mui/material";
import SelectedNegativeProductsRow from "./SelectedNegativeProductsRow";
import CustomSelectOptionWithMultiple from "components/customSelectOptionWithMultiple/CustomSelectOptionWithMultiple";

const ContentBoxContainer = styled("div")(() => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
}));

const NegativeProductsTab = () => {
  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
      {/* left box */}
      <div className="flex flex-col gap-2 h-full ">
        {/* box header */}
        <div className="flex gap-x-6 items-center flex-wrap">
          {/* apply to radio and inputs */}
          <div className="flex flex-col gap-2">
            {/* header */}
            <h4 className="text-[#111827] !font-bold !m-0 leading-6 text-[16px]">
              Apply to
            </h4>
            {/* radio button */}
            <div className="flex gap-4 items-center">
              {/* campaign radio button */}
              <div className="flex items-center justify-start">
                <Radio
                  id="campaign"
                  sx={{
                    color: "#6B7280",
                    padding: "0px",
                    margin: "0px",
                    marginRight: "5px",
                    "&.Mui-checked": {
                      color: "#237804",
                    },
                  }}
                />
                <label htmlFor="campaign">Campaign</label>
              </div>
              {/* ad group radio button */}
              <div className="flex items-center justify-start">
                <Radio
                  id="ad-group"
                  sx={{
                    color: "#6B7280",
                    padding: "0px",
                    margin: "0px",
                    marginRight: "5px",
                    "&.Mui-checked": {
                      color: "#237804",
                    },
                  }}
                />
                <label htmlFor="ad-group">Ad group</label>
              </div>
            </div>
          </div>
          {/* choose ad group */}
          <div className="flex flex-col w-full  2xl:w-[300px]">
            <h4 className="text-[#111827] !font-bold !m-0 leading-6 text-[16px] text-transparent">
              hidden text for grid
            </h4>
            <CustomSelectOptionWithMultiple
              placeholder="Choose ad group"
              field={{}}
              options={[
                {
                  name: "Choose Match Ad Group",
                  value: "Choose Match Ad Group",
                },
                {
                  name: "Loose Match Ad Group",
                  value: "Loose Match Ad Group",
                },
                {
                  name: "Substitude Match Ad group",
                  value: "Substitude Match Ad group",
                },
                {
                  name: "Complements Match Ad Group",
                  value: "Complements Match Ad Group",
                },
              ]}
            />
          </div>
        </div>
        {/* keywords inputs button */}
        <div className="w-full flex flex-col gap-4">
          <textarea
            className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 min-h-[200px]"
            placeholder="Separate keyword with comma (,) or enter new line"
          />
          {/* keyword  add info for small screen */}
          <div className="flex lg:hidden ">
            <div className="w-full flex justify-between">
              <p>You can enter up to 1000 keywords</p>
              <button
                type="button"
                className="text-[#237804] bg-[#CAFCD0] rounded-[3px] py-2 px-4 font-bold"
              >
                Add Keyword
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* right blox */}
      <div className="flex flex-col gap-2 h-full items-end justify-end">
        <div className="w-full flex justify-between">
          <p className="text-[#6B7280] text-[16px] !font-medium !m-0 leading-6">
            0 keyword added
          </p>
          <button
            type="button"
            className="text-[#D1D5DB] text-[16px] !font-medium leading-6"
          >
            Remove all
          </button>
        </div>
        {/* keywords inputs button */}
        <div className="w-full flex flex-col">
          <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[200px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
            {/* row header */}
            <div className="grid grid-cols-3">
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                ASINs
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Apply to
              </div>
            </div>
            <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
              <SelectedNegativeProductsRow />
              <SelectedNegativeProductsRow />
              <SelectedNegativeProductsRow />
              <SelectedNegativeProductsRow />
              <SelectedNegativeProductsRow />
            </ContentBoxContainer>
          </div>
        </div>
      </div>
      {/* keyword  add info for big screen */}
      <div className="hidden lg:flex">
        <div className="w-full flex justify-between">
          <p>You can enter up to 1000 keywords</p>
          <button
            type="button"
            className="text-[#237804] bg-[#CAFCD0] rounded-[3px] py-2 px-4 font-bold"
          >
            Add Keyword
          </button>
        </div>
      </div>
    </div>
  );
};

export default NegativeProductsTab;
