import { FC } from 'react'
import { BsExclamationCircle } from "react-icons/bs";
import cn from "../../utils/cn";


export type InfoType = "success" | "warning" | "danger";

interface Props {
    type: InfoType,
    className?: string,
    message: string
}

const InfoBox: FC<Props> = ({ className, type, message }) => {
    const bg = type === "success"  ? "bg-green-300/10" : type === "warning" ? "bg-[#FFBD06]/10" : "bg-red-300/10";
    const text = type === "success" ? "text-green-500" : type === "warning" ? "text-[#926B00]" : "text-red-500";
    const border = type === "success" ? "border-green-500" : type === "warning" ? "border-yellow-400" : "border-red-500";
  return (
    <div className={cn(
        'flex gap-3 py-[4px] px-2 items-center justify-center rounded-[4px] border-[1px] ',
        border,
        bg,
        text,
        className
    )}>
        <div>
            <BsExclamationCircle className='h-5 w-5' />
        </div>
        <p className={cn(
            'm-0 text-[14px] leading-5 font-medium',
           text 
        )}>{message}</p>
    </div>
  )
}

export default InfoBox