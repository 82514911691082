import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DashboardLayout from "../../../../layouts/XDashboardLayout";
import { Col, Row, Container } from "react-bootstrap";
import ManualCampaign from "./campaigns/manualCampaign";
import LastAppSync from "../lastAppSync";
import AutoCampaign from "./campaigns/autoCampaign";
import { useLocation } from "react-router-dom";
const Bulkoperation: React.FC = () => {
  const location = useLocation();
  const { state } = location;

  return (
    <DashboardLayout>
      {/* <LastAppSync /> */}
      <div className="main-content-container">
        <hr />
        <Container fluid>
          <Row>
            <Col>
              <Tabs defaultActiveKey="AutoCampaign" className="ms-1">
                <Tab eventKey="AutoCampaign" title="Auto Campaign">
                  <AutoCampaign />
                </Tab>
                <Tab eventKey="ManualCampaign" title="Manual Campaign">
                  <ManualCampaign />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default Bulkoperation;
