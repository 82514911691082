
type BrowserType = "Chrome" | "Firefox" | "Safari" | "Edge" | "Internet Explorer" | "Unknown";

export default function detectBrowser(): BrowserType {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Chrome")) {
        return "Chrome";
    } else if (userAgent.includes("Firefox")) {
        return "Firefox";
    } else if (userAgent.includes("Safari")) {
        return "Safari";
    } else if (userAgent.includes("Edge")) {
        return "Edge";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        return "Internet Explorer";
    } else {
        return "Unknown";
    }
}