import deleteIcon from "assets/icons/delete_icon.svg";
import copyIcon from "assets/icons/copy.svg";
import { Radio } from "@mui/material";

import { FC } from "react";

import cn from "utils/cn";
import { conditionOptions, levelOptions } from "./data";
import { Control, Controller, UseFormGetValues } from "react-hook-form";
import {
  ApplyToFormState,
  NeighborRelationType,
  TargetRow,
} from "./AddNewAutomationRuleApplyTo.types";
import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";

interface Props {
  currentIndex: number;
  totalLength: number;
  relationToTopNeighbor?: NeighborRelationType;
  relationToBottomNeighbor?: NeighborRelationType;
  handleDeleteRow: (index: number) => void;
  handleCopyRow: (index: number, row: TargetRow) => void;
  control: Control<ApplyToFormState, any>;
  getValues: UseFormGetValues<ApplyToFormState>;
}

const ApplicableTargetsRow: FC<Props> = ({
  currentIndex,
  totalLength,
  relationToBottomNeighbor,
  relationToTopNeighbor,
  handleDeleteRow,
  handleCopyRow,
  control,
  getValues,
}) => {
  return (
    <div className="flex flex-col h-auto">
      <div className="flex flex-row gap-3 h-auto">
        {/* left side bar */}
        <div
          className={cn(
            "grow w-[10px]  ",
            (currentIndex === 0 ||
              relationToTopNeighbor === NeighborRelationType.OR ||
              relationToTopNeighbor === NeighborRelationType.NONE) &&
              "rounded-t-lg",
            (currentIndex === totalLength - 1 ||
              relationToBottomNeighbor === NeighborRelationType.OR) &&
              "rounded-b-lg",
            currentIndex === 0
              ? relationToBottomNeighbor === NeighborRelationType.AND
                ? "bg-[#237804]"
                : "bg-transparent"
              : currentIndex === totalLength - 1
              ? relationToTopNeighbor === NeighborRelationType.AND
                ? "bg-[#237804]"
                : "bg-transparent"
              : relationToBottomNeighbor === NeighborRelationType.AND ||
                relationToTopNeighbor === NeighborRelationType.AND
              ? "bg-[#237804]"
              : "bg-transparent",

            totalLength === 1 && "hidden"
          )}
        />
        {/* contents */}
        <div className="w-full flex flex-col gap-5">
          <div className="w-full gap-4 grid grid-cols-12 items-center">
            {/* operand input option */}
            <div className="col-span-3">
              <Controller
                name={`targets.${currentIndex}.operand`}
                control={control}
                render={({ field }) => (
                  <CustomSelectOption
                    placeholder={"Select Level"}
                    options={levelOptions}
                    field={field}
                  />
                )}
              />
            </div>
            {/* operator input option */}
            <div className="col-span-3">
              <Controller
                name={`targets.${currentIndex}.operator`}
                control={control}
                render={({ field }) => (
                  <CustomSelectOption
                    placeholder="Select Condition"
                    options={conditionOptions}
                    field={field}
                  />
                )}
              />
            </div>
            {/* input value */}
            <div className="col-span-5">
              <Controller
                name={`targets.${currentIndex}.value`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <input
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    value={value}
                    className="w-full col-span-5 outline-none px-[14px] py-[11px] border-[1px] placeholder:text-[16px] text-[16px] border-stone-200 text-[#171717] leading-6 !font-normal rounded-[4px] hover:border-[#237804] focus:border-[#237804] transition-all duration-200"
                    placeholder="Enter keyword...."
                    type="text"
                  />
                )}
              />
            </div>
            {/* action button */}
            <div className="flex gap-2">
              {/* copy/link button button */}
              <button
                type="button"
                className="border-[1px] p-2 rounded-lg hover:border-[#237804] transition-all duration-200"
                onClick={() =>
                  handleCopyRow(
                    currentIndex,
                    getValues(`targets.${currentIndex}`)
                  )
                }
              >
                <img src={copyIcon} className="w-[30px]" alt="copy" />
              </button>
              {/* delete button */}
              <button
                type="button"
                className="border-[1px] p-2 rounded-lg hover:border-[#237804] transition-all duration-200"
                onClick={() => handleDeleteRow(currentIndex)}
              >
                <img src={deleteIcon} className="w-[30px] " alt="delete" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* relation button */}
      <div
        className={cn(
          "flex flex-row gap-3 h-auto",
          currentIndex === totalLength - 1 && "hidden",
          totalLength === 1 && "hidden"
        )}
      >
        {/* left side bar */}
        <div
          className={cn(
            "grow w-[10px]",
            currentIndex === totalLength - 1 && "rounded-b-lg",
            getValues(`targets.${currentIndex}.relationToBottomRow`) ===
              NeighborRelationType.AND
              ? "bg-[#237804]"
              : "bg-transparent"
          )}
        />
        {/* operation box for or */}
        <div
          className={cn(
            "w-full flex items-center justify-start gap-5 mb-5 mt-5"
          )}
        >
          <Controller
            name={`targets.${currentIndex}.relationToBottomRow`}
            control={control}
            render={({ field }) => (
              <>
                <div className="flex items-center justify-start">
                  <Radio
                    id="and"
                    value={NeighborRelationType.AND}
                    checked={field.value === NeighborRelationType.AND}
                    onChange={field.onChange}
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#237804",
                      },
                    }}
                  />
                  <label htmlFor="and">And</label>
                </div>
                <div className="flex items-center justify-start">
                  <Radio
                    id="or"
                    value={NeighborRelationType.OR}
                    checked={field.value === NeighborRelationType.OR}
                    onChange={field.onChange}
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#237804",
                      },
                    }}
                  />
                  <label htmlFor="or">OR</label>
                </div>
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicableTargetsRow;
