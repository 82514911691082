import { LinearProgress } from "@mui/material";
import LoadingScreen from "components/loadingScreen/LoadingScreen";
import { useLoggedInUserQuery } from "features/user/userSlice";
import { Navigate, Outlet } from "react-router";

const AuthenticationLayout = () => {
  /**-RTK-**/
  //queries
  const { data: loggedInUserData, isLoading } = useLoggedInUserQuery("");

  const authenticated =
    loggedInUserData?.result?.id && localStorage.getItem("userToken");

  if (isLoading) {
    return (
      <div className="h-screen grid place-content-center">
        <div className="grid gap-3">
          <LoadingScreen />
          <LinearProgress color="success" />
        </div>
      </div>
    );
  }

  // return (
  //   <Outlet />
  // )

  console.log("loggedInUserData", loggedInUserData);

  return (
    authenticated
      ? (
        loggedInUserData?.result?.awsCredentials
          ? (
            <Outlet />
          )
          : (
            <Navigate to="/onboarding/amazon-connect" />
          )
      )
      : (
        <Navigate to="/login" />
      )
  )
};

export default AuthenticationLayout;
