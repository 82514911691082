import { apiSlice } from "features/api/apiSlice";

const amazonSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAmazonConnections: builder.query({
            query: () => `/AmazonConnection`,
            providesTags: ['getAmazonConnections']
        }),
        updateAmazonConnection: builder.mutation({
            query: (data) => ({
                url: `/Users/Onboarding/ProfilePreferences`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['getAmazonConnections']
        })
    })
})

export const {
    useGetAmazonConnectionsQuery,
    useUpdateAmazonConnectionMutation
} = amazonSlice;