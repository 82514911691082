import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import logo from "assets/brand/logo.svg";
import cn from "utils/cn";
import styled from "@emotion/styled";
import { StepConnector, stepConnectorClasses } from "@mui/material";
import { IoMdCheckmark } from "react-icons/io";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export interface StepComponentProps {
  step: number;
  handleNext?: () => void;
  handleComplete?: () => void;
}

// step interface
interface StepInterface {
  title: string;
}

// step connector styled component
const Connector = styled(StepConnector)(({ theme }) => ({
  // completed step line color
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#237804",
    },
  },
  // activate step line color
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#237804",
    },
  },
  // default step line color
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    background: "#A1AEBE",
  },
}));

// main component
const OnboardingStepperLayout = () => {
  const activeStep = useSelector(
    (state: RootState) => state.stepCounter.activeStep
  );
  const completed = useSelector(
    (state: RootState) => state.stepCounter.completeStep
  );

  // step: list
  const steps: StepInterface[] = [
    {
      title: "Connect Amazon",
    },
    {
      title: "Select Marketplace",
    },
    {
      title: "Install Extension",
    },
  ];

  return (
    <div className="grow grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-32" />
        </div>
        {/* steps tracking */}
        <div className="w-full flex items-center justify-center">
          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={{
              marginBottom: 8,
              width: "95%",
            }}
            connector={<Connector />}
          >
            {steps.map((label, index) => (
              <Step
                key={index}
                completed={completed[index]}
                className="!p-0 relative"
              >
                <button
                  className={cn(
                    "w-9 h-9 rounded-full border-[2px] flex items-center justify-center font-medium text-xs",
                    activeStep === index || completed[index]
                      ? "border-green-600 text-green-600"
                      : "border-[#A1AEBE] text-[#242E39]",
                    completed[index] && "bg-green-600 text-white"
                  )}
                >
                  {completed[index] ? (
                    <IoMdCheckmark className="h-5 w-5" />
                  ) : (
                    `0${index + 1}`
                  )}
                </button>
                <p
                  className={cn(
                    "m-0 absolute -bottom-16 left-[50%] translate-x-[-50%] text-center flex items-center justify-center font-medium leading-5 text-sm",
                    activeStep === index || completed[index]
                      ? "text-[#237804]"
                      : "text-[#171717]"
                  )}
                >
                  {label["title"]}
                </p>
              </Step>
            ))}
          </Stepper>
        </div>
        {/* step action block */}
        <div className="border-t-[1px] w-full sm:w-[540px] overflow-hidden pt-6 flex flex-col gap-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default OnboardingStepperLayout;
