import SubmitButton from "components/submitButton/SubmitButton";
import { IoSyncSharp } from "react-icons/io5";
import { MdChevronLeft } from "react-icons/md";
import cn from "utils/cn";
import { CreateFormState, TabType } from "./CreateAds.types";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import CreateAdsAutoCampaignTab from "./createAdsAutoCampaignTab/CreateAdsAutoCampaignTab";
import CreateAdsManualCampaignTab from "./createAdsManualCampaignTab/CreateAdsManualCampaignTab";
import Warning from "./Warning";

const CreateAds = () => {
  /* tab active state */
  const [activeTab, setActiveTab] = useState<TabType>(TabType.AUTO_CAMPAIGN);
  /* warning tab */
  const [warningOpen, setWarningOpen] = useState<boolean>(false);

  /* Hook form */
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm<CreateFormState>({
    mode: "onChange",
    defaultValues: {
      autoCampaign: {
        campaignName: {
          placeholders: [],
        },
        adGroupName: {
          placeholders: [],
        },
        timeline: {
          noEndDate: false,
        },
      },
      manualCampaign: {
        campaignName: {
          placeholders: [],
        },
        adGroupName: {
          placeholders: [],
        },
        timeline: {
          noEndDate: false,
        },
      },
    },
  });

  /**-- handler --**/
  const formHandler: SubmitHandler<CreateFormState> = () => {
    setWarningOpen(true);
  };

  console.log(
    "Form state: =>>",
    watch("autoCampaign"),
    watch("manualCampaign")
  );
  return (
    <form onSubmit={handleSubmit(formHandler)} className="flex flex-col gap-4">
      {/* back and sync button */}
      <div className="flex items-center justify-between bg-white py-2 px-3 ">
        {/* back button */}
        <button
          type="button"
          className=" capitalize flex items-center gap-1 text-[#6B7280] text-sm !font-bold leading-5"
        >
          <MdChevronLeft className="h-5 w-5" />
          <span>create ads</span>
        </button>
        <div className="flex items-center gap-2 ">
          <button type="button" className="!font-bold text-[#6B7280]">
            <IoSyncSharp className="h-5 w-5" />
          </button>
          <span className="text-[#6B7280] text-xs !font-bold leading-5 capitalize">
            last app sync
          </span>
          <span className="text-[#6B7280] text-xs !font-normal leading-5">
            Wed, Nov 2, 10:57
          </span>
        </div>
      </div>
      {/* main content box */}
      <div className="flex flex-col bg-white rounded-[4px]">
        {/* page switching buttons */}
        <div className="flex flex-row flex-wrap gap-6 border-b  px-4">
          <button
            type="button"
            className={cn(
              "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
              activeTab === TabType.AUTO_CAMPAIGN &&
                " border-[#237804] text-[#111827] "
            )}
            onClick={() => setActiveTab(TabType.AUTO_CAMPAIGN)}
          >
            Auto Campaign
          </button>

          <button
            type="button"
            className={cn(
              "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
              activeTab === TabType.MANUAL_CAMPAIGN &&
                " border-[#237804] text-[#111827] "
            )}
            onClick={() => setActiveTab(TabType.MANUAL_CAMPAIGN)}
          >
            Manual Campaign
          </button>
        </div>
        {/* content */}
        <div className="p-4">
          <div className="flex flex-col gap-5">
            {/* tab content */}
            {activeTab === TabType.AUTO_CAMPAIGN ? (
              <CreateAdsAutoCampaignTab
                control={control}
                tabType={TabType.AUTO_CAMPAIGN}
                getValues={getValues}
                watch={watch}
                setValue={setValue}
              />
            ) : (
              <CreateAdsManualCampaignTab
                control={control}
                tabType={TabType.MANUAL_CAMPAIGN}
                getValues={getValues}
                watch={watch}
                setValue={setValue}
              />
            )}

            {/* form action button */}
            <div className="flex items-center justify-end gap-5">
              <button
                type="button"
                className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
              >
                back
              </button>
              <div>
                <SubmitButton
                  buttonText="Finish"
                  buttonType="submit"
                  className="px-8"
                  loadingClassName="px-8"
                  disabledClassName="px-8"
                  isDisabled={!isValid}
                  isLoading={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* warning model */}
      <Warning
        open={warningOpen}
        setOpen={setWarningOpen}
        setActiveTab={setActiveTab}
      />
    </form>
  );
};

export default CreateAds;
