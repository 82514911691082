import React, { FC } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import cn from "utils/cn";

interface Props {
  className?: string;
  icon?: string;
  text?: string;
  clickHandler?: () => void;
  hasArrow?: boolean;
}

const TopBarActionButton: FC<Props> = ({
  icon,
  className,
  text,
  clickHandler,
  hasArrow,
}) => {
  return (
    <button
      className={cn(
        "flex gap-2 items-center text-[#6B7280] text-sm font-medium leading-5",
        className
      )}
      onClick={() => clickHandler && clickHandler()}
    >
      <span>
        <img alt={text} src={icon} />
      </span>
      <span>{text}</span>
      {hasArrow && (
        <span>
          <MdKeyboardArrowDown />
        </span>
      )}
    </button>
  );
};

export default TopBarActionButton;
