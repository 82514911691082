import ProfileManue from "./ProfileManue";

const Navbar = () => {
    return (
        <nav className='flex bg-white p-3.5'>
            <div>
                <p className="text-xl font-medium">Settings</p>
            </div>
            <div className='grow grid justify-items-end'>
                <ProfileManue />
            </div>
        </nav>
    );
};

export default Navbar;