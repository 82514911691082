import React, { FC } from "react";
import MarketplaceToggleButton from "./MarketplaceToggleButton";
import { Marketplace } from "./AddNewAutomationRuleApplyTo.types";
import { ApplyToFormState } from "./AddNewAutomationRuleApplyTo.types";
import { Control, Controller } from "react-hook-form";

interface Props {
  marketplace: Marketplace;
  control: Control<ApplyToFormState, any>;
}

const MarketPlaceSelectionOptions: FC<Props> = ({ marketplace, control }) => {
  return (
    <div className="flex flex-col gap-2">
      <h5 className="capitalize leading-6 !font-normal text-[16px] text-[#737373]">
        {marketplace.region}
      </h5>
      <div className="flex flex-row flex-wrap gap-3">
        {marketplace.marketplaces.map((cc, index) => {
          return (
            <Controller
              key={index}
              name={`marketPlaces.${cc.n}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <MarketplaceToggleButton
                  toggleSelect={value !== undefined}
                  countryCode={cc?.countryCode}
                  onClick={() => {
                    const newValue =
                      value === undefined ? cc.countryCode : undefined;
                    onChange(newValue);
                  }}
                />
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MarketPlaceSelectionOptions;
