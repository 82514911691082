import { Dayjs } from "dayjs";

export enum TabType {
  AUTO_CAMPAIGN = "autoCampaign",
  MANUAL_CAMPAIGN = "manualCampaign",
}

export enum CampaignStructureType {
  TYPE_1 = "SingleCampaignWithSingleAdgroup",
  TYPE_2 = "SingleCampaignWithMultipleAdgroup",
  TYPE_3 = "MultipleCampaignWithSingleAdgroup",
}

export enum PlaceholderType {
  CAMPAIGN_STRUCTURE = "Campaign Structure",
  TARGETING_TYPE = "Targeting Type",
  MATCH_TYPE = "Match Type",
  ASIN = "ASIN",
  CUSTOM_TEXT = "Custom Text"
}

export interface CreateFormState {
  autoCampaign: {
    campaignName: CampaignNameType;
    adGroupName: AdGroupNameType;
    campaignStructureType: CampaignStructureType;
    timeline: Timeline;
    bidSettings: BidSettings,
    matchType: AutoCampaignMatchType,
    placement: Placement,
    marketPlaces: Marketplace[];
  };
  manualCampaign: {
    types: {
      keywordsTargeting: number;
      productTargeting: number;
    };
    campaignName: CampaignNameType;
    adGroupName: AdGroupNameType;
    campaignStructureType: CampaignStructureType;
    timeline: Timeline;
    bidSettings: BidSettings;
    matchType: ManualCampaignMatchType,
    placement: Placement,
    marketPlaces: Marketplace[];
  };
}

export interface Placement {
  topOfSearch: number | string,
  productDetailPages: number | string,
  restOfSearch: number | string
}

export interface AutoCampaignMatchType {
  targetingBit: number | string,
  close: {
    selected: boolean,
    value: number | string
  },
  loose: {
    selected: boolean,
    value: number | string
  },
  subsitutes: {
    selected: boolean,
    value: number | string
  },
  complements: {
    selected: boolean,
    value: number | string
  }
}
export interface ManualCampaignMatchType {
  targetingBit: number | string,
  broad: {
    selected: boolean,
    value: number | string
  },
  phrase: {
    selected: boolean,
    value: number | string
  },
  exact: {
    selected: boolean,
    value: number | string
  },
  product: {
    selected: boolean,
    value: number | string
  },
  category: {
    selected: boolean,
    value: number | string
  }
}

export interface BidSettings {
  campaignBiddingStrategy: CampaignBiddingStrategy,
  dailyBudget: number | string,
  defaultBid: number | string,
}

export enum CampaignBiddingStrategy {
  DOWN_ONLY = "Down only",
  UP_AND_DOWN = "Up and down",
  FIXED = "Fixed"
}
export interface Timeline {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  noEndDate: boolean;
}

export interface CampaignNameType {
  name: string;
  placeholders: { placeholderType: PlaceholderType, value: string }[];
}

export interface AdGroupNameType {
  name: string;
  placeholders: { placeholderType: PlaceholderType, value: string }[];
}

export interface Marketplace {
  region: string;
  marketplaces: { countryCode: string; n: number }[];
}
