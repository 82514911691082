import ErrorLabel from "components/errorLabel/ErrorLabel";

import ApplicableTargetsRow from "./ApplicableTargetsRow";
import { MdAddCircleOutline } from "react-icons/md";
import SubmitButton from "components/submitButton/SubmitButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import cn from "utils/cn";
import MarketPlaceSelectionOptions from "./MarketPlaceSelectionOptions";

import {
  autoCampaign,
  manualCampaignKeywordTargeting,
  manualCampaignProductTargeting,
  marketplaces,
} from "./data";

import {
  ApplyToFormState,
  NeighborRelationType,
  TargetRow,
} from "./AddNewAutomationRuleApplyTo.types";
import MatchTypeInputs from "./MatchTypeInputs";

const AddNewAutomationRuleApplyTo = () => {
  /**-- Hook Form --*/
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm<ApplyToFormState>({
    mode: "onChange",
    defaultValues: {
      ruleName: "",
      marketPlaces: [],
      matchType: {
        totalAutoCampaignSelected: 0,
        totalManualCampaignSelected: 0,
      },
      targets: [
        {
          relationToBottomRow: NeighborRelationType.OR,
          operand: undefined,
          operator: undefined,
          value: undefined,
        },
      ],
    },
  });

  /**-- Handlers --*/

  // add target row
  const handleAddTargetRow = () => {
    const currentArray = getValues("targets");

    const updatedArray = [
      ...currentArray,
      {
        relationToBottomRow: NeighborRelationType.OR,
        operand: undefined,
        operator: undefined,
        value: undefined,
      },
    ];
    setValue("targets", updatedArray);
  };

  // remove target row
  const handleRemoveTargetRow = (index: number) => {
    const currentArray = getValues("targets");
    const updatedArray = currentArray.filter((_, i) => i !== index);
    setValue("targets", updatedArray);
  };

  // copy target row
  const handleCopyRow = (index: number, row: TargetRow) => {
    const currentArray = getValues("targets");
    const updatedArray = [
      ...currentArray.slice(0, index),
      row,
      ...currentArray.slice(index),
    ];
    setValue("targets", updatedArray);
  };

  /**-- MarketPlaceCounter state --*/

  /**-- Handle step form submit --*/
  const handleStepFormSubmit: SubmitHandler<ApplyToFormState> = (data) => {
    console.log("step submit data:", JSON.stringify(data));
  };

  console.log("target values", watch("targets"), watch("matchType"));
  return (
    <form
      className="w-full flex flex-col gap-6"
      onSubmit={handleSubmit(handleStepFormSubmit)}
    >
      {/*--  rule name input --*/}
      <div className="w-full flex-col flex gap-2">
        <label className="text-[#111827] !text-[16px] !font-bold leading-6">
          Rule Name
        </label>
        <Controller
          name="ruleName"
          control={control}
          rules={{
            required: "Rule name is required....",
            maxLength: {
              value: 256,
              message: "Maximum length is 256 characters",
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <div className="w-full flex flex-col">
                <input
                  {...field}
                  className={cn(
                    "w-full px-3 py-2 border-[1px] outline-none rounded-md leading-6 text-[16px] text-[#171717] !font-medium ",
                    fieldState?.error
                      ? "border-red-500"
                      : "hover:border-[#237804] focus:border-[#237804]"
                  )}
                  placeholder="Enter a rule name..."
                />
                {fieldState?.error && (
                  <ErrorLabel message={fieldState?.error?.message} />
                )}
              </div>
            );
          }}
        />
      </div>

      {/*-- marketplace option inputs --*/}
      <div className="w-full flex flex-col gap-2">
        <h4 className="text-[#111827] text-[16px] !font-bold leading-6">
          Marketplace
        </h4>
        {/* select options */}
        <div className="flex flex-wrap gap-y-3 gap-x-20">
          {/* market place selection options */}
          {marketplaces.map((data, idx) => {
            return (
              <MarketPlaceSelectionOptions
                key={idx}
                marketplace={data}
                control={control}
              />
            );
          })}
        </div>
      </div>

      {/* applicable targets */}
      <div className="w-full flex flex-col gap-2">
        <h4 className="capitalize text-[#111827] text-[16px] !font-bold leading-6">
          applicable targets
        </h4>
        {/* application target rows */}
        <div className="flex flex-col">
          {/* row one */}
          {watch("targets").map((d, idx) => (
            <ApplicableTargetsRow
              key={idx}
              currentIndex={idx}
              totalLength={watch("targets").length}
              relationToTopNeighbor={
                idx === 0
                  ? NeighborRelationType.NONE
                  : watch("targets")[idx - 1].relationToBottomRow
              }
              relationToBottomNeighbor={d.relationToBottomRow}
              handleDeleteRow={handleRemoveTargetRow}
              handleCopyRow={handleCopyRow}
              control={control}
              getValues={getValues}
            />
          ))}

          {/* add row action */}
          <div className="flex justify-start items-center mt-3">
            <button
              type="button"
              className="flex items-center justify-center gap-1 text-[#9CA3AF] hover:text-[#237804] text-sm !font-medium leading-5 transition-all duration-200"
              onClick={handleAddTargetRow}
            >
              <span>
                <MdAddCircleOutline className="h-5 w-5" />
              </span>
              <span>Add Row</span>
            </button>
          </div>
        </div>
      </div>

      {/* match type inputs */}
      <MatchTypeInputs
        control={control}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        autoCampaign={autoCampaign}
        manualCampaignKeywordTargeting={manualCampaignKeywordTargeting}
        manualCampaignProductTargeting={manualCampaignProductTargeting}
      />
      {/* next action button */}
      <div className="flex justify-end items-center">
        <div>
          <SubmitButton
            buttonText="Next"
            buttonType="submit"
            className="px-10"
            loadingClassName="px-10"
            disabledClassName="px-10"
            isDisabled={!isValid}
            isLoading={false}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNewAutomationRuleApplyTo;
