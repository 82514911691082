import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface StepCounter {
    activeStep: number,
    completeStep: { [key: number]: boolean },
    totalStep: number
}

const initialState: StepCounter = {
    activeStep: Number(localStorage.getItem("onboarding-step")) || 0,
    completeStep: {},
    totalStep: 3
}

export const stepCounter = createSlice({
    name: "stepCounter",
    initialState,
    reducers: {
        setStep: (state, action: PayloadAction<number>) => {
            state.activeStep = action.payload
        },
        setStepComplete: (state, action: PayloadAction<number>) => {
            state.completeStep = {
                ...state.completeStep,
                [action.payload]: true
            }
        }
    }
})

export const { setStep, setStepComplete } = stepCounter.actions;
export default stepCounter.reducer
