import { Avatar, Menu, Tooltip } from "@mui/material";
import { useState } from "react";
import { LuChevronDown } from "react-icons/lu";
import { Link } from "react-router-dom";
import { TbLogout2, TbUserCircle } from "react-icons/tb";
import { PiCrownSimpleFill, PiLinkBold } from "react-icons/pi";
import { RiFileList3Line } from "react-icons/ri";
import { MdAddCircleOutline } from "react-icons/md";
import logout from "utils/logout";
import { useLoggedInUserQuery } from "features/user/userSlice";

const ProfileManue = () => {
  /**--React Hooks--**/
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // RTK: query user data fetching
  const { data } = useLoggedInUserQuery({});
  return (
    <div>
      <Tooltip title="My Profile">
        <div
          onClick={handleClick}
          className="flex items-center gap-3 cursor-pointer"
        >
          <Avatar alt="profile" src={data?.result?.image} />
          <p className="font-semibold text-am-primary text-base">
            {data?.result?.name}
          </p>
          <div className="bg-am-base h-6 w-6 rounded-full flex justify-center items-center">
            <LuChevronDown className="text-base" />
          </div>
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiPaper-root": {
            width: "200px",
          },
        }}
      >
        <div className="py-0.5 px-1.5 grid content-center divide-y divide-slate-200">
          <Link
            to="/account/profile"
            className="text-current hover:no-underline"
          >
            <div className="text-slate-700 flex items-center gap-2 hover:bg-am-base px-1.5 py-2">
              <div>
                <TbUserCircle className="text-2xl" />
              </div>
              <p className="font-medium">My Profile</p>
            </div>
          </Link>
          <Link to="/account/billing-plan" className="text-current hover:no-underline" state={{ showPlanUpgradeModal: true }}>
            <div className="text-slate-700 flex items-center gap-2 hover:bg-am-base px-1.5 py-2">
              <div className="min-h-5 min-w-5 rounded-full bg-am-primary flex justify-center items-center text-white p-1">
                <PiCrownSimpleFill />
              </div>
              <p className="font-medium text-am-primary">Upgrade to PRO</p>
            </div>
          </Link>
          <Link to="/account/billing-plan" className="text-current hover:no-underline">
            <div className="text-slate-700 flex items-center gap-2 hover:bg-am-base px-1.5 py-2">
              <div>
                <RiFileList3Line className="text-xl rotate-180" />
              </div>
              <p className="font-medium">Billing & Plan</p>
            </div>
          </Link>
          <Link to="/account/amazon-connect" className="text-current hover:no-underline">
            <div className="text-slate-700 flex items-center gap-2 hover:bg-am-base px-1.5 py-2">
              <div>
                <PiLinkBold className="text-xl" />
              </div>
              <p className="font-medium">Amazon Connection</p>
            </div>
          </Link>
          <Link to="#" className="text-current hover:no-underline">
            <div className="text-slate-700 flex items-center gap-2 hover:bg-am-base px-1.5 py-2">
              <div>
                <MdAddCircleOutline className="text-xl" />
              </div>
              <p className="font-medium">Add Account</p>
            </div>
          </Link>
          <button onClick={logout} className="text-current hover:no-underline">
            <div className="text-slate-700 flex items-center gap-2 hover:bg-am-base px-1.5 py-2">
              <div>
                <TbLogout2 className="text-xl text-red-600" />
              </div>
              <p className="font-medium text-red-600">Logout</p>
            </div>
          </button>
        </div>
      </Menu>
    </div>
  );
};

export default ProfileManue;
