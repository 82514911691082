
import { Bounce, toast, ToastOptions } from "react-toastify";

interface Props {
  type: "error" | "success";
  message: string;
}

const NotifyMessage = ({ type, message }: Props) => {
  let attb: ToastOptions = {
    position: "bottom-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    theme: "light",
    transition: Bounce,
  };
  if (type === "error") {
    toast.error(message, attb);
  } else if (type === "success") {
    toast.success(message, attb);
  } else {
    toast.warning(message, attb);
  }

};

export default NotifyMessage;
