import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import { Control, Controller } from "react-hook-form";
import { condition1Data, condition2Data } from "./data";
import { ThenFormState } from "./AddNewAutomationRuleThen.types";
import { FC } from "react";

interface Props {
  currentIndex: number;
  control: Control<ThenFormState, any>;
}

const ChangePlacementRowInputs: FC<Props> = ({ control, currentIndex }) => {
  return (
    <>
      <div className="col-span-2">
        <Controller
          name={`actions.${currentIndex}.condition1`}
          control={control}
          render={({ field }) => (
            <CustomSelectOption
              placeholder=""
              options={condition1Data}
              field={field}
              className="col-span-2"
              sx={{
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "8px 12px !important",
                },
              }}
            />
          )}
        />
      </div>

      <div className="col-span-2">
        <Controller
          name={`actions.${currentIndex}.condition2`}
          control={control}
          render={({ field }) => (
            <CustomSelectOption
              placeholder=""
              options={condition2Data}
              field={field}
              className="col-span-2"
              sx={{
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "8px 12px !important",
                },
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export default ChangePlacementRowInputs;
