import logo from "../../assets/brand/logo.svg";
import emailLogo from "../../assets/icons/email_custom.svg";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { useActiveAccountMutation } from "features/auth/authSlice";
// import InfoBox from "components/infoBox/InfoBox";

const EmailVerificationSuccess = () => {
  // NAVIGATe: - react-router-dom utils
  const navigate = useNavigate();
  const location = useLocation();

  // parse query parameters
  const queryParms = new URLSearchParams(location.search);
  const token = queryParms.get("token");

  // coutdown
  const [second, setSecond] = useState(3);

  // activation error
  const [activationError, setActivationError] = useState(true);

  // RTK: -- mutation
  const [activateAccount] = useActiveAccountMutation();

  // handle activate account
  const handleActivateAccount = async () => {
    try {
      const res = await activateAccount({
        token,
      });
      if (res?.data?.success) {
        setActivationError(false);
      } else {
        setActivationError(true);
      }
    } catch (error) {
      console.log(error);
      setActivationError(true);
    }
  };

  useEffect(() => {
    // call for acount activate
    handleActivateAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const counter = setInterval(() => {
      if (!activationError) {
        setSecond((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(counter);
  }, [activationError]);

  useEffect(() => {
    if (second === 0) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second]);

  return (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-32" />
        </div>
        <hr className="my-0 !border-[#D4D4D4]" />
        <div className="flex flex-col items-center justify-center gap-2">
          <img src={emailLogo} alt="logo" className="w-[100px]" />
          <h4 className="font-medium text-xl leading-8 text-center text-[#171717]">
            You successfully activated your email
          </h4>
        </div>
        <div className="flex max-w-[340px] flex-col gap-4">
          {/* default success */}
          {!activationError ? (
            <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
              Redirecting back to the login page in {second} second
            </p>
          ) : (
            <>
              {/* error state  */}
              <p className="m-0 text-[16px] font-medium leading-6 text-[#171717]">
                Not automatically redirected? Click this
                <a
                  href="/"
                  className=" inline-block font-bold !text-[#237804] ml-1 underline"
                >
                  Login
                </a>
                to redirected back.
              </p>
              {/* info */}
              {/* <InfoBox
                type="warning"
                message="Sorry for the trouble, we unable to prosess your request. Please try again."
              /> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationSuccess;
