import deleteIcon from "assets/icons/delete_icon.svg";

const SelectedCategoryProductRow = () => {
  return (
    <div className="grid grid-cols-4">
      <div className=" col-span-2 text-[#111827] font-normal leading-6 text-[16px]">
        Coloring Books for Grown-Ups
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px]">US</div>
      <div className="text-[#9CA3AF] font-normal leading-6 text-[16px] text-center">
        <button type="button">
          <img src={deleteIcon} className="w-[25px] " alt="delete" />
        </button>
      </div>
    </div>
  );
};

export default SelectedCategoryProductRow;
