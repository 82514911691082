import * as React from "react";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import cn from "utils/cn";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: "#fff",
    maxHeight: 280,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#aaa",
    },
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid #eaecef`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
      ...theme.applyStyles("dark", {
        borderBottom: `1px solid #30363d`,
      }),
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#1c2128",
    }),
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "absolute",
  },
}));

const PopperComponent =
  (
    hasCustom: boolean,
    customColumnText: string,
    setOpenCustomOption: React.Dispatch<React.SetStateAction<boolean>>,
    openCustomOption: boolean,
    customOptionValue: any,
    setCustomOptionValue: React.Dispatch<React.SetStateAction<any>>
  ) =>
  (props: PopperComponentProps) => {
    const { disablePortal, anchorEl, open, ...other } = props;

    return (
      <div className="w-full ">
        <StyledAutocompletePopper {...other} />
        {hasCustom && (
          <div className="relative">
            <button
              className="px-4 py-2 flex items-center justify-between !font-bold leading-6 text-[#237804] text-[16px] w-full"
              onClick={() => setOpenCustomOption(!openCustomOption)}
            >
              {customColumnText}
              <IoIosArrowForward className="h-5 w-5" />
            </button>
            {openCustomOption && (
              <div className="absolute left-[95%] bottom-0 bg-white py-2 inline-flex items-center shadow-lg z-50 p-2 rounded-[4px] gap-2">
                Last{" "}
                <input
                  onKeyDown={(e) => {
                    // @TODO if need of key action on custom option add here
                    // EX: enter key press
                    if (e.key === "Enter") {
                      console.log("enter key pressed");
                    }
                  }}
                  type="text"
                  className="outline-none border-[1px] w-[50px] rounded-[4px] p-2"
                  value={customOptionValue}
                  autoFocus={openCustomOption || customOptionValue}
                  onChange={(e) => {
                    setCustomOptionValue(e.target.value);
                  }}
                />{" "}
                Days
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  "& input": {
    borderRadius: 4,
    backgroundColor: "#fff",
    border: `1px solid #D1D5DB`,
    padding: 8,
    paddingRight: 35,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontSize: 14,
    "&:focus": {
      borderColor: "#9CA3AF",
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#0d1117",
      border: `1px solid #eaecef`,
    }),
  },
  ...theme.applyStyles("dark", {
    borderBottom: `1px solid #eaecef`,
  }),
}));

export default function CustomSelectOptionWithFilter({
  handleSelect,
  options,
  placeholder,
  className,
  inputClass,
  field,
  searchPlaceholder,
  hasCustom,
}: {
  handleSelect?: (option) => void;
  options: OptionType[];
  placeholder: string;
  className?: string;
  inputClass?: string;
  field: any;
  searchPlaceholder?: string;
  hasCustom?: boolean;
}) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [openCustomOption, setOpenCustomOption] =
    React.useState<boolean>(false);

  const [customOptionValue, setCustomOptionValue] = React.useState<any>();

  React.useEffect(() => {
    if (customOptionValue) {
      field.onChange(`Last ${customOptionValue} Days`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customOptionValue]);

  const handleSelectOption = (option: OptionType) => {
    field.onChange(option.value);
    setOpen(false);
  };

  const autocompleteRef = React.useRef<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setTimeout(() => {
      const inputElement = autocompleteRef.current?.querySelector("input");
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  };

  const handleClose = () => {
    setOpen(false);
    setCustomOptionValue("");
    setOpenCustomOption(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={cn("w-full relative", className)}>
        <div className="w-full relative">
          <button
            type="button"
            className={cn(
              "line-clamp-1 capitalize w-full flex text-sm justify-between items-center border-[1px] border-[#D1D5DB] py-2 px-3 rounded-[4px] font-normal text-[16px] leading-6",
              inputClass,
              open
                ? "border-[#237804] bg-[#EAFFEC] text-[#237804] !font-bold"
                : field.value
                ? "text-[#171717]"
                : "text-[#9CA3AF]"
            )}
            onClick={handleClick}
          >
            {field.value || placeholder}
          </button>
          <span
            className={cn(
              "absolute top-[50%] right-0 pr-2 !font-bold translate-y-[-50%]",
              open ? "text-[#237804]" : "text-[#6B7280]"
            )}
          >
            {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </span>
        </div>
        <div
          className={cn(
            open ? "block absolute left-0 top-[100%] w-full z-50" : "hidden"
          )}
        >
          <div className="bg-white shadow-md rounded-[4px]">
            <Autocomplete
              ref={autocompleteRef}
              open={true}
              onClose={(event, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              disableCloseOnSelect
              renderTags={() => null}
              noOptionsText="No options info"
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.value}
                  onClick={() => handleSelectOption(option)}
                  className="text-sm font-medium leading-6 text-[#4B5563] mx-3.5 py-2 border-b border-[#F3F4F6] cursor-pointer"
                >
                  {option.name}
                </li>
              )}
              options={options}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <div className="w-full relative">
                  <StyledInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    placeholder={
                      searchPlaceholder ? searchPlaceholder : "Search..."
                    }
                  />
                  <span className="flex items-center justify-center absolute right-5 top-0 h-full ">
                    <CiSearch className="w-6 h-6" />
                  </span>
                </div>
              )}
              slots={{
                popper: PopperComponent(
                  hasCustom!,
                  "Custom Period",
                  setOpenCustomOption,
                  openCustomOption,
                  customOptionValue,
                  setCustomOptionValue
                ),
              }}
            />
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export interface OptionType {
  name: string;
  value: string;
}
