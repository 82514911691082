import { apiSlice } from "../api/apiSlice";

export const authSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Sign up with credential
        signup: builder.mutation({
            query: (data) => ({
                url: `/Users/Register`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["loggedInUser"]
        }),

        // Resend active email
        resendActiveEmail: builder.mutation({
            query: (data) => ({
                url: `/Users/ResendActivationEmail`,
                method: "POST",
                body: data
            })
        }),
        // Active account
        activeAccount: builder.mutation({
            query: (data) => ({
                url: `/Users/Activate`,
                method: "POST",
                body: data
            })
        }),
        // 0auth login: includes google, facebook ...
        oAuthLogin: builder.mutation({
            query: (data) => ({
                url: `/Users/OAuthLogin`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["loggedInUser"]
        }),
        // credential login: login user on manual inputs
        credentialLogin: builder.mutation({
            query: (data) => ({
                url: `/Users/Login`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["loggedInUser"]
        }),

        // forgot password: need email and redirect url to call
        forgotPassword: builder.mutation({
            query: (data) => ({
                url: `/Users/ForgotPassword`,
                method: "POST",
                body: data
            })
        }),

        // reset password: need password and token
        resetPassword: builder.mutation({
            query: (data) => ({
                url: `/Users/ResetPassword`,
                method: "POST",
                body: data
            })
        }) 
    }),
});

export const {
    useSignupMutation,
    useOAuthLoginMutation,
    useCredentialLoginMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useActiveAccountMutation,
    useResendActiveEmailMutation
} = authSlice;