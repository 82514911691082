import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { ApplyToFormState } from "./AddNewAutomationRuleApplyTo.types";
import { FC } from "react";

interface Props {
  control: Control<ApplyToFormState, any>;
  watch: UseFormWatch<ApplyToFormState>;
  setValue: UseFormSetValue<ApplyToFormState>;
  getValues: UseFormGetValues<ApplyToFormState>;
  autoCampaign: string[];
  manualCampaignKeywordTargeting: string[];
  manualCampaignProductTargeting: string[];
}

const MatchTypeInputs: FC<Props> = ({
  control,
  watch,
  setValue,
  getValues,
  autoCampaign,
  manualCampaignKeywordTargeting,
  manualCampaignProductTargeting,
}) => {
  /**-- Variable --*/
  // check auto campagin all selected status
  const allAutoCampaignSelected =
    getValues("matchType.autoCampaign")?.length ===
    getValues("matchType.totalAutoCampaignSelected");

  // check manual campagin all selected status
  const allManualCampaignSelected =
    getValues("matchType.manualCampaign.product")?.length +
      getValues("matchType.manualCampaign.keyword")?.length ===
    getValues("matchType.totalManualCampaignSelected");

  /**-- Handlers --*/
  // handle all select auto campagin
  const handleSelectAllAutoCampaign = () => {
    autoCampaign.forEach((option, idx) => {
      setValue(`matchType.autoCampaign.${idx}.${option}`, true);
    });
    setValue(
      "matchType.totalAutoCampaignSelected",
      getValues("matchType.autoCampaign")?.length
    );
  };

  // handle all unselect auto campagin
  const handleUnselectAllAutoCampaign = () => {
    autoCampaign.forEach((option, idx) => {
      setValue(`matchType.autoCampaign.${idx}.${option}`, false);
    });
    setValue("matchType.totalAutoCampaignSelected", 0);
  };

  // handle all select manual campagin
  const handleSelectAllManualCampaign = () => {
    manualCampaignProductTargeting.forEach((option, idx) => {
      setValue(`matchType.manualCampaign.product.${idx}.${option}`, true);
    });
    manualCampaignKeywordTargeting.forEach((option, idx) => {
      setValue(`matchType.manualCampaign.keyword.${idx}.${option}`, true);
    });
    setValue(
      "matchType.totalManualCampaignSelected",
      (getValues("matchType.manualCampaign.keyword")?.length || 0) +
        (getValues("matchType.manualCampaign.product")?.length || 0)
    );
  };

  // handle all unselect auto campagin
  const handleUnselectAllManualCampaign = () => {
    manualCampaignProductTargeting.forEach((option, idx) => {
      setValue(`matchType.manualCampaign.product.${idx}.${option}`, false);
    });
    manualCampaignKeywordTargeting.forEach((option, idx) => {
      setValue(`matchType.manualCampaign.keyword.${idx}.${option}`, false);
    });
    setValue("matchType.totalManualCampaignSelected", 0);
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <h4 className="capitalize text-[#111827] text-[16px] !font-bold leading-6">
        match type
      </h4>
      {/* select input grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        {/* select auto campaign */}
        <div className="py-4 px-3 bg-[#F9FAFB] rounded-lg flex flex-col gap-3">
          {/* header */}
          <div className="flex items-center justify-between">
            <h4 className="capitalize text-[#111827] text-[16px] !font-medium leading-6">
              Auto campaign
            </h4>
            {/* action button */}
            {allAutoCampaignSelected ? (
              <button
                type="button"
                className="text-[#DC2626] !font-medium leading-5 text-sm"
                onClick={handleUnselectAllAutoCampaign}
              >
                Deselect All
              </button>
            ) : (
              <button
                type="button"
                className="text-[#237804] !font-medium leading-5 text-sm"
                onClick={handleSelectAllAutoCampaign}
              >
                Select All
              </button>
            )}
          </div>
          {/* select options */}
          <div className="flex flex-col gap-3">
            {autoCampaign.map((option, idx) => (
              <div key={idx} className="flex items-center">
                {/* option */}
                <Controller
                  name={`matchType.autoCampaign.${idx}.${option}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="flex items-center justify-start gap-2">
                      <input
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          onChange(isChecked);
                          let prev =
                            getValues("matchType.totalAutoCampaignSelected") ||
                            0;
                          setValue(
                            "matchType.totalAutoCampaignSelected",
                            prev + (isChecked ? 1 : -1)
                          );
                        }}
                        type="checkbox"
                        checked={value}
                        className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                      />
                      <label className="text-[#374151] text-[16px] !font-medium">
                        {option}
                      </label>
                    </div>
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        {/* select manual campaign */}
        <div className="py-4 px-3 bg-[#F9FAFB] rounded-lg flex flex-col gap-3">
          {/* header */}
          <div className="flex items-center justify-between">
            <h4 className="capitalize text-[#111827] text-[16px] !font-medium leading-6">
              Manual Campaign
            </h4>
            {/* action button */}
            {allManualCampaignSelected ? (
              <button
                type="button"
                className="text-[#DC2626] !font-medium leading-5 text-sm"
                onClick={handleUnselectAllManualCampaign}
              >
                Deselect All
              </button>
            ) : (
              <button
                type="button"
                className="text-[#237804] !font-medium leading-5 text-sm"
                onClick={handleSelectAllManualCampaign}
              >
                Select All
              </button>
            )}
          </div>
          {/* select options */}
          <div className="grid grid-cols-1 lg:grid-cols-2">
            {/* keyword Targetting */}
            <div className="flex flex-col gap-3">
              <h5 className="leading-6 text-[16px] !font-medium text-[#737373]">
                Keyword Targetting
              </h5>
              {/* select options */}
              <div className="flex flex-col gap-3">
                {manualCampaignKeywordTargeting.map((option, idx) => (
                  <div key={idx}>
                    {/* option */}
                    <Controller
                      name={`matchType.manualCampaign.keyword.${idx}.${option}`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="flex items-center justify-start gap-2">
                          <input
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              onChange(isChecked);
                              let prev =
                                getValues(
                                  "matchType.totalManualCampaignSelected"
                                ) || 0;
                              setValue(
                                "matchType.totalManualCampaignSelected",
                                prev + (isChecked ? 1 : -1)
                              );
                            }}
                            checked={value}
                            type="checkbox"
                            className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                          />
                          <label className="text-[#374151] text-[16px] !font-medium">
                            {option}
                          </label>
                        </div>
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* product targetting */}
            <div className="flex flex-col gap-3">
              <h5 className="leading-6 text-[16px] !font-medium text-[#737373]">
                Product Targetting
              </h5>
              {/* select options */}
              <div className="flex flex-col gap-3">
                {manualCampaignProductTargeting.map((option, idx) => (
                  <div key={idx}>
                    {/* option */}
                    <Controller
                      name={`matchType.manualCampaign.product.${idx}.${option}`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="flex items-center justify-start gap-2">
                          <input
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              onChange(isChecked);
                              let prev =
                                getValues(
                                  "matchType.totalManualCampaignSelected"
                                ) || 0;
                              setValue(
                                "matchType.totalManualCampaignSelected",
                                prev + (isChecked ? 1 : -1)
                              );
                            }}
                            checked={value}
                            type="checkbox"
                            className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                          />
                          <label className="text-[#374151] text-[16px] !font-medium">
                            {option}
                          </label>
                        </div>
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchTypeInputs;
