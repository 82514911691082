import { apiSlice } from "features/api/apiSlice";

export const dashboardSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardLastSync: builder.query({
            query: () => ({
                url: '/Dashboard/LastSync',
                method: 'GET',
            }),
            providesTags: ['getDashboardLastSync'],
        })
    })
})

export const {
    useGetDashboardLastSyncQuery
} = dashboardSlice;