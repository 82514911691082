import React from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import cn from '../../utils/cn';

import { FcGoogle } from "react-icons/fc";
import { getGoogleUserData } from 'utils/getGoogleUserData';

interface Props {
    className?: string,
    onError?: (res) => void,
    onSuccess?: (err) => void,
    text?: string
}


const SignInWithGoogle: React.FC<Props> = ({ text = "Continue with Google", className, onError, onSuccess }) => {

    const login = useGoogleLogin({
        onSuccess: async (response) => {
            if (onSuccess) {
                const { access_token } = response;
                const userData: any = await getGoogleUserData(access_token);

                let apiData = userData
                if (userData) {
                    const { authToken, email, firstName, id, lastName } = userData;
                    apiData = {
                        ...response,
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        provider: "google",
                        authToken: authToken,
                        idToken: id,
                    };
                }
                onSuccess(apiData)
            }
        },
        onError: err => {
            if (onError) {
                onError(err)
            }
        }
    });

    return (
        <>
            <button onClick={() => {
                login()
            }}
                className={cn("shadow-none py-2.5 px-20 rounded-[4px] border-[1px] !border-[#737373] text-[16px] flex gap-2 items-center justify-center", className)}
            >
                <FcGoogle className='h-5 w-5' /> <span className='text-[#171717] !font-bold'>{text}</span>
            </button>
        </>
    )
}

export default SignInWithGoogle