import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AutomateRuleAddStepCounter {
    activeStep: number,
    completeStep: { [key: number]: boolean },
    totalStep: number
}

const initialState: AutomateRuleAddStepCounter = {
    activeStep: Number(localStorage.getItem("onboarding-step")) || 0,
    completeStep: {},
    totalStep: 4
}

export const automateRuleAddStepCounter = createSlice({
    name: "stepCounter",
    initialState,
    reducers: {
        setAutomateRuleAddActiveStep: (state, action: PayloadAction<number>) => {
            state.activeStep = action.payload
        },
        setAutomateRuleAddStepComplete: (state, action: PayloadAction<number>) => {
            state.completeStep = {
                ...state.completeStep,
                [action.payload]: true
            }
        }
    }
})

export const { setAutomateRuleAddActiveStep, setAutomateRuleAddStepComplete } = automateRuleAddStepCounter.actions;
export default automateRuleAddStepCounter.reducer
