import { Box, ClickAwayListener, Fade, Popper } from "@mui/material";
import React, { FC } from "react";
import cn from "utils/cn";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const StyledPopperContent = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  maxHeight: 250,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
  padding: 0,
}));

export interface OptionType {
  name: string;
  value: string;
}

interface Props {
  placeholder?: string;
  field: any;
  options: OptionType[];
}

const CustomSelectOptionWithMultiple: FC<Props> = ({
  placeholder,
  field,
  options,
}) => {
  const [optionOpen, setOptionOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const parentRef = React.useRef<null | HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOptionOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = optionOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOptionOpen(false);
      }}
    >
      <div className="w-full " ref={parentRef}>
        <button
          className={cn(
            "w-full flex items-center justify-between rounded-[4px] border-[1px] py-2 px-3 border-[#D1D5DB] text-sm capitalize leading-5",
            optionOpen &&
              "border-[#237804] bg-[#EAFFEC] text-[#237804] !font-bold"
          )}
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          <span>{field?.value?.value || placeholder}</span>
          <span
            className={cn(optionOpen ? "text-[#237804]" : "text-[#6B7280]")}
          >
            {optionOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </span>
        </button>
        <Popper
          id={id}
          open={optionOpen}
          anchorEl={anchorEl}
          transition
          sx={{
            width: parentRef?.current?.offsetWidth,
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  boxShadow:
                    "1px 6px 15px -3px rgba(0, 0, 0, 0.10), 0px -4px 6px -2px rgba(0, 0, 0, 0.05)",
                  paddingX: "8px",
                  bgcolor: "background.paper",
                  overflow: "hidden",
                }}
              >
                <StyledPopperContent>
                  {options.map((option, idx) => (
                    <Option
                      id={idx}
                      onClick={() => {
                        field?.onChange(option);
                      }}
                      data={option}
                    />
                  ))}
                </StyledPopperContent>
              </Box>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const Option = ({
  id,
  data,
  onClick,
}: {
  id: number;
  onClick: () => void;
  data: OptionType;
}) => {
  return (
    <div className="text-[#4B5563] leading-5 !font-medium text-sm flex justify-between items-center gap-3 py-3 border-t">
      <input
        type="checkbox"
        id={`option.${id}`}
        className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
        onChange={() => {
          onClick();
        }}
      />
      <label
        className="text-ellipsis line-clamp-1 grow"
        htmlFor={`option.${id}`}
      >
        {data?.name}
      </label>
    </div>
  );
};

export default CustomSelectOptionWithMultiple;
