import amazonConnectIcon from "assets/icons/amazon_connect.svg";
import { useState } from "react";
import InfoBox from "components/infoBox/InfoBox";
import { useRequestAmazonAutorizationURLMutation } from "features/onboarding/onboardingSlice";
import SubmitButton from "components/submitButton/SubmitButton";

const OnboardingAmazonConnect = () => {
  // RTK: amazon autorization URL mutation
  const [requestAmazonAutorizationURL, { isLoading, isError }] = useRequestAmazonAutorizationURLMutation();

  // STATE: error state of amazon autorization URL
  const [errorAmazonAutorization, setErrorAmazonAutorization] = useState(false);

  const handleAmazonConnect = async () => {
    try {
      const res = await requestAmazonAutorizationURL("");
      if (res?.data?.success) {
        const data = res?.data;
        window.location.href = data?.result?.connectUrl;
        setErrorAmazonAutorization(false);
      } else {
        setErrorAmazonAutorization(true);
      }
    } catch (error) {
      setErrorAmazonAutorization(true);
    }
  }

  return (
    <>
      <div className="flex flex-col">
        <h4 className="!font-medium text-[16px] leading-6 !text-[#171717]">
          Connect Amazon Account
        </h4>
        <p className="m-0 leading-5 text-sm !text-[#393939]">
          Integrate your Amazon data, empowering you to analyze and monitor key
          performance metrics.
        </p>
      </div>
      {/* step action button */}
      <div className="w-full">
        <button className="w-full py-4 px-6 border-[1px] border-[#237804] flex items-center gap-4 rounded-2xl">
          <img src={amazonConnectIcon} alt="icon" className="w-[50px]" />
          <div className="flex flex-col justify-center items-start">
            <h4 className="m-0 !text-[#111827] !font-medium text-[16px] leading-6">
              Amazon
            </h4>
            <p className="m-0 font-normal text-xs leading-5 text-[#393939]">
              Not connected
            </p>
          </div>
        </button>
      </div>
      {/* info box */}
      {isError ||
        (errorAmazonAutorization && (
          <InfoBox
            type="warning"
            message="Sorry for the trouble, we unable to link your Amazon account. Please try again."
          />
        ))}
      {/* connect amazon action button */}
      <div className="w-full">
        <SubmitButton
          onClick={handleAmazonConnect}
          buttonText="Connect Amazon"
          buttonType="button"
          isDisabled={false}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default OnboardingAmazonConnect;
