import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        // baseUrl: process.env.REACT_APP_BASE_URL,
        baseUrl: "https://staging.backend.aimosa.io",
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("userToken");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            // headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["loggedInUser", "getDashboardLastSync", "getAmazonConnections"],
    endpoints: (builder) => ({}),
});