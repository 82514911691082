/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import chromeIcon from "assets/icons/chrome_icon.svg";
import firefoxIcon from "assets/icons/firefox_icon.svg";
import { useDispatch } from "react-redux";
import {
  setStep,
  setStepComplete,
} from "features/onboardingStep/onboardingStep";
import cn from "utils/cn";
import detectBrowser from "utils/detectBrowser";
import { useNavigate } from "react-router-dom";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";
import { useSyncUserBackgroundServiceMutation } from "features/user/userSlice";

const OnboardingInstallExtension = () => {
  /**-React Router-**/
  const navigate = useNavigate();

  // Redux slice dispatch
  const dispatch = useDispatch();

  // RTK: mutation to sync user background data
  const [syncUserBackground] = useSyncUserBackgroundServiceMutation();

  // initial state
  useEffect(() => {
    dispatch(setStep(2));
    dispatch(setStepComplete(1));
    dispatch(setStepComplete(0));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [browserExtensionDetails, setBrowserExtensionDetails] = useState<{
    [key: string]: string;
  }>();

  const [extensionInstalled, setExtensionInstalled] = useState<boolean>(
    Boolean(localStorage.getItem("extensionInstalled"))
  );

  // handle extensin install
  const handleInstall = () => {
    const extensionName = "";
    const extensionversion = "";

    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;

    setBrowserExtensionDetails({
      name: extensionName,
      version: extensionversion,
    });

    localStorage.setItem("extensionDownloaded", "true");

    window.open(
      `https://chrome.google.com/webstore/detail/aimosa/fhefiglehhbhabmbenjaidfpedlnfdgg`,
      "_blank",
      `width=${viewportWidth / 2},height=600`
    );
  };

  // check extension installation
  useEffect(() => {
    setTimeout(() => {
      const extensionIdentifier = window.document.getElementById(
        "aimosa_extension_id"
      );
      if (extensionIdentifier) {
        localStorage.setItem("extensionInstalled", "installed");
        setExtensionInstalled(true);
        dispatch(setStepComplete(2));
      } else {
        setExtensionInstalled(false);
        localStorage.removeItem("extensionInstalled");
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  // detecth the browser
  const broswer = detectBrowser();

  // handle finish onboarding
  const handleFinishOnboarding = async () => {
    // Sync body
    const body = {
      daysToSync: 30,
      timeUnit: "DAILY",
    };
    try {
      // Request for sync background data
      const userToken = localStorage.getItem("userToken");

      // Create a promise for syncing user background data
      const syncPromise = syncUserBackground(body);

      // Create a promise for opening the window
      const openWindowPromise = new Promise<void>((resolve) => {
        window.open(
          `https://account.kdp.amazon.com?t=${userToken}`,
          "_blank",
          "width=800,height=400"
        );
        resolve(); // Resolve immediately after opening the window
      });

      // Wait for both promises to resolve
      await Promise.all([syncPromise, openWindowPromise]);

      // redirect to dashboard
      navigate("/");

      // if (res?.data?.success) {
      //   navigate("/"); // Redirect to dashboard
      // } else {
      //   NotifyMessage({
      //     type: "error",
      //     message: "Something went wrong, please try again",
      //   });
      // }
    } catch (error) {
      NotifyMessage({
        type: "error",
        message: "Something went wrong, please try again",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <h4 className="!font-medium text-[16px] leading-6 !text-[#171717]">
          Install Extension
        </h4>
        <p className="m-0 leading-5 text-sm !text-[#393939]">
          Get started by installing our browser extension
        </p>
      </div>

      {/* extension list block */}
      <div className="flex flex-col gap-4">
        {/* extension card */}
        {broswer === "Chrome" && (
          <BrowserExtensionCard
            browserIcon={chromeIcon}
            browserTitle="Chrome"
            href={
              "https://chrome.google.com/webstore/detail/aimosa/fhefiglehhbhabmbenjaidfpedlnfdgg"
            }
            installed={broswer === "Chrome" && extensionInstalled}
            onClick={handleInstall}
          />
        )}
        {broswer === "Firefox" && (
          <BrowserExtensionCard
            browserIcon={firefoxIcon}
            browserTitle="Firefox"
            href="/"
            onClick={() => {
              console.log("firefox extension handler");
            }}
            installed={broswer === "Firefox" && extensionInstalled}
          />
        )}
      </div>
      <p className="m-0 text-xs !font-medium leading-5 !text-[#171717]">
        With the extension installed, simply click to launch Aimosa and get up
        and running in under a minute.
      </p>
      <p className="m-0 text-xs !font-medium leading-5 !text-[#171717]">
        You don’t need to keep the browser with the extension running all the
        time. Simply check in whenever you want to review your numbers, and
        Aiomsa will stay in sync with KDP and Amazon ads. Just visit{" "}
        <a href="/" target="_blank" className="!text-[#237804] no-underline">
          aimosa.io{" "}
        </a>
        to get started
      </p>

      {/* action button */}
      <div className="flex items-center justify-end">
        <button
          type="button"
          onClick={handleFinishOnboarding}
          className={cn(
            "px-4 py-2.5  rounded-[4px] text-[16px] leading-6 font-bold flex items-center justify-center ",
            "bg-[#237804] text-white"
          )}
        >
          Sync
        </button>
      </div>
    </>
  );
};

const BrowserExtensionCard = ({
  href,
  onClick,
  browserIcon,
  browserTitle,
  installed,
}: {
  href?: string;
  onClick?: () => void;
  browserIcon?: string;
  browserTitle?: string;
  installed?: boolean;
}) => {
  return (
    <div className="flex justify-between rounded-[4px] border-[1px] border-zinc-200 py-3 px-4 bg-[#F9FAFB]">
      {/* browser details */}
      <div className="flex gap-2 items-center">
        <img src={browserIcon} alt="chrom" className="w-[50px]" />
        {/* browser title */}
        <div className="flex flex-col">
          <h4 className="m-0 text-[16px] !font-medium leading-6 !text-[#171717]">
            {browserTitle}
          </h4>
          <span className="!text-[#737373] text-xs leading-5 !font-normal">
            aimosa.io
          </span>
        </div>
      </div>
      {/* browser action */}
      <div className="flex gap-5 items-center">
        <a
          href={href}
          target="_blank"
          className="!text-[#237804] text-[16px] !font-bold leading-6 no-underline"
          rel="noreferrer"
        >
          Learn More
        </a>
        <button
          onClick={onClick}
          className={cn(
            " !font-bold py-2 border-[1px] rounded-[4px]",
            installed
              ? "!text-[#DC2626] px-6 border-[#DC2626]"
              : "!text-[#237804] px-8  border-[#237804]"
          )}
        >
          {installed ? "Remove" : "Install"}
        </button>
      </div>
    </div>
  );
};

export default OnboardingInstallExtension;
