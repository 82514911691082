export interface IfFormState {
    selectTarget: string,
    conditions: ConditionRowType[]
    timePeriod: string
}
export enum NeighborRelationType {
    AND = "and",
    OR = "or",
    NONE = "none",
}

export enum MetricsType {
    ACOS = "ACOS",
    BID = "Bid",
    CLICKS = "Clicks",
    CONVERSION_RATE = "Conversion Rate",
    CPC = "CPC",
    CTR = "CTR",
    DEFAULT_BID = "Default Bid",
    IMPRESSIONS = "Impressions",
    ORDERS = "Orders",
    ROAS = "ROAS",
    SPEND = "Spend",
    PAGE_READ = "Page Read",
    ROYALITIES = "Royalties",
    TOP_OF_SEARCH = "Top of Search",
    PRODUCT_PAGES = "Product Pages"
}

export enum OperatorType {
    GREATER_THAN = "Greater Than",
    LESS_THAN = "Less Than",
    EQUAL_TO = "Equal To",
    GREATER_THAN_OR_EQUAL_TO = "Greater Than or Equal To",
    LESS_THAN_OR_EQUAL_TO = "Less Than or Equal To",
    NOT_EQUAL_TO = "Not Equal To",
    BETWEEN_TWO_VALUES = "Between Two Values"
}

export interface ConditionRowType {
    metrics: MetricsType | undefined,
    exchangeOption: boolean,
    operator: OperatorType | undefined,
    relationToBottomRow: NeighborRelationType,
    value1: number | string,
    value2: number | string,
    sumValue: number | string,
    dynamicCondition: {
        metrics?: string,
        period?: string,
        value?: number
    }
}