import timezones from 'compact-timezone-list';
import ct from "countries-and-timezones";

export const getTimezones = <T extends { name: string, value: string }>(): T[] => {
    const timezoneSet = new Set<string>();
    const timezone: T[] = [];

    const timezoneList = timezones;

    timezoneList.forEach((zone) => {
        timezoneSet.add(`GTM ${zone?.offset.split(":")[0]}`)
    })

    timezoneSet.forEach((zone) => {
        timezone.push({
            name: zone,
            value: zone
        } as T)
    })

    return timezone
}

export const getGTMTimezone = (countryCode: string): string => {

    const country = ct.getCountry(countryCode);
    const timezone = ct.getTimezone(country?.timezones[0]!);
    return convertToGMTFormat(timezone?.utcOffset);
}

export const getCountryName = (countryCode: string): string => {
    let countryName = ct.getCountry(countryCode)?.name;

    if (!Boolean(countryName) && countryCode.toLowerCase() === "uk") {
        return "United Kingdom";
    } else {
        return countryName || "Unknown"
    }
}

function convertToGMTFormat(offset) {
    const hours = offset / 60; // Convert minutes to hours
    return `GMT${hours >= 0 ? ' +' : ''}${hours}`;
}