import logo from "assets/brand/logo.svg";

import { useForm, SubmitHandler } from "react-hook-form";

import ErrorLabel from "components/errorLabel/ErrorLabel";
import * as Yup from "yup";
import cn from "utils/cn";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useResetPasswordMutation } from "features/auth/authSlice";
import SubmitButton from "components/submitButton/SubmitButton";
import { useState } from "react";
import InfoBox from "components/infoBox/InfoBox";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormInputs {
  password: string;
  confirmPassword: string;
}

/**-Hook Form Validation Schema-**/
const schema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Please confirm your password"),
});

const SetNewPassword = () => {
  // NAVIGATe: - react-router-dom utils
  const navigate = useNavigate();
  const location = useLocation();

  // parse query parameters
  const queryParms = new URLSearchParams(location.search);
  const token = queryParms.get("token");

  // RTK: - mutation
  const [resetPassword, { isError, isLoading, isSuccess }] =
    useResetPasswordMutation();

  // STATE: - password toggole state
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetError, setResetError] = useState(false);

  // STATE: - Hook-Form state
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  // Determine: - if the button should be disabled based on form state
  const isFormValid = !isValid || !isDirty;

  // Handle: - send email
  const handleResetPassword: SubmitHandler<FormInputs> = async (data) => {
    // decorate req body

    const body = {
      password: data?.password,
      token,
    };
    try {
      const res = await resetPassword(body);
      if (res?.data?.success) {
        navigate("/forgot-password-reset-success");
      } else {
        setResetError(true);
      }
    } catch (error) {
      setResetError(true);
    }
  };

  return (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6 relative">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-32" />
        </div>
        <hr className="my-0 !bg-[#D4D4D4] !border-[#D4D4D4]" />
        <div className="w-full">
          <h3 className="text-center text-xl !font-medium !text-black leading-[30px] m-0">
            Set new Password
          </h3>
          <p className="flex text-sm items-center justify-center font-medium m-0 leading-5 text-[#171717]">
            Enter your new password and reset
          </p>
        </div>
        {/* forget password form */}
        <form
          className="flex flex-col w-full gap-3"
          onSubmit={handleSubmit(handleResetPassword)}
        >
          {/* password inputs */}
          <div className="flex flex-col">
            <div className="relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password..."
                className={cn(
                  "py-2.5 px-3.5 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] w-full",
                  "border-[1px]",
                  errors?.password ? "border-red-500" : "border-[#C6C6C6]"
                )}
                {...register("password")}
              />
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute top-[50%] translate-y-[-50%] right-5"
              >
                {showPassword ? (
                  <FaEye className="text-gray-500 text-lg" />
                ) : (
                  <FaEyeSlash className="text-gray-500 text-lg" />
                )}
              </button>
            </div>
            {errors?.password && (
              <ErrorLabel message={errors.password?.message} />
            )}
          </div>
          {/* confirm password input */}
          <div className="flex flex-col">
            <div className="relative w-full">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm password..."
                className={cn(
                  "py-2.5 px-3.5 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] w-full",
                  "border-[1px]",
                  errors?.confirmPassword
                    ? "border-red-500"
                    : "border-[#C6C6C6]"
                )}
                {...register("confirmPassword")}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
                className="absolute top-[50%] translate-y-[-50%] right-5"
              >
                {showConfirmPassword ? (
                  <FaEye className="text-gray-500 text-lg" />
                ) : (
                  <FaEyeSlash className="text-gray-500 text-lg" />
                )}
              </button>
            </div>
            {errors.confirmPassword && (
              <ErrorLabel message={errors.confirmPassword?.message} />
            )}
          </div>

          {/* info message */}
          {isError ||
            (resetError && (
              <InfoBox
                type="warning"
                message="Sorry for the trouble, we unable to prosess your request. Please try again."
              />
            ))}
          {/* login button */}
          <div className="flex flex-col w-[340px]">
            <SubmitButton
              buttonText="Reset Password"
              buttonType="submit"
              isLoading={isLoading}
              isDisabled={isFormValid}
            />
          </div>
        </form>

        {/* back to login button  */}
        <div className="flex items-center justify-center">
          <a
            href="/login"
            className="no-underline flex items-center justify-center gap-2 !text-[#171717] font-medium text-[16px] leading-6"
          >
            <FaArrowLeftLong /> Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
