import { FC } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { TargeKeyType, ThenFormState } from "./AddNewAutomationRuleThen.types";
import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import {
  targetKeyData,
  targetMatchTypeData,
  targetMatchTypeData2,
  targetStatusTypeData,
} from "./data";

interface Props {
  currentIndex: number;
  control: Control<ThenFormState, any>;
  watch: UseFormWatch<ThenFormState>;
}

const NegativeTargetRowInputs: FC<Props> = ({
  watch,
  control,
  currentIndex,
}) => {
  return (
    <>
      <div className="col-span-2">
        <Controller
          name={`actions.${currentIndex}.targetKey`}
          control={control}
          render={({ field }) => (
            <CustomSelectOption
              placeholder={""}
              options={targetKeyData}
              field={field}
              sx={{
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "8px 12px !important",
                },
              }}
            />
          )}
        />
      </div>
      <div className="col-span-3">
        <Controller
          name={`actions.${currentIndex}.targetKeyMatch`}
          control={control}
          render={({ field }) => (
            <CustomSelectOption
              placeholder={""}
              options={
                watch(`actions.${currentIndex}.targetKey`) === TargeKeyType.ASIN
                  ? targetMatchTypeData2
                  : targetMatchTypeData
              }
              field={field}
              sx={{
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "8px 12px !important",
                },
              }}
            />
          )}
        />
      </div>
      <div className="col-span-3 flex gap-3 items-center">
        <span className="text-[#374151] !font-normal leading-8 text-[16px]">
          Apply to
        </span>
        <div className="grow">
          <Controller
            name={`actions.${currentIndex}.targetStatus`}
            control={control}
            render={({ field }) => (
              <CustomSelectOption
                placeholder={""}
                options={targetStatusTypeData}
                field={field}
                sx={{
                  "& .MuiSelect-select.MuiSelect-outlined": {
                    padding: "8px 12px !important",
                  },
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default NegativeTargetRowInputs;
