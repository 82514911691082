import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ConditionRowType,
  IfFormState,
  NeighborRelationType,
} from "./AddNewAutomationRuleIf.types";

import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import { targetData, timePeriodData } from "./data";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  setAutomateRuleAddActiveStep,
  // setAutomateRuleAddStepComplete,
} from "features/automateRuleAdd/automateRuleAddStateSlice";
import ConditionRow from "./ConditionRow";
import { MdAddCircleOutline } from "react-icons/md";
import CustomSelectOptionWithFilter from "components/CustomSelectOptionWithFilter/CustomSelectOptionWithFilter";
import SubmitButton from "components/submitButton/SubmitButton";
import { useNavigate } from "react-router";

const AddNewAutomationRuleIf = () => {
  /**-React Router-**/
  const navigate = useNavigate();

  /**-- Redux hook --*/
  const dispatch = useDispatch();

  /**-- Hook Form States */
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { isValid },
  } = useForm<IfFormState>({
    mode: "onChange",
    defaultValues: {
      selectTarget: "",
      conditions: [
        {
          metrics: undefined,
          operator: undefined,
          sumValue: "",
          value1: "",
          value2: "",
          exchangeOption: false,
          dynamicCondition: {},
          relationToBottomRow: NeighborRelationType.NONE,
        },
      ],
      timePeriod: "",
    },
  });

  /**-- useEffect --*/
  // set step complete state status
  useEffect(() => {
    dispatch(setAutomateRuleAddActiveStep(1));
    // dispatch(setAutomateRuleAddStepComplete(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**-- Handlers --*/
  // handle stepform submit
  const handleStepFormSubmit: SubmitHandler<IfFormState> = async (data) => {
    console.log(data);
  };

  // handleAddConditionRow
  const handleAddConditionRow = () => {
    const currentArray = getValues("conditions");

    const updatedArray = [
      ...currentArray,
      {
        metrics: undefined,
        operator: undefined,
        sumValue: "",
        value1: "",
        value2: "",
        exchangeOption: false,
        dynamicCondition: {},
        relationToBottomRow: NeighborRelationType.OR,
      },
    ];
    setValue("conditions", updatedArray as ConditionRowType[]);
  };

  // remove target row
  const handleRemoveTargetRow = (index: number) => {
    const currentArray = getValues("conditions");
    const updatedArray = currentArray.filter((_, i) => i !== index);
    setValue("conditions", updatedArray);
  };

  // copy target row
  const handleCopyRow = (index: number, row: ConditionRowType) => {
    const currentArray = getValues("conditions");
    const updatedArray = [
      ...currentArray.slice(0, index),
      row,
      ...currentArray.slice(index),
    ];
    setValue("conditions", updatedArray);
  };

  console.log(
    "form data",
    watch("selectTarget"),
    watch("conditions"),
    watch("timePeriod")
  );
  return (
    <form
      className="w-full flex flex-col gap-6"
      onSubmit={handleSubmit(handleStepFormSubmit)}
    >
      <div className="flex w-full">
        <Controller
          name="selectTarget"
          control={control}
          rules={{ required: "Taget is required" }}
          render={({ field }) => (
            <CustomSelectOption
              options={targetData}
              placeholder={"Select Target"}
              field={field}
              sx={{
                width: "100%",
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "8px 14px !important",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            />
          )}
        />
      </div>
      {/* condition row inputs block */}
      <div className="w-full flex flex-col gap-2">
        <h4 className="text-[#111827] text-[16px] !font-bold leading-6">
          Condition
        </h4>
        <div className="flex flex-col">
          {watch("conditions")?.map((row, idx) => (
            <ConditionRow
              key={idx}
              control={control}
              currentIndex={idx}
              getValues={getValues}
              handleCopyRow={handleCopyRow}
              handleDeleteRow={handleRemoveTargetRow}
              totalLength={watch("conditions").length}
              relationToBottomNeighbor={row?.relationToBottomRow}
              relationToTopNeighbor={
                idx === 0
                  ? NeighborRelationType.NONE
                  : watch("conditions")[idx - 1].relationToBottomRow
              }
              setValue={setValue}
            />
          ))}
          {/* add row action */}
          <div className="flex justify-start items-center mt-3">
            <button
              type="button"
              className="flex items-center justify-center gap-1 text-[#9CA3AF] hover:text-[#237804] text-sm !font-medium leading-5 transition-all duration-200"
              onClick={handleAddConditionRow}
            >
              <span>
                <MdAddCircleOutline className="h-5 w-5" />
              </span>
              <span>Add Condition</span>
            </button>
          </div>
        </div>
      </div>

      {/* period input block */}
      <div className="w-full flex flex-col gap-2">
        <h4 className="text-[#111827] text-[16px] !font-bold leading-6">
          Period
        </h4>
        <div className="inline-flex">
          <div className="w-[250px]">
            <Controller
              name="timePeriod"
              control={control}
              rules={{
                required: "Time period is required",
              }}
              render={({ field }) => (
                <CustomSelectOptionWithFilter
                  placeholder={"Select Metrics"}
                  searchPlaceholder="Search metrics..."
                  options={timePeriodData}
                  field={field}
                  hasCustom={true}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* form action button */}
      <div className="flex items-center justify-end gap-5">
        <button
          type="button"
          className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
          onClick={() => {
            navigate("/automation-rule/add/apply-to");
          }}
        >
          back
        </button>
        <div>
          <SubmitButton
            buttonText="Next"
            buttonType="submit"
            className="px-8"
            loadingClassName="px-8"
            disabledClassName="px-8"
            isDisabled={!isValid}
            isLoading={false}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNewAutomationRuleIf;
