import { useMemo, } from 'react';
import {
    // MRT_Table as MrtTable, //import alternative sub-component if we do not want toolbars
    MaterialReactTable,
    type MRT_ColumnDef,
    useMaterialReactTable,
} from 'material-react-table';
import CountryFlag from 'components/countryFlag/CountryFlag';
import ConnectionToggleButton from './ConnectionToggleButton';



interface DataConnectionProps {
    marketplace: string,
    type: string,
    profileName: string,
    dataConnections: boolean
}

export const AmazonConnectionTable: React.FC<{ connectionData: DataConnectionProps[] }> = ({ connectionData }) => {

    /**-Material Table-**/
    const columns = useMemo<MRT_ColumnDef<DataConnectionProps>[]>(
        //column definitions...
        () => [
            {
                accessorKey: 'marketplace',
                header: 'Country',
                Cell: ({ cell }) => {
                    return <CountryFlag country={cell.getValue()} />
                },
            },
            {
                accessorKey: 'profileName',
                header: 'Profile',
            },
            {
                accessorKey: 'type',
                header: 'Type',
            },
            {
                accessorKey: 'dataConnections',
                header: 'Data Connection',
                Cell: ({ cell, row }) => {
                    return (
                        <ConnectionToggleButton connection={cell?.getValue<boolean>()} rowData={row?.original} />
                    )
                },
            },
        ],
        [],
        //end
    );

    const table = useMaterialReactTable<DataConnectionProps>({
        columns,
        data: connectionData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableKeyboardShortcuts: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableSorting: false,
        enableTopToolbar: false,
        enableCellActions: true,
        enableBottomToolbar: false,
        // enablePagination: true,
        // paginationDisplayMode: 'pages',
        // muiPaginationProps: {
        //     color: 'secondary',
        //     rowsPerPageOptions: [10, 20, 30],
        //     shape: 'rounded',
        //     variant: 'outlined',
        // },

        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default, //change default background color
        }),
        muiTablePaperProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            hover: false,
            onClick: (event) => console.log("RowData", row),
        }),
        muiTableBodyProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableHeadRowProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
                fontStyle: 'italic',
                fontWeight: 'normal',
                backgroundColor: '#F9FAFB',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
            },
        },
        // renderCaption: ({ table }) =>
        //     `Here is a table rendered with the lighter weight MRT_Table sub-component, rendering ${table.getRowModel().rows.length} rows.`,
    });

    //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
    return <MaterialReactTable table={table} />;
};
export default AmazonConnectionTable;