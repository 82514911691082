import { Modal } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import cn from "utils/cn";

type BasicModalProps = {
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    headerLogoImg?: string | null,
    headerLogoClass?: string,
    headerTitle?: string,
    headerClass?: string,
    modalParentClass?: string,
    modalBodyClass?: string,
    crossButton?: boolean,
    disableOverlayClick?: boolean,
    children: React.ReactNode
}

const BasicModal: React.FC<BasicModalProps> = ({
    showModal = true,
    setShowModal,
    headerLogoImg = null,
    headerLogoClass,
    headerTitle = "Modal Title",
    headerClass,
    modalParentClass,
    modalBodyClass,
    crossButton = true,
    disableOverlayClick = false,
    children
}) => {
    return (
        <Modal
            open={showModal}
            onClose={() => disableOverlayClick ? console.log("overlay click disbaled") : setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={
                cn(
                    "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 min-w-80 max-w-[500px] bg-white rounded-md flex flex-col",
                    modalParentClass
                )
            }>
                <header className={
                    cn(
                        "border-b border-gray-200 p-3",
                        (
                            headerLogoImg
                                ?
                                "flex gap-2 items-start"
                                :
                                "flex items-center justify-between gap-2"
                        ),
                        headerClass
                    )
                }>
                    {
                        headerLogoImg
                            ?
                            <div className="grow flex justify-center">
                                <img
                                    className={
                                        cn(
                                            "w-32 select-none",
                                            headerLogoClass
                                        )
                                    }
                                    src={headerLogoImg}
                                    alt=""
                                />
                            </div>
                            :
                            <p className="text-xl font-bold">{headerTitle}</p>
                    }
                    {
                        crossButton &&
                        <button onClick={() => setShowModal(false)}>
                            <RxCross2 />
                        </button>
                    }
                </header>
                <section className={
                    cn(
                        "grow p-3",
                        modalBodyClass
                    )
                }>
                    {children}
                </section>
            </div>
        </Modal>
    );
};

export default BasicModal;