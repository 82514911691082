/* eslint-disable react-hooks/exhaustive-deps */
import deleteIcon from "assets/icons/delete_icon.svg";
import copyIcon from "assets/icons/copy.svg";
import exchangeIcon from "assets/icons/exchange_icon.svg";
import exchangeDisableIcon from "assets/icons/exchange_disable_icon.svg";
import { IconButton, Radio, Tooltip } from "@mui/material";
import { MdErrorOutline } from "react-icons/md";
import { FC, useEffect } from "react";
import cn from "utils/cn";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { RiDraggable } from "react-icons/ri";

import {
  ActionRowType,
  ActionType,
  ConditionType,
  NeighborRelationType,
  ThenFormState,
  ValueType,
} from "./AddNewAutomationRuleThen.types";
import { actionTypeData, condition } from "./data";
import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import SelectDynamicCondition from "./SelectDynamicCondition";
import styled from "@emotion/styled";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ChangeStatusRowInputs from "./ChangeStatusRowInputs";
import ChangePlacementRowInputs from "./ChangePlacementRowInputs";
import NegativeTargetRowInputs from "./NegativeTargetRowInputs";
import ChangeBiddingStrategyRowInputs from "./ChangeBiddingStrategyRowInputs";
import AddAndMoveTargetRowInputs from "./AddAndMoveTargetRowInputs";

interface Props {
  currentIndex: number;
  totalLength: number;
  relationToTopNeighbor?: NeighborRelationType;
  relationToBottomNeighbor?: NeighborRelationType;
  handleDeleteRow: (index: number) => void;
  handleCopyRow: (index: number, row: ActionRowType) => void;
  control: Control<ThenFormState, any>;
  getValues: UseFormGetValues<ThenFormState>;
  setValue: UseFormSetValue<ThenFormState>;
  watch: UseFormWatch<ThenFormState>;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#030229",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#030229",
    maxWidth: "220px",
  },
}));

const ActionRow: FC<Props> = ({
  currentIndex,
  totalLength,
  relationToBottomNeighbor,
  relationToTopNeighbor,
  handleDeleteRow,
  handleCopyRow,
  control,
  getValues,
  setValue,
  watch,
}) => {
  /**-- useEffect --*/
  useEffect(() => {
    // validate value for condition one option
    if (
      watch(`actions.${currentIndex}.condition`) ===
        ConditionType.INCREASE_BY &&
      watch(`actions.${currentIndex}.value.data`) &&
      (Number(watch(`actions.${currentIndex}.value.data`)) < 0.02 ||
        Number(watch(`actions.${currentIndex}.value.data`)) > 1000)
    ) {
      setValue(
        `actions.${currentIndex}.value.errorMessage`,
        "Please enter a value between 0.02 - 1000"
      );
    } else if (
      watch(`actions.${currentIndex}.condition`) === ConditionType.DECREASE_BY
    ) {
      if (
        watch(`actions.${currentIndex}.value.type`) === ValueType.DOLLER &&
        (Number(watch(`actions.${currentIndex}.value.data`)) < 0.02 ||
          Number(watch(`actions.${currentIndex}.value.data`)) > 1000)
      ) {
        setValue(
          `actions.${currentIndex}.value.errorMessage`,
          `Please enter a value between 1 - 50000 \n
          bids must between $0.02 to $1,000.`
        );
      } else if (
        watch(`actions.${currentIndex}.value.type`) === ValueType.PERCENT &&
        (Number(watch(`actions.${currentIndex}.value.data`)) < 1 ||
          Number(watch(`actions.${currentIndex}.value.data`)) > 50000)
      ) {
        setValue(
          `actions.${currentIndex}.value.errorMessage`,
          `Please enter a value between 1 - 50000 \n
          bids must between $0.02 to $1,000.`
        );
      } else {
        setValue(`actions.${currentIndex}.value.errorMessage`, undefined);
      }
    } else {
      setValue(`actions.${currentIndex}.value.errorMessage`, undefined);
    }

    // validate for action type
    if (
      watch(`actions.${currentIndex}.actionType`) ===
        ActionType.CHANGE_PLACEMENTS &&
      (Number(watch(`actions.${currentIndex}.value.data`)) < 1 ||
        Number(watch(`actions.${currentIndex}.value.data`)) > 900)
    ) {
      setValue(
        `actions.${currentIndex}.value.errorMessage`,
        "Please enter a value between 1 - 900"
      );
    }
  }, [
    watch(`actions.${currentIndex}.value.data`),
    watch(`actions.${currentIndex}.condition`),
    watch(`actions.${currentIndex}.actionType`),
  ]);

  return (
    <div className="flex flex-col h-auto">
      <div className="flex flex-row gap-3 h-auto">
        {/* left side bar */}
        <div
          className={cn(
            "grow w-[10px]  ",
            (currentIndex === 0 ||
              relationToTopNeighbor === NeighborRelationType.OR ||
              relationToTopNeighbor === NeighborRelationType.NONE) &&
              "rounded-t-lg",
            (currentIndex === totalLength - 1 ||
              relationToBottomNeighbor === NeighborRelationType.OR) &&
              "rounded-b-lg",
            currentIndex === 0
              ? relationToBottomNeighbor === NeighborRelationType.AND
                ? "bg-[#237804]"
                : "bg-transparent"
              : currentIndex === totalLength - 1
              ? relationToTopNeighbor === NeighborRelationType.AND
                ? "bg-[#237804]"
                : "bg-transparent"
              : relationToBottomNeighbor === NeighborRelationType.AND ||
                relationToTopNeighbor === NeighborRelationType.AND
              ? "bg-[#237804]"
              : "bg-transparent",

            totalLength === 1 && "hidden"
          )}
        />
        {/* contents */}
        <div className="w-full flex flex-row items-center bg-[#F9FAFB] py-2 px-3 rounded-lg">
          <button className="cursor-grabbing text-[#9CA3AF]">
            <RiDraggable className="w-[28px] h-[50px]" />
          </button>
          <div className="w-full flex flex-row gap-5 justify-between">
            <div className="w-full gap-4 grid grid-cols-12 items-center">
              {/* action type input options */}
              <div
                className={cn(
                  watch(`actions.${currentIndex}.actionType`) ===
                    ActionType.CHANGE_STATUS ||
                    watch(`actions.${currentIndex}.actionType`) ===
                      ActionType.NEGATIVE_TARGET ||
                    watch(`actions.${currentIndex}.actionType`) ===
                      ActionType.CHANGE_BIDDING_STRATEGY ||
                    watch(`actions.${currentIndex}.actionType`) ===
                      ActionType.ADD_MOVE_TARGET
                    ? "col-span-3"
                    : "col-span-2"
                )}
              >
                <Controller
                  name={`actions.${currentIndex}.actionType`}
                  control={control}
                  render={({ field }) => (
                    <CustomSelectOption
                      placeholder={"Select Action"}
                      options={actionTypeData}
                      field={field}
                      sx={{
                        "& .MuiSelect-select.MuiSelect-outlined": {
                          padding: "8px 12px !important",
                        },
                      }}
                    />
                  )}
                />
              </div>

              {/* for change status */}
              {watch(`actions.${currentIndex}.actionType`) ===
                ActionType.CHANGE_STATUS && (
                <ChangeStatusRowInputs
                  control={control}
                  currentIndex={currentIndex}
                />
              )}

              {/* for change placement */}
              {watch(`actions.${currentIndex}.actionType`) ===
                ActionType.CHANGE_PLACEMENTS && (
                <ChangePlacementRowInputs
                  control={control}
                  currentIndex={currentIndex}
                />
              )}

              {/* for negative target */}
              {watch(`actions.${currentIndex}.actionType`) ===
                ActionType.NEGATIVE_TARGET && (
                <NegativeTargetRowInputs
                  control={control}
                  currentIndex={currentIndex}
                  watch={watch}
                />
              )}

              {/* for change bidding strategy */}
              {watch(`actions.${currentIndex}.actionType`) ===
                ActionType.CHANGE_BIDDING_STRATEGY && (
                <ChangeBiddingStrategyRowInputs
                  control={control}
                  currentIndex={currentIndex}
                  watch={watch}
                />
              )}
              {/* for add/move target */}
              {watch(`actions.${currentIndex}.actionType`) ===
                ActionType.ADD_MOVE_TARGET && (
                <AddAndMoveTargetRowInputs
                  control={control}
                  currentIndex={currentIndex}
                  watch={watch}
                />
              )}

              {/* condition option select */}
              {!(
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.CHANGE_PLACEMENTS ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.CHANGE_STATUS ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.NEGATIVE_TARGET ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.CHANGE_BIDDING_STRATEGY ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.ADD_MOVE_TARGET
              ) && (
                <div className="col-span-2">
                  <Controller
                    name={`actions.${currentIndex}.condition`}
                    control={control}
                    render={({ field }) => (
                      <CustomSelectOption
                        placeholder=""
                        options={condition}
                        field={field}
                        className="col-span-2"
                        sx={{
                          "& .MuiSelect-select.MuiSelect-outlined": {
                            padding: "8px 12px !important",
                          },
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {/* value input  */}
              {!(
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.CHANGE_STATUS ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.NEGATIVE_TARGET ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.CHANGE_BIDDING_STRATEGY ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.ADD_MOVE_TARGET
              ) && (
                <div className="col-span-2 flex flex-row gap-3 items-center">
                  {/* change placements display ui */}
                  {watch(`actions.${currentIndex}.actionType`) ===
                    ActionType.CHANGE_PLACEMENTS && (
                    <span className="text-[#374151] !font-normal leading-8 text-xl">
                      to
                    </span>
                  )}
                  <Controller
                    name={`actions.${currentIndex}.value.data`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="flex w-full relative">
                        <input
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          value={value}
                          className={cn(
                            "w-full outline-none px-[12px] py-[8px] border-[1px] placeholder:text-[16px] text-[16px] border-stone-200 text-[#171717] leading-6 !font-normal rounded-[4px] transition-all duration-100 bg-transparent hover:bg-white ",
                            "pr-10",

                            watch(`actions.${currentIndex}.value.errorMessage`)
                              ? "border-red-500"
                              : "hover:border-[#237804] focus:border-[#237804]"
                          )}
                          placeholder=""
                          type="text"
                        />
                        {watch(
                          `actions.${currentIndex}.value.errorMessage`
                        ) && (
                          <div className="flex absolute top-[50%] translate-y-[-50%] right-[35%] ">
                            <BootstrapTooltip
                              title={watch(
                                `actions.${currentIndex}.value.errorMessage`
                              )}
                              className="text-center line-clamp-2"
                            >
                              <IconButton>
                                <MdErrorOutline className="h-6 w-6 text-red-500" />
                              </IconButton>
                            </BootstrapTooltip>
                          </div>
                        )}
                        {(getValues(`actions.${currentIndex}.condition1`) ||
                          getValues(`actions.${currentIndex}.condition`) ||
                          getValues(`actions.${currentIndex}.condition2`) ||
                          watch(
                            `actions.${currentIndex}.value.errorMessage`
                          )) && (
                          <>
                            <div className="absolute top-[50%] translate-y-[-50%] right-0 h-[95%] flex items-center justify-center px-2.5 mr-[1px] rounded-r-[4px] bg-[#F3F4F6]">
                              <Controller
                                name={`actions.${currentIndex}.value.type`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <select
                                    value={value}
                                    onChange={onChange}
                                    className="bg-transparent outline-none text-[#111827]"
                                  >
                                    <option value={ValueType.DOLLER}>$</option>
                                    <option value={ValueType.PERCENT}>%</option>
                                  </select>
                                )}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  />
                </div>
              )}

              {/* dynamic condition input */}
              {getValues(`actions.${currentIndex}.exchangeOption`) &&
                !(
                  watch(`actions.${currentIndex}.actionType`) ===
                    ActionType.CHANGE_PLACEMENTS ||
                  watch(`actions.${currentIndex}.actionType`) ===
                    ActionType.CHANGE_STATUS ||
                  watch(`actions.${currentIndex}.actionType`) ===
                    ActionType.NEGATIVE_TARGET ||
                  watch(`actions.${currentIndex}.actionType`) ===
                    ActionType.CHANGE_BIDDING_STRATEGY ||
                  watch(`actions.${currentIndex}.actionType`) ===
                    ActionType.ADD_MOVE_TARGET
                ) && (
                  <div className="col-span-3">
                    <SelectDynamicCondition
                      control={control}
                      currentIndex={currentIndex}
                      getValues={getValues}
                    />
                  </div>
                )}
            </div>
            {/* action button */}
            <div className="flex gap-2">
              {!(
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.CHANGE_STATUS ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.NEGATIVE_TARGET ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.CHANGE_BIDDING_STRATEGY ||
                watch(`actions.${currentIndex}.actionType`) ===
                  ActionType.ADD_MOVE_TARGET
              ) && (
                <button
                  type="button"
                  className={cn(
                    "border-[1px] p-2 rounded-lg  transition-all duration-200",
                    getValues(`actions.${currentIndex}.exchangeOption`) ||
                      watch(`actions.${currentIndex}.actionType`) ===
                        ActionType.CHANGE_PLACEMENTS
                      ? "bg-[#F3F4F6]"
                      : "hover:border-[#237804]"
                  )}
                  onClick={() =>
                    setValue(`actions.${currentIndex}.exchangeOption`, true)
                  }
                  disabled={
                    getValues(`actions.${currentIndex}.exchangeOption`) ||
                    watch(`actions.${currentIndex}.actionType`) ===
                      ActionType.CHANGE_PLACEMENTS
                  }
                >
                  <img
                    src={
                      getValues(`actions.${currentIndex}.exchangeOption`) ||
                      watch(`actions.${currentIndex}.actionType`) ===
                        ActionType.CHANGE_PLACEMENTS
                        ? exchangeDisableIcon
                        : exchangeIcon
                    }
                    className="w-[22px] h-[22px]"
                    alt="exchange"
                  />
                </button>
              )}
              {/* copy/link button button */}
              <button
                type="button"
                className="border-[1px] p-2 rounded-lg hover:border-[#237804] transition-all duration-200"
                onClick={() =>
                  handleCopyRow(
                    currentIndex,
                    getValues(`actions.${currentIndex}`)
                  )
                }
              >
                <img src={copyIcon} className="w-[22px] h-[22px]" alt="copy" />
              </button>
              {/* delete button */}
              <button
                type="button"
                className="border-[1px] p-2 rounded-lg hover:border-[#237804] transition-all duration-200"
                onClick={() => handleDeleteRow(currentIndex)}
              >
                <img
                  src={deleteIcon}
                  className="w-[22px] h-[22px]"
                  alt="delete"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* relation button */}
      <div
        className={cn(
          "flex flex-row gap-3 h-auto",
          currentIndex === totalLength - 1 && "hidden",
          totalLength === 1 && "hidden"
        )}
      >
        {/* left side bar */}
        <div
          className={cn(
            "grow w-[10px]",
            currentIndex === totalLength - 1 && "rounded-b-lg",
            getValues(`actions.${currentIndex}.relationToBottomRow`) ===
              NeighborRelationType.AND
              ? "bg-[#237804]"
              : "bg-transparent"
          )}
        />
        {/* operation box for or */}
        <div
          className={cn(
            "w-full flex items-center justify-start gap-5 mb-5 mt-5"
          )}
        >
          <Controller
            name={`actions.${currentIndex}.relationToBottomRow`}
            control={control}
            render={({ field }) => (
              <>
                <div className="flex items-center justify-start">
                  <Radio
                    id="and"
                    value={NeighborRelationType.AND}
                    checked={field.value === NeighborRelationType.AND}
                    onChange={field.onChange}
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#237804",
                      },
                    }}
                  />
                  <label htmlFor="and">And</label>
                </div>
                <div className="flex items-center justify-start">
                  <Radio
                    id="or"
                    value={NeighborRelationType.OR}
                    checked={field.value === NeighborRelationType.OR}
                    onChange={field.onChange}
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#237804",
                      },
                    }}
                  />
                  <label htmlFor="or">OR</label>
                </div>
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionRow;
