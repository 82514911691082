import { useCallback, useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { Checkbox, ListItemButton, ListItemIcon } from "@mui/material";
import InfoBox from "components/infoBox/InfoBox";
import LoadingScreen from "components/loadingScreen/LoadingScreen";
import { useDispatch } from "react-redux";
import {
  setStep,
  setStepComplete,
} from "features/onboardingStep/onboardingStep";
import {
  useRequestAmazonAdsMerchantMutation,
  useUpdateUserProfilePreferencesMutation,
} from "features/onboarding/onboardingSlice";
import useQuery from "components/useQuery/useQuery";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import cn from "utils/cn";
import { useNavigate } from "react-router-dom";
import {
  useGetUserProfileMutation,
  useLoggedInUserQuery,
} from "features/user/userSlice";
import SubmitButton from "components/submitButton/SubmitButton";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";
import CountryFlag2 from "components/countryFlag/CountryFlag2";

interface FormInputs {
  selectedMerchantsId: number[];
}

type PlanType = "free" | "pro";

const OnboardingSelectMarketPlace = () => {
  // Redux hook
  const dispatch = useDispatch();
  // initial page loading
  useEffect(() => {
    dispatch(setStep(1));
    dispatch(setStepComplete(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**-React Router-**/
  const navigate = useNavigate();

  // RTK: query user data
  //queries
  const { data: loggedInUserData } = useLoggedInUserQuery("");

  // RTK: query mutaion merchant
  const [requestForMerchant, { isLoading, isError }] =
    useRequestAmazonAdsMerchantMutation();

  // RTK: query
  const [getUserProfile, { isLoading: isUserProfileLoading }] =
    useGetUserProfileMutation();

  // RTK: mutation
  const [updateProfilePreference, { isLoading: isProfileUpdateLoading }] =
    useUpdateUserProfilePreferencesMutation();

  // state message
  const [stateText, setStateText] = useState(
    "Please wait, we’re gathering available merchant"
  );

  // error state message
  const [errorStateText, setErrorStateText] = useState<string | undefined>(
    undefined
  );

  // market place state
  const [marketPlaces, setMarketPlaces] = useState<{ [key: string]: any }[]>(
    []
  );

  // Custom hook for query params
  const query = useQuery();

  // form state
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm<FormInputs>({
    defaultValues: { selectedMerchantsId: [] },
    mode: "onChange",
  });

  // Determine if the button should be disabled based on form state
  const isFormValid = !isValid || !(watch("selectedMerchantsId").length > 0);

  // Check plan
  let planType: PlanType = "free";
  let planMessage = "";
  loggedInUserData?.result?.subscriptions?.forEach((p) => {
    if (p?.plan === "FreePlan") {
      planType = "free";
      planMessage = "Upgrade to pro, to select multiple merchant";
    } else {
      planType = "pro";
      planMessage = "";
    }
  });

  // fetch merchant data
  const fetchMerchantData = useCallback(async () => {
    const profile = await getUserProfile({});
    if (profile?.data?.success) {
      setStateText(
        `We found ${profile?.data?.result.length} available marketplaces ready to connect.`
      );
      setMarketPlaces(profile?.data?.result?.awsProfilesPreferences);
      profile?.data?.result?.awsProfilesPreferences.forEach((d, idx) => {
        if (d?.isActive) {
          setValue("selectedMerchantsId", [
            ...getValues("selectedMerchantsId"),
            idx,
          ]);
        }
      });
    } else {
      setStateText("");
      setErrorStateText("Sorry we don't found marketplace for you");
      setMarketPlaces([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // get marchent data
    (async () => {
      // get query params
      const code = query.get("code");
      const scope = query.get("scope");
      const state = query.get("state");
      const body = {
        code,
        scope,
        state,
      };

      // check awsCredentials exist or not
      if (loggedInUserData?.result?.awsCredentials) {
        await fetchMerchantData();
      } else {
        try {
          const res = await requestForMerchant(body);
          if (res?.data?.success) {
            await fetchMerchantData();
          } else {
            setStateText("Merchant list not found");
            setErrorStateText(
              "Sorry for the trouble, we unable to get marketplaces. Please try again."
            );
          }
        } catch (error) {
          setStateText("Merchant list not found");
          setErrorStateText(
            "Sorry for the trouble, we unable to get marketplaces. Please try again."
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get("code"), query.get("scope"), query.get("state")]);

  // submit handler: update user preference
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log(data);
    // get selected data
    const reqObject = marketPlaces.map((d, idx) => {
      if (data.selectedMerchantsId.includes(idx)) {
        return {
          profileId: d?.profileId,
          isActive: true,
        };
      } else {
        return {
          profileId: d?.profileId,
          isActive: false,
        };
      }
    });
    // decorate request body
    const body = {
      awsProfilePreferences: reqObject,
    };
    try {
      const res = await updateProfilePreference(body);

      if (res?.data?.success) {
        navigate("/onboarding/install-extension");
      } else {
        NotifyMessage({
          type: "error",
          message: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      NotifyMessage({
        type: "success",
        message: "Something went wrong, please try again",
      });
    }
  };

  // handle next on clicked
  const handleToGoNextStep = () => {
    navigate("/onboarding/install-extension");
    dispatch(setStepComplete(1));
  };

  // handle back
  const handleBack = () => {
    navigate("/onboarding/amazon-connect");
  };
  return (
    <>
      <div className="flex flex-col">
        <h4 className="!font-medium text-[16px] leading-6 !text-[#171717]">
          Select Merchant
        </h4>
        <p className="m-0 leading-5 text-sm !text-[#393939]">{stateText}</p>
      </div>
      {/* for loading */}
      {(isLoading || isUserProfileLoading) && marketPlaces.length <= 0 ? (
        <div className="w-full h-[488px] flex items-center justify-center">
          <LoadingScreen />
        </div>
      ) : isError ? (
        <>
          {/* info box */}
          {errorStateText && (
            <InfoBox
              type="warning"
              className="py-2"
              message={errorStateText!}
            />
          )}
          {/* action button */}
          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={handleBack}
              className="px-4 py-2.5  rounded-[4px] text-[16px] leading-6 font-bold flex items-center justify-center border-[1px] border-[#237804] text-[#237804]"
            >
              Back
            </button>
            <button
              type="button"
              onClick={handleToGoNextStep}
              className={cn(
                "px-4 py-2.5  rounded-[4px] text-[16px] leading-6 font-bold flex items-center justify-center ",
                "bg-[#237804] text-white"
              )}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <>
          {/* after finding marchant box */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            {marketPlaces.length > 0 && (
              <div className="flex flex-col border-[1px] rounded-[4px] overflow-hidden ">
                <div className="bg-[#F9FAFB] py-2">
                  <span className="uppercase text-[#6B7280] text-sm leading-5 !font-bold ml-[70px]">
                    Country
                  </span>
                </div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 300,
                    "& ul": { padding: "1px" },
                  }}
                  subheader={<li />}
                >
                  {marketPlaces?.map((market, index) => (
                    <ListItem key={`item-${index}`} className="border-b">
                      <Controller
                        name="selectedMerchantsId"
                        control={control}
                        render={({ field }) => (
                          <ListItemButton
                            role={undefined}
                            disabled={
                              watch("selectedMerchantsId").length > 0 &&
                              !watch("selectedMerchantsId").includes(index) &&
                              !(planType === "pro")
                            }
                            // selected={market?.isActive}
                            onClick={() => {
                              const newSelected = field.value.includes(index)
                                ? field.value.filter((i) => i !== index)
                                : [...field.value, index];
                              field.onChange(newSelected);
                            }}
                            dense={true}
                            sx={{
                              display: "flex",
                              gap: "0px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "40px" }}>
                              <Checkbox
                                edge="start"
                                checked={field.value.includes(index)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": `${index}` }}
                                sx={{
                                  color: "#D1D5DB",
                                  borderRadius: "8px",
                                  "&.Mui-checked": {
                                    color: "#46AC8D",
                                  },
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText id={`${index}`}>
                              <div className="flex gap-3">
                                <CountryFlag2
                                  country={{
                                    code: market?.countryCode,
                                    name: market?.profileName,
                                  }}
                                />
                              </div>
                            </ListItemText>
                          </ListItemButton>
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}

            {/* info box */}
            {errorStateText && (
              <InfoBox
                type="warning"
                className="py-5 justify-start"
                message={errorStateText!}
              />
            )}
            {!errorStateText && planMessage && (
              <InfoBox
                type="warning"
                className="py-5 justify-start"
                message={planMessage!}
              />
            )}
            {/* action button */}
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={handleBack}
                className="px-4 py-2.5  rounded-[4px] text-[16px] leading-6 font-bold flex items-center justify-center border-[1px] border-[#237804] text-[#237804]"
              >
                Back
              </button>
              {marketPlaces.length > 0 ? (
                <>
                  <div className="inline-flex">
                    <SubmitButton
                      buttonText="Next"
                      buttonType="submit"
                      isDisabled={isFormValid}
                      isLoading={isProfileUpdateLoading}
                      className="px-4 py-2.5"
                      disabledClassName="px-4 py-2.5"
                      loadingClassName="px-4 py-2.5"
                    />
                  </div>
                </>
              ) : (
                <button
                  type={"button"}
                  onClick={handleToGoNextStep}
                  className={cn(
                    "px-4 py-2.5  rounded-[4px] text-[16px] leading-6 font-bold flex items-center justify-center ",

                    "bg-[#237804] text-white"
                  )}
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default OnboardingSelectMarketPlace;
