import {
    ActionType,
    BiddingStrategyType,
    Condition1Type,
    Condition2Type,
    ConditionType,
    MetricsType,
    MoveAddTargetOperatingType,
    MoveAddTargetOptionType,
    Status1Type,
    Status2Type,
    TargeKeyMatchType,
    TargeKeyType,
    TargetStatus
} from "./AddNewAutomationRuleThen.types";


export const moveAddTargetOperatingData = [
    {
        name: MoveAddTargetOperatingType.ADD,
        value: MoveAddTargetOperatingType.ADD
    },
    {
        name: MoveAddTargetOperatingType.MOVE,
        value: MoveAddTargetOperatingType.MOVE
    }
]

export const moveAddTargetOptionData = [
    {
        name: MoveAddTargetOptionType.CAMPAIGN,
        value: MoveAddTargetOptionType.CAMPAIGN
    },
    {
        name: MoveAddTargetOptionType.AD_GROUP,
        value: MoveAddTargetOptionType.AD_GROUP
    }
]

export const biddingTypeData = [
    {
        name: BiddingStrategyType.DOWN_ONLY,
        value: BiddingStrategyType.DOWN_ONLY
    },
    {
        name: BiddingStrategyType.UP_AND_DOWN,
        value: BiddingStrategyType.UP_AND_DOWN
    },
    {
        name: BiddingStrategyType.FIXED,
        value: BiddingStrategyType.FIXED
    }
]

export const actionTypeData = [
    {
        name: ActionType.CHANGE_BID,
        value: ActionType.CHANGE_BID
    },
    {
        name: ActionType.CHANGE_DEFAULT_BID,
        value: ActionType.CHANGE_DEFAULT_BID
    },
    {
        name: ActionType.CHANGE_PLACEMENTS,
        value: ActionType.CHANGE_PLACEMENTS
    },
    {
        name: ActionType.CHANGE_STATUS,
        value: ActionType.CHANGE_STATUS
    },
    {
        name: ActionType.NEGATIVE_TARGET,
        value: ActionType.NEGATIVE_TARGET
    },
    {
        name: ActionType.CHANGE_BIDDING_STRATEGY,
        value: ActionType.CHANGE_BIDDING_STRATEGY
    },
    {
        name: ActionType.ADD_MOVE_TARGET,
        value: ActionType.ADD_MOVE_TARGET
    }
]

export const targetKeyData = [
    {
        name: TargeKeyType.KEYWORD,
        value: TargeKeyType.KEYWORD
    },
    {
        name: TargeKeyType.ASIN,
        value: TargeKeyType.ASIN
    }
]

export const targetMatchTypeData = [
    {
        name: TargeKeyMatchType.NEGATIVE_EXACT,
        value: TargeKeyMatchType.NEGATIVE_EXACT
    },
    {
        name: TargeKeyMatchType.NEGATIVE_PHRASE,
        value: TargeKeyMatchType.NEGATIVE_PHRASE
    }
]
export const targetMatchTypeData2 = [
    {
        name: TargeKeyMatchType.INDIVIDUAL_PRODUCT,
        value: TargeKeyMatchType.INDIVIDUAL_PRODUCT
    },
]

export const targetStatusTypeData = [
    {
        name: TargetStatus.CAMPAIGN,
        value: TargetStatus.CAMPAIGN
    },
    {
        name: TargetStatus.AD_GROUP,
        value: TargetStatus.AD_GROUP
    }
]


export const status1Data = [
    {
        name: Status1Type.ENABLED,
        value: Status1Type.ENABLED
    },
    {
        name: Status1Type.PAUSED,
        value: Status1Type.PAUSED
    },
    {
        name: Status1Type.ARCHIVE,
        value: Status1Type.ARCHIVE
    }
]

export const status2Data = [
    {
        name: Status2Type.TARGET,
        value: Status2Type.TARGET
    },
    {
        name: Status2Type.CAMPAIGN,
        value: Status2Type.CAMPAIGN
    },
    {
        name: Status2Type.AD_GROUP,
        value: Status2Type.AD_GROUP
    }
]


export const condition = [
    {
        name: ConditionType.INCREASE_BY,
        value: ConditionType.INCREASE_BY
    },
    {
        name: ConditionType.DECREASE_BY,
        value: ConditionType.DECREASE_BY
    },
    {
        name: ConditionType.SET_BID,
        value: ConditionType.SET_BID
    }
]

export const condition1Data = [
    {
        name: Condition1Type.INCREASE_BY,
        value: Condition1Type.INCREASE_BY
    },
    {
        name: Condition1Type.DECREASE_BY,
        value: Condition1Type.DECREASE_BY
    },
    {
        name: Condition1Type.SET_BID,
        value: Condition1Type.SET_BID
    }
]

export const condition2Data = [
    {
        name: Condition2Type.TOP_OF_SEARCH,
        value: Condition2Type.TOP_OF_SEARCH
    },
    {
        name: Condition2Type.PRODUCT_PAGES,
        value: Condition2Type.PRODUCT_PAGES
    },
    {
        name: Condition2Type.REST_OF_SEARCH,
        value: Condition2Type.REST_OF_SEARCH
    }
]




export const metricsData: { name: string, value: MetricsType }[] = [
    {
        name: "ACOS",
        value: MetricsType.ACOS,
    },
    {
        name: "Bid",
        value: MetricsType.BID,
    },
    {
        name: "Clicks",
        value: MetricsType.CLICKS
    },
    {
        name: "Conversion Rate",
        value: MetricsType.CONVERSION_RATE,
    },
    {
        name: "CPC",
        value: MetricsType.CPC,
    },
    {
        name: "CTR",
        value: MetricsType.CTR,
    },
    {
        name: "Default Bid",
        value: MetricsType.DEFAULT_BID
    },
    {
        name: "Impressions",
        value: MetricsType.IMPRESSIONS
    },
    {
        name: "Orders",
        value: MetricsType.ORDERS
    },
    {
        name: "ROAS",
        value: MetricsType.ROAS
    },
    {
        name: "Spend",
        value: MetricsType.SPEND
    },
    {
        name: "Page Read",
        value: MetricsType.PAGE_READ
    },
    {
        name: "Royalties",
        value: MetricsType.ROYALITIES
    },
    {
        name: "Top of Search",
        value: MetricsType.TOP_OF_SEARCH
    },
    {
        name: "Product Pages",
        value: MetricsType.PRODUCT_PAGES
    }
]

export const timePeriodData = [
    {
        name: "Yesterday",
        value: "yesterday"
    },
    {
        name: "Last Week",
        value: "Last Week"
    },
    {
        name: "Last Month",
        value: "Last Month"
    },
    {
        name: "This Week",
        value: "This Week"
    },
    {
        name: "This Month",
        value: "This Month"
    },
    {
        name: "Lifetime",
        value: "Lifetime"
    },
    {
        name: "Last 3 days",
        value: "Last 3 days"
    },
    {
        name: "Last 7 days",
        value: "Last 7 days"
    },
    {
        name: "Last 14 days",
        value: "Last 14 days"
    },
    {
        name: "Last 30 days",
        value: "Last 30 days"
    },
    {
        name: "Last 65 days",
        value: "Last 65 days"
    },
    {
        name: "Last 90 days",
        value: "Last 90 days"
    },
    {
        name: "Last 180 days",
        value: "Last 180 days"
    }
]