import { FC, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/brand/logo.svg";
import eye from "../../assets/icons/eye.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
import cn from "../../utils/cn";
import { useForm, SubmitHandler } from "react-hook-form";
import LoginWithGoogle from "./LoginWithGoogle";
import { useCredentialLoginMutation } from "../../features/auth/authSlice";
import ErrorLabel from "components/errorLabel/ErrorLabel";
import SubmitButton from "components/submitButton/SubmitButton";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";

interface LoginState {
  redirect?: string | null;
  loading?: boolean;
}

type FormInputs = {
  email: string;
  password: string;
  remember_me: boolean;
};

const Login: FC<LoginState> = ({ redirect }) => {
  /**-React Router-**/
  const navigate = useNavigate();

  /**-React Hooks-**/
  //states
  const [showPassword, setShowPassword] = useState(false);

  /**-RTK-**/
  //mutations
  const [credentialLogin, { isLoading: isCredentialLoginLoading }] =
    useCredentialLoginMutation();

  /**-Hook Form-**/
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      remember_me: false,
    },
  });

  // Determine if the button should be disabled based on form state
  const isFormValid = !isValid || !isDirty;

  // redirect exist redirect to the given path
  if (redirect) {
    return <Navigate to={redirect} />;
  }

  /**-Event Handlers-**/
  const handleUserCredentialLogin: SubmitHandler<FormInputs> = async (data) => {
    const body = {
      email: data?.email,
      password: data?.password,
      rememberMe: data?.remember_me,
    };

    try {
      const req = await credentialLogin(body);

      if (req.data?.success) {
        let result = req?.data?.result;
        localStorage.setItem("userName", result.user.userName);
        localStorage.setItem("email", result.user.email);
        localStorage.setItem("userToken", result.accessToken);
        localStorage.setItem("planName", result.user.planName);
        localStorage.setItem("planStatus", result.user.planStatus);
        localStorage.setItem("planExpDate", result.user.planEXPDate);
        navigate("/");
        NotifyMessage({
          type: "success",
          message: "Login success...",
        });
      } else {
        setError("email", {
          message: "Invalid email",
        });
        setError("password", {
          message: "Invalid password",
        });
        NotifyMessage({
          type: "error",
          message: "Login error, Please try again",
        });
      }
    } catch (error) {
      console.log(error);
      NotifyMessage({
        type: "error",
        message: "Login error, Please try again",
      });
    }
  };

  return (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6 relative">
        {/* progress */}
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-[110px]" />
        </div>
        <hr className="my-0 !bg-[#D4D4D4]" />
        <h3 className="text-center text-lg !font-medium !text-black leading-[30px] m-0">
          Login to Continue
        </h3>
        <div className="flex flex-col">
          <LoginWithGoogle />
        </div>
        <div className="flex items-center justify-center gap-2">
          <span className="flex h-[1px] bg-[#C6C6C6] flex-grow" />
          <span className="text-sm text-[#999999] font-semibold">Or</span>
          <span className="flex h-[1px] bg-[#C6C6C6] flex-grow" />
        </div>

        {/* signin input form */}
        <form
          className="flex flex-col w-full gap-3"
          onSubmit={handleSubmit(handleUserCredentialLogin)}
        >
          {/* email inputs */}
          <div className="flex flex-col">
            <input
              type="email"
              placeholder="Enter email..."
              className={cn(
                "py-2.5 px-3.5 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] ",
                " border-[1px]",
                errors?.email ? "!border-red-500" : "!border-[#C6C6C6]"
              )}
              autoComplete="off"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email",
                },
              })}
            />
            {errors?.email && <ErrorLabel message={errors?.email?.message} />}
          </div>
          {/* password input */}
          <div className="flex flex-col">
            <div className="relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password..."
                className={cn(
                  "py-2.5 pl-3.5 pr-11 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] border-[1px]  w-full",
                  errors?.password ? "!border-red-500" : "!border-[#C6C6C6]"
                )}
                autoComplete="off"
                {...register("password", {
                  required: "Password is required",
                })}
              />
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute top-[50%] translate-y-[-50%] right-5"
              >
                <img
                  src={showPassword ? eyeClose : eye}
                  alt="eye"
                  className="h-5 w-5 "
                />
              </button>
            </div>
            {errors?.password && (
              <ErrorLabel message={errors?.password?.message} />
            )}
            <div className="flex items-center justify-end">
              <a
                href="/forgot-password"
                className="!text-[#237804] leading-5 text-sm font-medium no-underline"
              >
                Forgot password?
              </a>
            </div>
          </div>
          {/* remember me */}
          <div className="flex items-center justify-start gap-2">
            <input
              type="checkbox"
              id="remember-me"
              className="!accent-[#237804] h-4 w-4 !hover:accent-[#237804] "
              {...register("remember_me")}
            />
            <label
              htmlFor="remember-me"
              className="text-sm leading-5 font-semibold"
            >
              Remember me
            </label>
          </div>
          {/* login button */}
          <div className="flex flex-col">
            <SubmitButton
              buttonText="Login"
              buttonType="submit"
              isLoading={isCredentialLoginLoading}
              isDisabled={isFormValid}
            />
          </div>
        </form>

        {/* signup link */}
        <div className="flex gap-1 text-[16px] items-center justify-center font-medium text-[#171717]">
          Doesn't have an account?{" "}
          <Link to="/sign-up" className="!text-[#237804] font-semibold">
            Signup
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
