import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import logo from "../../assets/brand/logo.svg";
import cn from "../../utils/cn";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorLabel from "components/errorLabel/ErrorLabel";
import SignInWithGoogle from "components/SignInWithGoogle/SignInWithGoogle";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import {
  useOAuthLoginMutation,
  useSignupMutation,
} from "features/auth/authSlice";
import SubmitButton from "components/submitButton/SubmitButton";
import NotifyMessage from "components/NotifyMessage/NotifyMessage";

/**-Hook Form Validation Schema-**/
const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Please confirm your password"),
});

const SignUp = () => {
  /**-React Router-**/
  const navigate = useNavigate();

  /**-React Hooks-**/
  //states
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  /**-RTK-**/
  //mutations
  const [signUp, { isLoading: signUpLoading }] = useSignupMutation();
  const [oAuthLogin] = useOAuthLoginMutation();

  /**-Hook Form-**/
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  /**-Event Handlers-**/
  const handleSignUp = async (data: any) => {
    try {
      const res = await signUp(data);
      if (res?.data?.success) {
        navigate("/email-verification", {
          state: {
            email: data?.email,
          },
        });
        NotifyMessage({
          type: "success",
          message: "Sugnup Success..",
        });
      } else {
        const error: any = res?.error;
        if (
          error?.data?.validationErrors &&
          error?.data?.validationErrors["Email"]
        ) {
          setError("email", {
            message: error?.data?.validationErrors["Email"],
          });
          NotifyMessage({
            type: "error",
            message: "Sign up Error, Please try again",
          });
        }
      }
    } catch (error) {
      console.log("signUperror", error);
      NotifyMessage({
        type: "error",
        message: "Sign up Error, please try again",
      });
    }
  };

  const handleOnGoogleLoginError = (error: any) => {
    console.log("error", error);
  };
  const handleOnGoogleLoginSuccess = async (response: any) => {
    try {
      const res = await oAuthLogin(response);

      if (res.data?.success) {
        let result = res.data?.result;
        localStorage.setItem("userName", result?.user?.userName);
        localStorage.setItem("email", result?.user?.email);
        localStorage.setItem("userToken", result?.accessToken);
        localStorage.setItem("planName", result?.user?.planName);
        localStorage.setItem("planStatus", result?.user?.planStatus);
        localStorage.setItem("planExpDate", result?.user?.planEXPDate);
        navigate("/");
        NotifyMessage({
          type: "success",
          message: "Google sign up success..",
        });
      } else {
        NotifyMessage({
          type: "error",
          message: "Google sugn up error! try again",
        });
      }
    } catch (error) {
      console.log("oAuthSinupError", error);
      NotifyMessage({
        type: "error",
        message: "Error sign up with google",
      });
    }
  };

  return (
    <div className="grid place-items-center">
      <div className="bg-white shadow-sm p-8 rounded-md flex flex-col gap-6 relative max-w-[450px]">
        <div className="flex justify-center">
          <img src={logo} alt="" className="w-[110px]" />
        </div>
        <hr className="my-0 !bg-[#D4D4D4]" />
        <h3 className="text-center text-lg !font-medium !text-black leading-[30px] m-0">
          Signup to Continue
        </h3>
        <div className="flex flex-col">
          <SignInWithGoogle
            onError={handleOnGoogleLoginError}
            onSuccess={handleOnGoogleLoginSuccess}
          />
        </div>
        <div className="flex items-center justify-center gap-2">
          <span className="flex h-[1px] bg-[#C6C6C6] flex-grow" />
          <span className="text-sm text-[#999999] font-semibold">Or</span>
          <span className="flex h-[1px] bg-[#C6C6C6] flex-grow" />
        </div>

        <form
          onSubmit={handleSubmit(handleSignUp)}
          className="flex flex-col w-full gap-3"
          autoComplete="off"
        >
          {/* email inputs */}
          <div className="flex flex-col">
            <input
              type="email"
              autoComplete="off"
              placeholder="Enter email..."
              className={cn(
                "py-2.5 px-3.5 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] ",
                "border-[1px]",
                errors?.email ? "border-red-500" : "border-[#C6C6C6]"
              )}
              {...register("email")}
            />
            {errors?.email && <ErrorLabel message={errors.email?.message} />}
          </div>
          {/* password inputs */}
          <div className="flex flex-col">
            <div className="relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                placeholder="Enter password..."
                className={cn(
                  "py-2.5 px-3.5 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] w-full",
                  "border-[1px]",
                  errors?.password ? "border-red-500" : "border-[#C6C6C6]"
                )}
                {...register("password")}
              />
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute top-[50%] translate-y-[-50%] right-5"
              >
                {showPassword ? (
                  <FaEye className="text-gray-500 text-lg" />
                ) : (
                  <FaEyeSlash className="text-gray-500 text-lg" />
                )}
              </button>
            </div>
            {errors?.password && (
              <ErrorLabel message={errors.password?.message} />
            )}
          </div>
          {/* confirm password input */}
          <div className="flex flex-col">
            <div className="relative w-full">
              <input
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="off"
                placeholder="Confirm password..."
                className={cn(
                  "py-2.5 px-3.5 text-[#737373] text-base placeholder:text-[#737373] placeholder:font-medium rounded-[4px] w-full",
                  "border-[1px]",
                  errors?.confirmPassword
                    ? "border-red-500"
                    : "border-[#C6C6C6]"
                )}
                {...register("confirmPassword")}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
                className="absolute top-[50%] translate-y-[-50%] right-5"
              >
                {showConfirmPassword ? (
                  <FaEye className="text-gray-500 text-lg" />
                ) : (
                  <FaEyeSlash className="text-gray-500 text-lg" />
                )}
              </button>
            </div>
            {errors.confirmPassword && (
              <ErrorLabel message={errors.confirmPassword?.message} />
            )}
          </div>
          <p className="text-xs text-center text-zinc-950 font-medium">
            By clicking ‘Create account’ or ‘continue with google or facebook’
            you agree to the{" "}
            <Link to="" className="!text-am-primary font-medium no-underline">
              Aimosa TOS
            </Link>{" "}
            and{" "}
            <Link to="" className="!text-am-primary font-medium no-underline">
              Privacy policy
            </Link>
          </p>

          {/* sign up button */}
          <SubmitButton
            buttonText="Create account"
            isDisabled={!isDirty}
            isLoading={signUpLoading}
          />
        </form>

        {/* signup link */}
        <div className="flex gap-1 text-[16px] items-center justify-center font-medium text-[#171717]">
          Already have an account?{" "}
          <Link to="/login" className="!text-[#237804] font-semibold">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
