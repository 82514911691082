import React, { FC } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { ThenFormState } from "./AddNewAutomationRuleThen.types";
import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import { moveAddTargetOperatingData, moveAddTargetOptionData } from "./data";
import AddAndModeTargetRowInputsManualOptions from "./AddAndModeTargetRowInputsManualOptions";

interface Props {
  currentIndex: number;
  control: Control<ThenFormState, any>;
  watch: UseFormWatch<ThenFormState>;
}

const AddAndMoveTargetRowInputs: FC<Props> = ({ control, currentIndex }) => {
  return (
    <>
      <div className="col-span-1">
        <Controller
          name={`actions.${currentIndex}.condition1`}
          control={control}
          render={({ field }) => (
            <CustomSelectOption
              placeholder=""
              options={moveAddTargetOperatingData}
              field={field}
              className="col-span-2"
              sx={{
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "8px 12px !important",
                },
              }}
            />
          )}
        />
      </div>
      <div className="col-span-2 flex gap-3 items-center">
        <span className="text-[#374151] !font-normal leading-8 text-[16px]">
          to
        </span>
        <div className="grow">
          <Controller
            name={`actions.${currentIndex}.condition1`}
            control={control}
            render={({ field }) => (
              <CustomSelectOption
                placeholder=""
                options={moveAddTargetOptionData}
                field={field}
                className="col-span-2"
                sx={{
                  "& .MuiSelect-select.MuiSelect-outlined": {
                    padding: "8px 12px !important",
                  },
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="col-span-3">
        <AddAndModeTargetRowInputsManualOptions />
      </div>
    </>
  );
};

export default AddAndMoveTargetRowInputs;
