import { CircleFlag } from 'react-circle-flags';

const CountryFlag = ({ country, size = 20 }) => {
    return (
        <div className='flex gap-2 items-center'>
            <CircleFlag countryCode={country?.toLowerCase()} height={size} width={size} />
            <span>{country}</span>
        </div>
    );
};

export default CountryFlag;