import { Dayjs } from "dayjs";

export interface TimelineFormState {

    untils: UntilRowType[],
    runningDates: {
        runningType: RunningType | undefined
        dateRange: {
            from: Dayjs | null,
            to: Dayjs | null
        }
    }
}

export enum RunningType {
    DATE_RANGE = "Date range",
    RUN_CONTINUOUSLY = "Run continuously"
}

export interface UntilRowType {
    relationToBottomRow: NeighborRelationType | undefined,
    metrics: undefined,
    operator: undefined,
    value: {
        data: string | number,
        type?: undefined,
    },
    value1: {
        data: string | number,
        type?: undefined,
    },
    value2: {
        data: string | number,
        type?: undefined,
    },
    sumValue: number | undefined,
    exchangeOption: boolean,
    dynamicCondition: {
        metrics?: string,
        period?: string,
        value?: number
    }
}

export enum NeighborRelationType {
    AND = "and",
    OR = "or",
    NONE = "none",
}