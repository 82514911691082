import { CreateFormState, TabType } from "pages/createAds/CreateAds.types";
import { FC } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import { BsPercent } from "react-icons/bs";
import { PiListChecksFill } from "react-icons/pi";
import cn from "utils/cn";

interface Props {
  control: Control<CreateFormState, any>;
  tabType: TabType;
  getValues: UseFormGetValues<CreateFormState>;
  watch: UseFormWatch<CreateFormState>;
}

const PlacementInputs: FC<Props> = ({ control, getValues, tabType, watch }) => {
  return (
    <>
      <div className="w-full flex flex-col gap-2">
        {/* header */}
        <div className="flex flex-row items-center leading-6 gap-2">
          <button type="button" className="text-[#111827]">
            <PiListChecksFill className="h-5 w-5" />
          </button>
          <span className="text-[#111827] font-bold text-xl">Placement</span>
        </div>
        {/* input options */}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
          {/* Top of search inputs */}
          <Controller
            name={`${tabType}.placement.topOfSearch`}
            control={control}
            rules={{
              validate: {
                minValue: (value) => {
                  const numValue = parseFloat(String(value));
                  return numValue >= 0 || "Please enter only from 0 - 900";
                },
                maxValue: (value) => {
                  const numValue = parseFloat(String(value));
                  return numValue <= 900 || "Please enter only from 0 - 900";
                },
              },
            }}
            render={({ field, fieldState }) => (
              <div className=" flex gap-2 flex-col">
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
                      Top of search (first page)
                    </h4>
                  </div>
                </div>
                <div className="w-full relative">
                  <span
                    className={cn(
                      "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[10px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                      fieldState?.error && "border-l border-red-500"
                    )}
                  >
                    <BsPercent className="h-4 w-4" />
                  </span>
                  <input
                    onChange={(e) => {
                      field?.onChange(e.target.value);
                    }}
                    value={field?.value}
                    type="text"
                    placeholder="0"
                    className={cn(
                      "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                      fieldState?.error && "!border-red-500"
                    )}
                  />
                </div>

                {fieldState?.error && <p>{fieldState?.error?.message}</p>}
              </div>
            )}
          />
          {/* Product detail pages inputs */}
          <Controller
            name={`${tabType}.placement.productDetailPages`}
            control={control}
            rules={{
              validate: {
                minValue: (value) => {
                  const numValue = parseFloat(String(value));
                  return numValue >= 0 || "Please enter only from 0 - 900";
                },
                maxValue: (value) => {
                  const numValue = parseFloat(String(value));
                  return numValue <= 900 || "Please enter only from 0 - 900";
                },
              },
            }}
            render={({ field, fieldState }) => (
              <div className=" flex gap-2 flex-col">
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
                      Product detail pages
                    </h4>
                  </div>
                </div>
                <div className="w-full relative">
                  <span
                    className={cn(
                      "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[10px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                      fieldState?.error && "border-l border-red-500"
                    )}
                  >
                    <BsPercent className="h-4 w-4" />
                  </span>
                  <input
                    onChange={(e) => {
                      field?.onChange(e.target.value);
                    }}
                    value={field?.value}
                    type="text"
                    placeholder="0"
                    className={cn(
                      "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                      fieldState?.error && "!border-red-500"
                    )}
                  />
                </div>
                {fieldState?.error && <p>{fieldState?.error?.message}</p>}
              </div>
            )}
          />
          {/* Rest of search inputs */}
          <Controller
            name={`${tabType}.placement.restOfSearch`}
            control={control}
            rules={{
              validate: {
                minValue: (value) => {
                  const numValue = parseFloat(String(value));
                  return numValue >= 0 || "Please enter only from 0 - 900";
                },
                maxValue: (value) => {
                  const numValue = parseFloat(String(value));
                  return numValue <= 900 || "Please enter only from 0 - 900";
                },
              },
            }}
            render={({ field, fieldState }) => (
              <div className=" flex gap-2 flex-col">
                <div className="w-full">
                  <div className="inline-flex items-center gap-2 ">
                    <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
                      Rest of search
                    </h4>
                  </div>
                </div>
                <div className="w-full relative">
                  <span
                    className={cn(
                      "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[10px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                      fieldState?.error && "border-l border-red-500"
                    )}
                  >
                    <BsPercent className="h-4 w-4" />
                  </span>
                  <input
                    onChange={(e) => {
                      field?.onChange(e.target.value);
                    }}
                    value={field?.value}
                    type="text"
                    placeholder="0"
                    className={cn(
                      "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                      fieldState?.error && "!border-red-500"
                    )}
                  />
                </div>
                {fieldState?.error && <p>{fieldState?.error?.message}</p>}
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default PlacementInputs;
