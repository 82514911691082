import { FC } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import AdGroupNameInput from "../shared/adGroupNameInput/AdGroupNameInput";
import BidSettings from "../shared/bidSettings/BidSettings";
import CampaignNameInput from "../shared/campaignNameInput/CampaignNameInput";
import CampaignStructureOptions from "../shared/campaignStructureOption/CampaignStructureOptions";
import DropdownInputs from "../shared/dropdownInputs/DropdownInputs";
import MatchTypeInputs from "../shared/matchTypeInputs/MatchTypeInputs";
import PlacementInputs from "../shared/placementInputs/PlacementInputs";
import SelectMarketPlacesInputs from "../shared/selectMarketPlacesInputs/SelectMarketPlacesInputs";

import NegativeTargetingInputs from "../shared/negativeTargetingInputs/NegativeTargetingInputs";
import { CreateFormState, TabType } from "../CreateAds.types";
import { marketplaces } from "../data";

interface Props {
  control: Control<CreateFormState, any>;
  tabType: TabType;
  getValues: UseFormGetValues<CreateFormState>;
  watch: UseFormWatch<CreateFormState>;
  setValue: UseFormSetValue<CreateFormState>;
}
const CreateAdsAutoCampaignTab: FC<Props> = ({
  control,
  tabType,
  getValues,
  watch,
  setValue,
}) => {
  return (
    <div className="flex flex-col gap-5">
      {/* campaign name input */}
      <CampaignNameInput
        control={control}
        tabType={tabType}
        getValues={getValues}
        watch={watch}
      />
      {/* ad group name */}
      <AdGroupNameInput
        control={control}
        tabType={tabType}
        getValues={getValues}
        watch={watch}
      />
      {/* campaign structure option */}
      <CampaignStructureOptions
        control={control}
        tabType={tabType}
        getValues={getValues}
        watch={watch}
      />
      {/* dropdown select options */}
      <DropdownInputs
        control={control}
        tabType={tabType}
        getValues={getValues}
        watch={watch}
        setValue={setValue}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* bid settings */}
      <BidSettings
        control={control}
        tabType={tabType}
        getValues={getValues}
        watch={watch}
      />
      {/* match types */}
      <MatchTypeInputs
        control={control}
        tabType={tabType}
        getValues={getValues}
        watch={watch}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* placement inputs */}
      <PlacementInputs
        control={control}
        tabType={tabType}
        getValues={getValues}
        watch={watch}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* marketplaces */}
      <SelectMarketPlacesInputs
        tabType={tabType}
        control={control}
        marketplace={marketplaces}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* target inputs */}
      <NegativeTargetingInputs />
    </div>
  );
};

export default CreateAdsAutoCampaignTab;
