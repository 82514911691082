import deleteIcon from "assets/icons/delete_icon.svg";
import copyIcon from "assets/icons/copy.svg";
import exchangeIcon from "assets/icons/exchange_icon.svg";
import exchangeDisableIcon from "assets/icons/exchange_disable_icon.svg";
import { Radio } from "@mui/material";
import { FC } from "react";
import cn from "utils/cn";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { RiDraggable } from "react-icons/ri";

import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import {
  ConditionRowType,
  IfFormState,
  MetricsType,
  NeighborRelationType,
  OperatorType,
} from "./AddNewAutomationRuleIf.types";
import { metricsData, opertorData } from "./data";
import CustomSelectOptionWithFilter from "components/CustomSelectOptionWithFilter/CustomSelectOptionWithFilter";
import SelectDynamicCondition from "./SelectDynamicCondition";

interface Props {
  currentIndex: number;
  totalLength: number;
  relationToTopNeighbor?: NeighborRelationType;
  relationToBottomNeighbor?: NeighborRelationType;
  handleDeleteRow: (index: number) => void;
  handleCopyRow: (index: number, row: ConditionRowType) => void;
  control: Control<IfFormState, any>;
  getValues: UseFormGetValues<IfFormState>;
  setValue: UseFormSetValue<IfFormState>;
}

const ConditionRow: FC<Props> = ({
  currentIndex,
  totalLength,
  relationToBottomNeighbor,
  relationToTopNeighbor,
  handleDeleteRow,
  handleCopyRow,
  control,
  getValues,
  setValue,
}) => {
  return (
    <div className="flex flex-col h-auto">
      <div className="flex flex-row gap-3 h-auto">
        {/* left side bar */}
        <div
          className={cn(
            "grow w-[10px]  ",
            (currentIndex === 0 ||
              relationToTopNeighbor === NeighborRelationType.OR ||
              relationToTopNeighbor === NeighborRelationType.NONE) &&
              "rounded-t-lg",
            (currentIndex === totalLength - 1 ||
              relationToBottomNeighbor === NeighborRelationType.OR) &&
              "rounded-b-lg",
            currentIndex === 0
              ? relationToBottomNeighbor === NeighborRelationType.AND
                ? "bg-[#237804]"
                : "bg-transparent"
              : currentIndex === totalLength - 1
              ? relationToTopNeighbor === NeighborRelationType.AND
                ? "bg-[#237804]"
                : "bg-transparent"
              : relationToBottomNeighbor === NeighborRelationType.AND ||
                relationToTopNeighbor === NeighborRelationType.AND
              ? "bg-[#237804]"
              : "bg-transparent",

            totalLength === 1 && "hidden"
          )}
        />
        {/* contents */}
        <div className="w-full flex flex-row items-center bg-[#F9FAFB] py-2 px-3 rounded-lg">
          <button className="cursor-grabbing text-[#9CA3AF]">
            <RiDraggable className="w-[28px] h-[50px]" />
          </button>
          <div className="w-full flex flex-row gap-5 justify-between">
            <div className="w-full gap-4 grid grid-cols-12 items-center">
              {/* metrics input options */}
              <div className="col-span-2">
                <Controller
                  name={`conditions.${currentIndex}.metrics`}
                  control={control}
                  render={({ field }) => (
                    <CustomSelectOptionWithFilter
                      placeholder={"Select Metrics"}
                      searchPlaceholder="Search metrics..."
                      options={metricsData}
                      field={field}
                    />
                  )}
                />
              </div>
              {/* operator input options */}
              <div className="col-span-2">
                <Controller
                  name={`conditions.${currentIndex}.operator`}
                  control={control}
                  render={({ field }) => (
                    <CustomSelectOption
                      placeholder="Select Operator"
                      options={opertorData}
                      field={field}
                      className="col-span-2"
                      sx={{
                        "& .MuiSelect-select.MuiSelect-outlined": {
                          padding: "8px 12px !important",
                        },
                      }}
                    />
                  )}
                />
              </div>
              {/* value input  */}

              {getValues(`conditions.${currentIndex}.operator`) ===
              OperatorType.BETWEEN_TWO_VALUES ? (
                <div className="col-span-3 grid grid-cols-4 gap-3">
                  <div className="col-span-2">
                    <Controller
                      name={`conditions.${currentIndex}.value2`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <input
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          value={value}
                          className={cn(
                            "w-full outline-none px-[12px] py-[8px] border-[1px] placeholder:text-[16px] text-[16px] border-stone-200 text-[#171717] leading-6 !font-normal rounded-[4px] hover:border-[#237804] focus:border-[#237804] transition-all duration-100 bg-transparent hover:bg-white "
                          )}
                          placeholder=""
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-2">
                    <Controller
                      name={`conditions.${currentIndex}.value1`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="flex w-full relative">
                          <input
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            value={value}
                            className={cn(
                              "w-full outline-none px-[12px] py-[8px] border-[1px] placeholder:text-[16px] text-[16px] border-stone-200 text-[#171717] leading-6 !font-normal rounded-[4px] hover:border-[#237804] focus:border-[#237804] transition-all duration-100 bg-transparent hover:bg-white ",
                              (getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.BID ||
                                getValues(
                                  `conditions.${currentIndex}.metrics`
                                ) === MetricsType.CONVERSION_RATE ||
                                getValues(
                                  `conditions.${currentIndex}.metrics`
                                ) === MetricsType.CPC ||
                                getValues(
                                  `conditions.${currentIndex}.metrics`
                                ) === MetricsType.CTR ||
                                getValues(
                                  `conditions.${currentIndex}.metrics`
                                ) === MetricsType.DEFAULT_BID ||
                                getValues(
                                  `conditions.${currentIndex}.metrics`
                                ) === MetricsType.ROAS) &&
                                "pr-10"
                            )}
                            placeholder=""
                            type="text"
                          />
                          {(getValues(`conditions.${currentIndex}.metrics`) ===
                            MetricsType.BID ||
                            getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.CONVERSION_RATE ||
                            getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.CPC ||
                            getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.CTR ||
                            getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.DEFAULT_BID ||
                            getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.ROAS) && (
                            <div className="absolute top-[50%] translate-y-[-50%] right-0 h-[95%] flex items-center justify-center px-2.5 mr-[1px] rounded-r-[4px] bg-[#F3F4F6]">
                              {getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.BID ||
                              getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.CPC ||
                              getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.DEFAULT_BID
                                ? "$"
                                : "%"}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-span-2">
                  <Controller
                    name={`conditions.${currentIndex}.sumValue`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="flex w-full relative">
                        <input
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          value={value}
                          className={cn(
                            "w-full outline-none px-[12px] py-[8px] border-[1px] placeholder:text-[16px] text-[16px] border-stone-200 text-[#171717] leading-6 !font-normal rounded-[4px] hover:border-[#237804] focus:border-[#237804] transition-all duration-100 bg-transparent hover:bg-white ",
                            (getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.BID ||
                              getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.CONVERSION_RATE ||
                              getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.CPC ||
                              getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.CTR ||
                              getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.DEFAULT_BID ||
                              getValues(
                                `conditions.${currentIndex}.metrics`
                              ) === MetricsType.ROAS) &&
                              "pr-10"
                          )}
                          placeholder=""
                          type="text"
                        />
                        {(getValues(`conditions.${currentIndex}.metrics`) ===
                          MetricsType.BID ||
                          getValues(`conditions.${currentIndex}.metrics`) ===
                            MetricsType.CONVERSION_RATE ||
                          getValues(`conditions.${currentIndex}.metrics`) ===
                            MetricsType.CPC ||
                          getValues(`conditions.${currentIndex}.metrics`) ===
                            MetricsType.CTR ||
                          getValues(`conditions.${currentIndex}.metrics`) ===
                            MetricsType.DEFAULT_BID ||
                          getValues(`conditions.${currentIndex}.metrics`) ===
                            MetricsType.ROAS) && (
                          <div className="absolute top-[50%] translate-y-[-50%] right-0 h-[95%] flex items-center justify-center px-2.5 mr-[1px] rounded-r-[4px] bg-[#F3F4F6]">
                            {getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.BID ||
                            getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.CPC ||
                            getValues(`conditions.${currentIndex}.metrics`) ===
                              MetricsType.DEFAULT_BID
                              ? "$"
                              : "%"}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
              )}

              {/* dynamic condition input */}
              {getValues(`conditions.${currentIndex}.exchangeOption`) && (
                <div className="col-span-3">
                  <SelectDynamicCondition
                    control={control}
                    currentIndex={currentIndex}
                    getValues={getValues}
                  />
                </div>
              )}
            </div>
            {/* action button */}
            <div className="flex gap-2">
              {/* copy/link button button */}
              <button
                type="button"
                className={cn(
                  "border-[1px] p-2 rounded-lg  transition-all duration-200",
                  getValues(`conditions.${currentIndex}.exchangeOption`)
                    ? "bg-[#F3F4F6]"
                    : "hover:border-[#237804]"
                )}
                onClick={() =>
                  setValue(`conditions.${currentIndex}.exchangeOption`, true)
                }
                disabled={getValues(
                  `conditions.${currentIndex}.exchangeOption`
                )}
              >
                <img
                  src={
                    getValues(`conditions.${currentIndex}.exchangeOption`)
                      ? exchangeDisableIcon
                      : exchangeIcon
                  }
                  className="w-[22px] h-[22px]"
                  alt="copy"
                />
              </button>
              {/* copy/link button button */}
              <button
                type="button"
                className="border-[1px] p-2 rounded-lg hover:border-[#237804] transition-all duration-200"
                onClick={() =>
                  handleCopyRow(
                    currentIndex,
                    getValues(`conditions.${currentIndex}`)
                  )
                }
              >
                <img src={copyIcon} className="w-[22px] h-[22px]" alt="copy" />
              </button>
              {/* delete button */}
              <button
                type="button"
                className="border-[1px] p-2 rounded-lg hover:border-[#237804] transition-all duration-200"
                onClick={() => handleDeleteRow(currentIndex)}
              >
                <img
                  src={deleteIcon}
                  className="w-[22px] h-[22px]"
                  alt="delete"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* relation button */}
      <div
        className={cn(
          "flex flex-row gap-3 h-auto",
          currentIndex === totalLength - 1 && "hidden",
          totalLength === 1 && "hidden"
        )}
      >
        {/* left side bar */}
        <div
          className={cn(
            "grow w-[10px]",
            currentIndex === totalLength - 1 && "rounded-b-lg",
            getValues(`conditions.${currentIndex}.relationToBottomRow`) ===
              NeighborRelationType.AND
              ? "bg-[#237804]"
              : "bg-transparent"
          )}
        />
        {/* operation box for or */}
        <div
          className={cn(
            "w-full flex items-center justify-start gap-5 mb-5 mt-5"
          )}
        >
          <Controller
            name={`conditions.${currentIndex}.relationToBottomRow`}
            control={control}
            render={({ field }) => (
              <>
                <div className="flex items-center justify-start">
                  <Radio
                    id="and"
                    value={NeighborRelationType.AND}
                    checked={field.value === NeighborRelationType.AND}
                    onChange={field.onChange}
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#237804",
                      },
                    }}
                  />
                  <label htmlFor="and">And</label>
                </div>
                <div className="flex items-center justify-start">
                  <Radio
                    id="or"
                    value={NeighborRelationType.OR}
                    checked={field.value === NeighborRelationType.OR}
                    onChange={field.onChange}
                    sx={{
                      color: "#6B7280",
                      padding: "0px",
                      margin: "0px",
                      marginRight: "5px",
                      "&.Mui-checked": {
                        color: "#237804",
                      },
                    }}
                  />
                  <label htmlFor="or">OR</label>
                </div>
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ConditionRow;
