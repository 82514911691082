import { MetricsType } from "./AddNewAutomationRuleIf.types";

export const targetData = [
    {
        name: "Target",
        value: "Target"
    },
    {
        name: "Search Term",
        value: "Search Term"
    },
    {
        name: "Ad Group",
        value: "Ad Group"
    },
    {
        name: "Campaign",
        value: "Campaign"
    }
];

export const opertorData = [
    {
        name: "Greater Than",
        value: "Greater Than",
    },
    {
        name: "Less Than",
        value: "Less Than",
    },
    {
        name: "Equal To",
        value: "Equal To"
    },
    {
        name: "Greater Than or Equal To",
        value: "Greater Than or Equal To",
    },
    {
        name: "Less Than or Equal To",
        value: "Less Than or Equal To",
    },
    {
        name: "Not Equal To",
        value: "Not Equal To",
    },
    {
        name: "Between Two Values",
        value: "Between Two Values",
    }

]

export const metricsData: { name: string, value: MetricsType }[] = [
    {
        name: "ACOS",
        value: MetricsType.ACOS,
    },
    {
        name: "Bid",
        value: MetricsType.BID,
    },
    {
        name: "Clicks",
        value: MetricsType.CLICKS
    },
    {
        name: "Conversion Rate",
        value: MetricsType.CONVERSION_RATE,
    },
    {
        name: "CPC",
        value: MetricsType.CPC,
    },
    {
        name: "CTR",
        value: MetricsType.CTR,
    },
    {
        name: "Default Bid",
        value: MetricsType.DEFAULT_BID
    },
    {
        name: "Impressions",
        value: MetricsType.IMPRESSIONS
    },
    {
        name: "Orders",
        value: MetricsType.ORDERS
    },
    {
        name: "ROAS",
        value: MetricsType.ROAS
    },
    {
        name: "Spend",
        value: MetricsType.SPEND
    },
    {
        name: "Page Read",
        value: MetricsType.PAGE_READ
    },
    {
        name: "Royalties",
        value: MetricsType.ROYALITIES
    },
    {
        name: "Top of Search",
        value: MetricsType.TOP_OF_SEARCH
    },
    {
        name: "Product Pages",
        value: MetricsType.PRODUCT_PAGES
    }
]

export const timePeriodData = [
    {
        name: "Yesterday",
        value: "yesterday"
    },
    {
        name: "Last Week",
        value: "Last Week"
    },
    {
        name: "Last Month",
        value: "Last Month"
    },
    {
        name: "This Week",
        value: "This Week"
    },
    {
        name: "This Month",
        value: "This Month"
    },
    {
        name: "Lifetime",
        value: "Lifetime"
    },
    {
        name: "Last 3 days",
        value: "Last 3 days"
    },
    {
        name: "Last 7 days",
        value: "Last 7 days"
    },
    {
        name: "Last 14 days",
        value: "Last 14 days"
    },
    {
        name: "Last 30 days",
        value: "Last 30 days"
    },
    {
        name: "Last 65 days",
        value: "Last 65 days"
    },
    {
        name: "Last 90 days",
        value: "Last 90 days"
    },
    {
        name: "Last 180 days",
        value: "Last 180 days"
    }
]