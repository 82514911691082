import { apiSlice } from "../api/apiSlice";

export const masterDataSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCurrencyTypeSelection: builder.query({
            query: () => ({
                url: '/MasterData/Profile_CurrencyTypeSelection'
            })
        }),
        getTimezoneSelection: builder.query({
            query: () => ({
                url: '/MasterData/Profile_TimeZoneSelection'
            })
        })
    }),
});

export const {
    useGetCurrencyTypeSelectionQuery,
    useGetTimezoneSelectionQuery
} = masterDataSlice;