import React from 'react'
import logoGram from "assets/icons/logogram.svg";
import cn from 'utils/cn';


const LoadingScreen = ({ className }: { className?: string }) => {
  return (
    <div className={cn("w-full h-full flex items-center justify-center", className)}>
      <div className='flex gap-2 items-center justify-center scale-pulse'>
        <img
          src={logoGram}
          alt="logogram"
          className="w-[25px]"
        />
        <span className="uppercase !font-black text-[#237804] text-[16px] leading-6 tracking-[10px] ">Loading</span>
      </div>
    </div>
  )
}
export default LoadingScreen