import CustomSwitch from 'components/customSwitch/CustomSwitch';
import { useUpdateAmazonConnectionMutation } from 'features/amazon/amazonSlice';
import { useEffect, useState } from 'react';
import {
    type MRT_RowData
} from 'material-react-table'

interface UpdateDataType {
    awsProfilePreferences: MRT_RowData[]
}

const ConnectionToggleButton = ({ connection, rowData }) => {
    console.log("rowData", rowData)
    /**-React Hooks-**/
    //sates
    const [toggleSwitch, setToggleSwitch] = useState(false)

    /**-RTK-**/
    //mutations
    const [updateAmazonConnection, { isLoading: updateAmazonConnectionLoading }] = useUpdateAmazonConnectionMutation()

    useEffect(() => {
        setToggleSwitch(connection)
    }, [connection])

    const handleToggleSwitchChange = (toggleSwitchState: boolean) => {
        if (rowData && !updateAmazonConnectionLoading) {
            const updateData: UpdateDataType = {
                awsProfilePreferences: []
            }
            updateData?.awsProfilePreferences.push({ profileId: rowData?.profileId, isActive: !toggleSwitchState })
            updateAmazonConnection(updateData)
        }
    }

    return (
        <div className='relative flex justify-center'>
            <CustomSwitch checked={toggleSwitch} handleSwitchChange={() => handleToggleSwitchChange(toggleSwitch)} />
            {
                updateAmazonConnectionLoading &&
                <div className="absolute top-0 w-full h-full animate-pulse bg-gray-100 cursor-not-allowed"></div>

            }
        </div>
    )
}

export default ConnectionToggleButton;