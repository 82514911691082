import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AmAccordionProps {
    headerComponent: React.ReactNode;
    bodyComponent: React.ReactNode;
    defaultExpanded?: boolean;
}

const AmAccordion: React.FC<AmAccordionProps> = ({ headerComponent, bodyComponent, defaultExpanded }) => {
    const [expanded, setExpanded] = useState<boolean | undefined>(defaultExpanded);

    return (
        <div>
            <Accordion
                sx={{
                    backgroundColor: 'transparent',
                    textWrap: "nowrap",
                    padding: "0px",
                    boxShadow: "none",
                    '& .MuiButtonBase-root': {
                        padding: "0px !important"
                    },
                    '& .Mui-expanded': {
                        margin: "0px"
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        color: defaultExpanded ?? expanded ? "#2BC877" : "white"
                    },
                    '&:hover': {
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            color: "#2BC877"
                        }
                    },
                }}
                defaultExpanded={expanded}
                onChange={() => setExpanded((prevExpanded) => !prevExpanded)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    {
                        headerComponent
                    }
                </AccordionSummary>
                <AccordionDetails>
                    {
                        bodyComponent
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default AmAccordion;