import { FC } from "react";
import ReactCountryFlag from "react-country-flag";
import cn from "utils/cn";

interface Props {
  className?: string;
  onClick?: () => void;
  countryCode: string;
  toggleSelect?: any;
}

const MarketplaceToggleButton: FC<Props> = ({
  className,
  onClick,
  countryCode,
  toggleSelect,
}) => {
  let cc = countryCode;
  if (cc.toLowerCase() === "uk") {
    cc = "GB";
  }

  return (
    <button
      type="button"
      className={cn(
        "flex gap-2 py-3.5 px-5 border-[1px] rounded-full items-center justify-center",
        toggleSelect
          ? "border-[#237804] bg-green-500/10 !font-bold !text-[#237804]"
          : "",
        className
      )}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <ReactCountryFlag
        svg
        countryCode={cc}
        style={{
          width: "1.5em",
          height: "1.5em",
        }}
      />
      <span className={cn("uppercase")}>{countryCode}</span>
    </button>
  );
};

export default MarketplaceToggleButton;
