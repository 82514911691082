import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DashboardLayout from "../../../../layouts/XDashboardLayout";
import { Col, Row, Container, Button } from "react-bootstrap";
import ManualCampaign from "./campaigns/manualCampaign";
import LastAppSync from "../lastAppSync";
import AutoCampaign from "./campaigns/autoCampaign";
import { useLocation } from "react-router-dom";
const CreateBothCampaign: React.FunctionComponent = () => {
  const [isAutoState, setIsAutoState] = useState<any>([]);
  const [isTab, setIsTab] = useState<any>("AutoCampaign");
  const getAutoState = (state: any) => {
    setIsAutoState(state);
    setIsTab("ManualCampaign");
  };
  const changeHandler = (eventKey: any) => {
    setIsTab(eventKey);
  };
  return (
    <DashboardLayout>
      <LastAppSync />
      <div className="main-content-container">
        <hr />
        <Container fluid>
          <Row>
            <Col>
              <Tabs activeKey={isTab} className="ms-1" onSelect={changeHandler}>
                <Tab eventKey="AutoCampaign" title="Auto Campaign">
                  <AutoCampaign isAuto={getAutoState} />
                </Tab>
                <Tab
                  eventKey="ManualCampaign"
                  title="Manual Campaign"
                  id="manual"
                >
                  <ManualCampaign isAutoState={isAutoState} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default CreateBothCampaign;
