import { Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRef, useState } from "react";
import cn from "utils/cn";

const BootstrapInput = styled(InputBase)(() => ({
  "label + &": {
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    padding: "12px 14px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#237804",
    },
    "&:hover": {
      borderColor: "#237804",
    },
    "&:active": {
      borderColor: "#237804",
    },
  },
}));

const CustomSelectOption = ({
  options,
  placeholder,
  field,
  sx,
  className,
  valueCalss,
}: {
  options: { name: string; value: string }[]; // Specify the type for options
  placeholder: string;
  field: any;
  sx?: any;
  className?: string;
  valueCalss?: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const selectRef = useRef<null | HTMLElement>(null);

  return (
    <Select
      ref={selectRef}
      displayEmpty
      value={field.value || ""}
      onChange={(e) => {
        field.onChange(e.target.value); // Use the value from the event
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onBlur={field.onBlur}
      input={
        <BootstrapInput
          className={cn(
            "rounded-[4px] border-[1px]",
            valueCalss,
            open ? "border-[#237804] bg-[#EAFFEC]" : "border-[#D1D5DB]",
          )}
        />
      }
      IconComponent={ExpandMoreIcon}
      renderValue={(selected) => {
        if (!selected) {
          return (
            <p
              className={cn(
                "capitalize text-sm !font-normal !m-0 leading-5 !line-clamp-1 !mr-12",
                valueCalss,
                open ? "text-[#237804] !font-bold" : "text-[#9CA3AF]",
              )}
            >
              {placeholder}
            </p>
          );
        }
        return (
          <p
            className={cn(
              "capitalize !m-0 text-sm !font-normal !line-clamp-1 !mr-12 ",
              valueCalss,
              open ? "text-[#237804] !font-bold" : "text-[#171717]",
            )}
          >
            {selected}
          </p>
        );
      }}
      className={cn("!line-clamp-1", className)}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300,
            overflowY: "auto",
            scrollbarWidth: "thin",
            width: selectRef?.current ? selectRef?.current.offsetWidth : "auto",
          },
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
      sx={{
        width: "100%",
        paddingY: "0px",
        paddingX: "0px",
        display: "flex",
        alignItems: "center",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #e7e5e4",
        },
        "&:focus .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #e7e5e4",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #e7e5e4",
          outline: "0px",
        },
        "& .MuiSelect-select.MuiSelect-outlined": {
          padding: "12px 14px !important",
          display: "flex",
          alignItems: "center",
        },
        "& .MuiSelect-iconOutlined": {
          color: open ? "#237804" : "#6B7280",
        },
        ...sx,
      }}
    >
      {options.map((option, idx) => (
        <MenuItem
          key={idx}
          value={option.value}
          className="!font-normal !py-3 !border-b-[1px] !border-[#F3F4F6] capitalize"
          sx={{
            borderTop: idx === 0 ? "" : "1px solid #333",
            marginX: "0px",
            paddingX: "12px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent !important",
            "& .Mui-selected": {
              backgroundColor: "red !important",
            },
            "&:hover": {
              backgroundColor: "#F3F4F6 !important",
            },
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelectOption;
