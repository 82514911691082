import { GiWorld } from "react-icons/gi";
import SelectMarketPlaceOptions from "./SelectMarketPlaceOptions";
import { FC } from "react";
import { CreateFormState, Marketplace, TabType } from "../../CreateAds.types";
import { Control } from "react-hook-form";

interface Props {
  marketplace: Marketplace[];
  control: Control<CreateFormState, any>;
  tabType: TabType;
}

const SelectMarketPlacesInputs: FC<Props> = ({
  control,
  marketplace,
  tabType,
}) => {
  return (
    <div className="w-full flex flex-col gap-3">
      {/* header */}
      <div className="flex flex-row items-center leading-6 gap-2">
        <button type="button" className="text-[#111827]">
          <GiWorld className="h-5 w-5" />
        </button>
        <span className="text-[#111827] font-bold text-xl">
          Select Marketplaces
        </span>
      </div>
      {/* select inputs option */}
      <div className="flex flex-wrap gap-y-3 gap-x-20">
        {marketplace.map((markets, idx) => (
          <SelectMarketPlaceOptions
            key={idx}
            marketplace={markets}
            control={control}
            tabType={tabType}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectMarketPlacesInputs;
